/* eslint-disable */
import React, { memo, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import io from "../../actions/socket";
import { rootApi } from "../../axiosConfig";
import { Row, Col, Image } from "react-bootstrap";
import _ from "lodash";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import Time from "../../assets/images/time.svg";
import ResolvedTime from "../../assets/images/resolved-time.svg";
import HPF from "../../assets/images/tank-new.png";
import EVC_GIF from "../../assets/images/EVC-fan-gif.gif";
import EVC from "../../assets/images/EVC-fan.png";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { registerNames } from "../../actions/passwordRegex";
import { findCompressorNumberByValue } from "../../helpers";
import { useDeviceContext } from "context/deviceContext";
import { ReactComponent as FallingFilmIcon } from "../../assets/images/Falling_Film_Chiller.svg";
import { ReactComponent as HiperChill50Icon } from "../../assets/images/HiPerChill_5.svg";
import { ReactComponent as BakeryChillerIcon } from "../../assets/images/Bakery_Chiller.svg";
import { ReactComponent as LsChillerIcon } from "../../assets/images/LS_Chiller.svg";
import { ReactComponent as MaxximizerIcon } from "../../assets/images/Maxximizer.svg";
import { ReactComponent as Cp2ChillerIcon } from "../../assets/images/CP2_Chiller.svg";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import stratusControl from "../../assets/images/stratusControl.png";
import nimbusControl from "../../assets/images/nimbusControl.png";


const LiveAlertsNotifications = memo(({ turckControlObjectId, currentTankData, dropdownTime, equipmentModelNo, tankType }) => {
  const { dairyID, tankID } = useParams();
  const { t } = useTranslation();
  const { deviceTypeGlobal } = useDeviceContext();
  const [liveAlerts, setLiveAlerts] = useState([]);
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [countAlert, setCountAlert] = useState(false);
  let liveAlertCount = 0;
  /** Live alerts Api call */
  useEffect(() => {
    if(deviceTypeGlobal === "HPF") {
      rootApi
        .get(
          `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          setLiveAlerts(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
      /** live alert using socket connection  */
      io.socket.on(`alerthistory:manual:${dairyID}`, (data1) => {
        if (!data1["isResolved"]) {
          setCountAlert(true);
        } else {
          rootApi
            .get(
              `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
            )
            .then((response) => {
              setCountAlert(response.data.length ? true : false);
              setLiveAlerts(response.data);
            })
            .catch((err) => {
              toast.dark(
                <Toaster
                  icon="error"
                  message={err?.response?.message ?? t("alert_history_error")}
                />
              );
            });
        }
      });
    } else if(deviceTypeGlobal === "TURCK_TANKS") {
      rootApi
        .get(
                `turckalerthistories?where={"or":[{"and":[{"control":"${turckControlObjectId}"},{"turckAlert":null},{"tank":"${turckControlObjectId}"},{"isResolved":false},{"dairy":"${dairyID}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]},{"and":[{"turckAlert":null},{"tank":"${tankID}"},{"isResolved":false},{"dairy":"${dairyID}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
        )
        .then((response) => {
          setLiveAlerts(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
      /** live alert using socket connection  */
      io.socket.on(`turck-alerthistory:manual:${dairyID}`, (data1) => {
        if (!data1["isResolved"]) {
          setCountAlert(true);
        } else {
          rootApi
            .get(
                `turckalerthistories?where={"or":[{"and":[{"control":"${turckControlObjectId}"},{"turckAlert":null},{"tank":"${turckControlObjectId}"},{"isResolved":false},{"dairy":"${dairyID}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]},{"and":[{"turckAlert":null},{"tank":"${tankID}"},{"isResolved":false},{"dairy":"${dairyID}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
            )
            .then((response) => {
              setCountAlert(response.data.length ? true : false);
              setLiveAlerts(response.data);
            })
            .catch((err) => {
              toast.dark(
                <Toaster
                  icon="error"
                  message={err?.response?.message ?? t("alert_history_error")}
                />
              );
            });
        }
      });
    }  else if (deviceTypeGlobal === "CHILLER"){
      rootApi
      .get(
        `chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        setLiveAlerts(response.data);
      })
      .catch((err) => {
        toast.dark(
          <Toaster
            icon="error"
            message={err ?? t("live_alert_history_error")}
          />
        );
      });
    /** live alert using socket connection  */
    io.socket.on(`chiller-alerthistory:manual:${dairyID}`, (data1) => {
      if (!data1["isResolved"]) {
        setCountAlert(true);
      } else {
        rootApi
          .get(
            `chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&sort=updatedAt DESC&limit=1000`
          )
          .then((response) => {
            setCountAlert(response.data.length > 0 ? true : false);
            setLiveAlerts(response.data);
          })
          .catch((err) => {
            toast.dark(
              <Toaster
                icon="error"
                message={err?.response?.message ?? t("alert_history_error")}
              />
            );
          });
      }
    });
    }
  }, []);
  /** Resolved alerts api call */

  const fetchResolvedAlertData = () => {
    if(deviceTypeGlobal === "HPF") {
      setLoader(true);
      rootApi
        .get(
          `/alerthistories?where={"and":[{"alert":null},{"tank":"${tankID}"},{"isResolved":true},{"configKey":{"!=":"VALIDATE_TEMPERATURE"}}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
        )
        .then((response) => {
          const result = response.data;
          setResolvedAlerts([...resolvedAlerts, ...result]);
          setCurrentCount((prev) => prev + 5);
          setLoader(false);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
    } else if(deviceTypeGlobal === "TURCK_TANKS") {
      setLoader(true);
      rootApi
            .get(
                `/turckalerthistories?where={"or":[{"and":[{"control":"${turckControlObjectId}"},{"turckAlert":null},{"tank":"${turckControlObjectId}"},{"isResolved":true}]},{"and":[{"turckAlert":null},{"tank":"${tankID}"},{"isResolved":true}]}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
              )
        .then((response) => {
          const result = response.data;
          setResolvedAlerts([...resolvedAlerts, ...result]);
          setCurrentCount((prev) => prev + 5);
          setLoader(false);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
    } else if (deviceTypeGlobal === "CHILLER") {
      setLoader(true);
      rootApi
        .get(
          `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"device":"${tankID}"},{"isResolved":true}]}&sort=createdAt DESC&limit=5&skip=${currentCount}`
        )
        .then((response) => {
          const result = response.data;
          setResolvedAlerts([...resolvedAlerts, ...result]);
          setCurrentCount((prev) => prev + 5);
          setLoader(false);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
    }
  };

  const ChillerDeviceIcon = useMemo(() => {
    switch (equipmentModelNo) {
      case 'None':
        return FallingFilmIcon;
      case 'HPC5':
        return HiperChill50Icon;
      case 'LS':
        return LsChillerIcon;
      case 'LSA':
        return LsChillerIcon;
      case 'CP2':
        return Cp2ChillerIcon;
      case 'HPC40':
        return MaxximizerIcon;
      default:
        return BakeryChillerIcon;
    }
  }, [equipmentModelNo]);

  useEffect(() => {
    fetchResolvedAlertData();
  }, []);
  return (
    <div className="my-4 rounded px-4 py-3 live-alerts">
      <InfiniteScroll
        style={{ overflowY: "scroll", overflowX: 'hidden' }}
        height={500}
        dataLength={resolvedAlerts.length}
        next={fetchResolvedAlertData}
        hasMore={true}
        loader={
          loader === true ? (
            <div className="text-center mt-5">{t("loading_text")}</div>
          ) : resolvedAlerts.length === 0 ? (
            <div className="px-3 mt-3">{t("resolved_alert_not_found")}</div>
          ) : (
                ""
              )
        }
      >
        <div className="mb-3">
          <h3 className="text-dark px-3 pt-2">{t("live_alerts")}</h3>
          {liveAlerts.map((liveAlert) => {
                       if (
              (deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") &&
              (liveAlert.tank !== null || liveAlert.control !== null) &&
              ((liveAlert?.tank?.id || (liveAlert.tank && countAlert === true)) === tankID || (liveAlert?.control?.id || (liveAlert.control && countAlert === true)) === turckControlObjectId)
            ) {
              const Triggered = new Date(liveAlert.createdAt);
              liveAlertCount += 1;

              // evc and hpf indication names ends here
              let indicator =
                liveAlert.deviceType === "EVC" && currentTankData
                  ? currentTankData.EVC
                  : "";

              let indicatorName;
              if (indicator) {
                indicator = indicator[liveAlert.deviceID]
                  ? Number(indicator[liveAlert.deviceID].EVC_IDENTIFICATION)
                  : "";
                indicatorName =
                  indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
                  
                if(deviceTypeGlobal === "TURCK_TANKS") {
                  indicatorName = indicator ? findCompressorNumberByValue(indicator.toString()) : "EVC";
                }
              }
              if (liveAlert.deviceType === "HPF") {
                indicatorName = "HPF";
              } else if (liveAlert.deviceType === "TURCK_TANK" || liveAlert.deviceType === "TURCK_TANKS") {
                indicatorName = "TANK";
              } else if (liveAlert.deviceType === "TURCK") {
                indicatorName = "TURCK";
              }
              return (
                <Row className="border-bottom p-3 alert_row_container" key={liveAlert.id}>
                  <Col lg={1} xs={3} md={2} className="alert_row_container_column__left">
                    <Image
                      src={liveAlert.deviceType === "HPF" ? HPF : (liveAlert?.deviceType === 'TURCK_TANK' || liveAlert.deviceType === "TURCK_TANKS") ? (tankType === 1 ? tankVerticalIcon : HPF) : (liveAlert?.deviceType === "TURCK") ? (liveAlert?.control.controlType === "Stratus") ? stratusControl : nimbusControl: EVC_GIF}
                      fluid
                      className="w-100 alert_row_container--icon vertical-image"
                    />
                    <div className="text-center mt-1">
                      <small>{indicatorName}</small>
                    </div>
                  </Col>
                  <Col lg={11} xs={9} md={10} className="alert_row_container_column__right">
                    <div className="text-danger text-uppercase mr-4">
                      {liveAlert.configDisplay}
                    </div>
                    <p className="mb-1">{liveAlert.alertText}</p>
                    <div>
                      <span>
                        <Image src={Time} className="mr-2" />
                        {t("triggered")}:
                      </span>{" "}
                      <small>
                        {dropdownTime === undefined
                          ? moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")
                          : moment
                            .tz(new Date(Triggered), dropdownTime)
                            .format("hh:mm:ss A (DD MMM YYYY)")}
                        {/* {moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")} */}
                      </small>
                    </div>
                  </Col>
                </Row>
              );
            } else if (
                deviceTypeGlobal === "CHILLER" &&
                liveAlert.device !== null &&
                (liveAlert.device.id || (liveAlert.device && countAlert === true)) === tankID
              ) {
                              const Triggered = new Date(liveAlert.createdAt);
              liveAlertCount += 1;

              // evc and hpf indication names ends here
              let indicator =
                liveAlert.deviceType === "EVC" && currentTankData
                  ? currentTankData.EVC
                  : "";

              let indicatorName;
              if (indicator) {
                indicator = indicator[liveAlert.deviceSerialNumber]
                  ? Number(indicator[liveAlert.deviceSerialNumber].EVC_IDENTIFICATION)
                  : "";
                indicatorName =
                  indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
              }
              if (liveAlert.deviceType === "CHILLER") {
                indicatorName = "CHILLER";
              } else if (liveAlert.deviceType === "EVC" || indicatorName === "" ) {
                let deviceNo = '';
                  if(!_.isEmpty(currentTankData)) {
                    deviceNo = !Number.isNaN(currentTankData?.EVC[liveAlert.deviceSerialNumber]?.device)? currentTankData?.EVC[liveAlert.deviceSerialNumber]?.device : "";
                  }
                indicatorName = `Compressor - ${deviceNo}`;
              }
              return (
                <Row className="border-bottom p-3  alert_row_container" key={liveAlert.id}>
                  <Col lg={1} xs={3} md={2} className="alert_row_container_column__left">
                    {
                      liveAlert.deviceType === "CHILLER" 
                        ? <ChillerDeviceIcon className="w-100 alert_row_container--icon" />
                        : <Image src={EVC_GIF} fluid className="w-100 alert_row_container--icon" />
                    }
                    <div className="text-center mt-1">
                      <small>{indicatorName}</small>
                    </div>
                  </Col>
                  <Col lg={11} xs={8} md={10} className="alert_row_container_column__right">
                    <p className="mb-2">{liveAlert.alertText}</p>
                    <div>
                      <span>
                        <Image src={Time} className="mr-2" />
                        {t("triggered")}:
                      </span>{" "}
                      <small>
                        {dropdownTime === undefined
                          ? moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")
                          : moment
                            .tz(new Date(Triggered), dropdownTime)
                            .format("hh:mm:ss A (DD MMM YYYY)")}
                        {/* {moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")} */}
                      </small>
                    </div>
                  </Col>
                </Row>
              );
            } else {
              return "";
            }
          })}
          {liveAlertCount === 0 && (
            <div className="px-3 mt-3">{t("live_alert_not_found")}</div>
          )}
        </div>
        <div className="mb-3 mt-5">
          <h3 className="text-dark px-3 pt-2">{t("resolved_alerts")}</h3>
            {resolvedAlerts.map((historyAlert, i) => {
              if (
								(deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") &&
                (historyAlert.tank !== null || historyAlert.control !== null) && 
								historyAlert.isResolved === true
							) {
								const Triggered = new Date(historyAlert.createdAt);
								const Resolved = new Date(historyAlert.updatedAt);
                let indicator =
                  historyAlert.deviceType === "EVC" && currentTankData
                    ? currentTankData.EVC
                    : "";

								let indicatorName = "";
								if (indicator) {
                  indicator = indicator[historyAlert.deviceID]
                    ? Number(indicator[historyAlert.deviceID].EVC_IDENTIFICATION)
                    : "";
                  indicatorName =
                    indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
                  if(deviceTypeGlobal === "TURCK_TANKS") {
                    indicatorName = indicator ? findCompressorNumberByValue(indicator.toString()) : "EVC";
                  }
								}
								if (historyAlert.deviceType === "HPF") {
									indicatorName = "HPF";
								} else if (historyAlert.deviceType === "TURCK_TANK" || historyAlert.deviceType === "TURCK_TANKS") {
									indicatorName = "TANK";
								}else if (historyAlert.deviceType === "TURCK") {
                  indicatorName = "TURCK";
                }
								return (
									<Row className="border-bottom p-3 alert_row_container" key={i}>
										<Col lg={1} xs={3} md={2} className="alert_row_container_column__left">
											<Image
                        src={historyAlert.deviceType === "HPF" ? HPF : (historyAlert?.deviceType === 'TURCK_TANK' || historyAlert?.deviceType === "TURCK_TANKS")? (tankType === 1 ? tankVerticalIcon : HPF) : (historyAlert?.deviceType === "TURCK") ? (historyAlert?.control.controlType === "Stratus") ? stratusControl : nimbusControl: EVC}
												fluid
												className="w-100 alert_row_container--icon vertical-image"
											/>
											<div className="text-center mt-1">
												<small>{indicatorName}</small>
											</div>
										</Col>
										<Col lg={11} xs={9} md={10} className="alert_row_container_column__right">
                      <div className="text-uppercase mr-4">
                        {historyAlert.configDisplay}
                      </div>
											<p className="mb-1">{historyAlert.alertText}</p>
											<Row>
												<Col xl={5} lg={5} md={6} sm={12} xs={12}>
													<span>
                            <Image src={Time} className="mr-2" /> {t("triggered")}
                            :
													</span>{" "}
													<small>
														{dropdownTime === undefined
                              ? moment(Triggered).format(
                                "hh:mm:ss A (DD MMM YYYY)"
                              )
                              : moment
                                .tz(new Date(Triggered), dropdownTime)
                                .format("hh:mm:ss A (DD MMM YYYY)")}
													</small>
												</Col>
												<Col xl={5} lg={5} md={6} sm={12} xs={12}>
													<span>
														<Image src={ResolvedTime} className="mr-2" />
														{t("resolved")}:
													</span>{" "}
													<small>
														{dropdownTime === undefined
                              ? moment(Resolved).format(
                                "hh:mm:ss A (DD MMM YYYY)"
                              )
                              : moment
                                .tz(new Date(Resolved), dropdownTime)
                                .format("hh:mm:ss A (DD MMM YYYY)")}
													</small>
												</Col>
											</Row>
										</Col>
									</Row>
								);
              } else if(deviceTypeGlobal === "CHILLER") {
								const Triggered = new Date(historyAlert.createdAt);
								const Resolved = new Date(historyAlert.updatedAt);
                let indicator =
                  historyAlert.deviceType === "EVC" && currentTankData
                    ? currentTankData.EVC
                    : "";

								let indicatorName = "";
								if (indicator) {
                  indicator = indicator[historyAlert.deviceSerialNumber]
                    ? Number(indicator[historyAlert.deviceSerialNumber].EVC_IDENTIFICATION)
                    : "";
                  indicatorName =
                    indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
								}
								if (historyAlert.deviceType === "CHILLER") {
									indicatorName = "CHILLER";
								} else if (historyAlert.deviceType === "EVC" || indicatorName === "") {
                  let deviceNo = '';
                  if(!_.isEmpty(currentTankData)) {
                    deviceNo = !Number.isNaN(currentTankData?.EVC[historyAlert.deviceSerialNumber]?.device)? currentTankData?.EVC[historyAlert.deviceSerialNumber]?.device : "";
									}
									indicatorName = `Compressor - ${deviceNo}`;
								}
								return (
									<Row className="border-bottom p-3 alert_row_container" key={i}>
										<Col lg={1} xs={3} md={2} className="alert_row_container_column__left">
                      {
                        historyAlert.deviceType === "CHILLER" 
                          ? <ChillerDeviceIcon className="w-100 alert_row_container--icon" />
                          : <Image src={EVC} fluid className="w-100 alert_row_container--icon" />
                      }
											<div className="text-center mt-1">
												<small>{indicatorName}</small>
											</div>
										</Col>
										<Col lg={11} xs={9} md={10} className="alert_row_container_column__right">
											<p className="mb-2">{historyAlert.alertText}</p>
											<Row>
												<Col xl={5} lg={5} md={6} sm={12} xs={12}>
													<span>
                            <Image src={Time} className="mr-2" /> {t("triggered")}
                            :
													</span>{" "}
													<small>
														{dropdownTime === undefined
                              ? moment(Triggered).format(
                                "hh:mm:ss A (DD MMM YYYY)"
                              )
                              : moment
                                .tz(new Date(Triggered), dropdownTime)
                                .format("hh:mm:ss A (DD MMM YYYY)")}
													</small>
												</Col>
												<Col xl={5} lg={5} md={6} sm={12} xs={12}>
													<span>
														<Image src={ResolvedTime} className="mr-2" />
														{t("resolved")}:
													</span>{" "}
													<small>
														{dropdownTime === undefined
                              ? moment(Resolved).format(
                                "hh:mm:ss A (DD MMM YYYY)"
                              )
                              : moment
                                .tz(new Date(Resolved), dropdownTime)
                                .format("hh:mm:ss A (DD MMM YYYY)")}
													</small>
												</Col>
											</Row>
										</Col>
									</Row>
								);
							}
            })}
        </div>
      </InfiniteScroll>
    </div>
  );
});

export default LiveAlertsNotifications;
