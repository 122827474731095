/* eslint-disable */
import React, { memo, useState } from "react";
import {
  Card,
  Dropdown as BootstrapDropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import horizontalTank from "../../assets/images/tank-new.png";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import stratusControl from "../../assets/images/stratusControl.png";
import nimbusControl from "../../assets/images/nimbusControl.png";
import Group3 from "../../assets/images/Group3.svg";
import tempIcon from "../../assets/images/Temp._icn.svg";
import volumeIcon from "../../assets/images/Volume_icn.svg";
import io from "../../actions/socket";
import Dot from "../../assets/images/dot.svg";
import downCaret from "../../assets/images/arrow.svg";
import { getUser } from "../../actions/actions";
import {
  getTempAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
} from "../../helpers";
import { useMediaQuery } from "react-responsive";
import { PARSED_REGISTER_VIEW, RAW_REGISTER_VIEW } from "actions/passwordRegex";

const TankCardImg = styled.img`
  width: 35px;
  height: 40px;
  padding-right: 2px;
`;

const CardBody = styled(Card.Body)`
  padding: 10px 5px;
  background: "white";
  border: ${(props) => (props.isactive ? "2px solid #3F7CCA" : "#E3E3E3")};
  cursor: pointer;
  &:hover {
    background-color:  #eff5ff !important;
  }
`;

const UnregTankCard = styled(Card.Body)`
padding: 15px 2px 15px 8px;
cursor: not-allowed;
border: 1px solid #d6e6ff;
  border-radius: 0.25rem;
  p {
    font-size: 18px;
    letter-spacing: 9.6px;
    font-weight: bold;
  }
  color : #212F46;
`;

const TankOptions = styled.div`
  position: absolute;
  right: 5px;
  color: #2C61A5;
  .dropdown-toggle img {
    padding: 5px !important;
    &:hover {
      border-radius: 2px;
      background-color: #adcdff;
    }
  }

  .dropdown-toggle::after {
    content: none;
    &:hover {
      background: #adcdff;
    }
  }

  button.tank-option-btn {
    font-size: 14px;
    color: #212f46;
    &:hover {
      background-color: #f0f6ff;
    }
  }
  .edit-dropdown {
    transform: translate(-117px, 28px) !important;
  }
`;
const TitleDiv = styled.div`
  text-align: center;
  padding: ${({ pause }) => (pause ? "0 10px" : "0 8px")};
  cursor: pointer;
  img.caret {
    margin-left: 10px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #2c61a5;
  }
`;

const TurckTankCard = memo(
  ({
    isactive,
    tank,
    click,
    remove,
    editTank,
    toggleRegistrationCode,
    isAlert,
    setShowDropdown,
    showDropdown,
    controlCard,
    turckTankCard,
    turckTotalTanks,
    setCurrentActiveView,
  }) => {
    const [temperature, setTemperature] = useState("--");
    const [tankType, setTankType] = useState(null);
    const [volume, setVolume] = useState("--");
    const { t } = useTranslation();
    const user = getUser();
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1200 });
    const [ph1SensorValue, setPh1SensorValue] = useState("--");
    const [ph2SensorValue, setPh2SensorValue] = useState("--");
    const [percentageValue, setPercentageValue] = useState("");
    const [isDeviceOffline, setIsDeviceOffline] = useState(true);
    if (tank?.controlName?.length > 0) {
      io.socket.on(`controlData:${tank.id}`, (data) => {
        setPh1SensorValue(data.TURCK[Object.keys(data.TURCK)[0]].TURCK_PHE_TEMPERATURE_SENSOR_1);
        setPh2SensorValue(data.TURCK[Object.keys(data.TURCK)[0]].TURCK_PHE_TEMPERATURE_SENSOR_2);  
      });
    }

    io.socket.on(`influxData:${tank.type}`, (data) => {
      setIsDeviceOffline(false);
      turckTotalTanks.forEach((element,index) => {
        if (tank.id === element.id && data?.TURCK_TANKS) {
          setTemperature(data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]?.TANK_TEMPERATURE);
          setVolume(
            data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]] 
              ?.TANK_LEVEL ||
              Number(
                data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]
                  ?.TANK_LEVEL
              ) === 0
              ? data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]
                ?.TANK_LEVEL
							: "--"
					);
          setPercentageValue(data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]?.PERCENT_FULL);
          setTankType(Number(data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]?.['SILO/MILK_COOLER_SELECTION']));
        }
      });
    });

    const { temperature: tempValue, tempUnit : tempUnit } = getTempAsPerSelectedUnitSystem(
      Number(temperature)
    );

    const { temperature: ph1Temp, tempUnit : ph1Unit } = getTempAsPerSelectedUnitSystem(
      Number(ph1SensorValue)
    );

    const { temperature: ph2Temp, tempUnit : ph2Unit } = getTempAsPerSelectedUnitSystem(
      Number(ph2SensorValue)
    );

    const {
      volume: volumeValue,
      volumeUnit,
    } = getVolumeAsPerSelectedUnitSystem(Number(volume));



    const { tankName, registrationId, HPFSerialNumber , controlName, controlSerialNumber, controlType } = tank;
    return (
      <>
        {!registrationId ? (
          <Card 
          className={ (controlCard && isDesktopOrLaptop) ?  'mb-3 border' : 'mb-3 border mx-1'}
          key={tank.id}
          style={controlCard ? {
            "borderBottomLeftRadius" : "0px" ,
            "borderBottomRightRadius" : "0px"
          } : null}
          >
            <CardBody
              isactive={isactive}
              onClick={e => {
                e.stopPropagation();
                click();
              }}
              isAlert={isAlert}
              className="d-flex align-items-center"
              style={controlCard ? {"backgroundColor" : "#F7F7F7"} : {"background": `linear-gradient(to right, rgba(226, 239, 255, 1) ${percentageValue}%, transparent ${percentageValue}%)`, transition: "all 0.2s"}}
            >
              {!['Sanitarian', 'MilkHauler'].includes(user.role) && (
                <TankOptions className={`border-0 ${controlCard? "turckControlCard--bottom": 'turckControlCard--top'}`}>
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                    {controlCard ? (
                      <TitleDiv
                      onClick={() => {
                        setShowDropdown(!showDropdown);                  
                      }}
                      style={{
                        "backgroundColor" : "#eaeaea",
                        "borderRadius" : "4px",
                      }}
                      >
                        {!showDropdown ? (
                          <Image 
                            src={controlCard ? downCaret : Dot} 
                            className="caret" 
                            style={{
                              "transform" : "rotate(180deg)" , 
                              "margin-left" : "0px",
                            }}
                          />
                        ) : (
                          <Image 
                            src={controlCard ? downCaret : Dot} 
                            className="caret" 
                            style={{
                              "margin-left" : "0px",
                            }}
                          />
                        )}
                      </TitleDiv>

                    ) : (
                      <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic" className="p-0">
                        <Image src={Dot} />
                      </BootstrapDropdown.Toggle>
                    )}
                    <BootstrapDropdown.Menu
                      menualign="right"
                      flip={false}
                      id="dropdown-menu-align-right"
                      className="edit-dropdown rounded-1 border-0 p-0"
                    >
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0  text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          editTank();
                        }}
                      >
                        {t('edit')}
                      </BootstrapDropdown.Item>
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        disabled={isDeviceOffline}
                        onClick={e => {
                          e.stopPropagation();
                          setCurrentActiveView(RAW_REGISTER_VIEW)
                        }}
                      >
                        {t('raw_register')}
                      </BootstrapDropdown.Item>
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        disabled={isDeviceOffline}
                        onClick={e => {
                          e.stopPropagation();
                          setCurrentActiveView(PARSED_REGISTER_VIEW)
                        }}
                      >
                        {t('register_values')}
                      </BootstrapDropdown.Item>
                    </BootstrapDropdown.Menu>
                  </BootstrapDropdown>
                </TankOptions>
              )}
              {!["SuperAdmin", "DairyOperator", "DairyAdmin"].includes(user.role) && (
                <TankOptions className={"border-0 turckControlCard--top" }>
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                    {controlCard && (
                      <TitleDiv
                      onClick={() => {
                        setShowDropdown(!showDropdown);                  
                      }}
                      style={{
                        "backgroundColor" : "#eaeaea",
                        "borderRadius" : "4px",
                      }}
                      >
                        {!showDropdown ? (
                          <Image 
                            src={controlCard ? downCaret : Dot} 
                            className="caret" 
                            style={{
                              "transform" : "rotate(180deg)" , 
                              "margin-left" : "0px",
                            }}
                          />
                        ) : (
                          <Image 
                            src={controlCard ? downCaret : Dot} 
                            className="caret" 
                            style={{
                              "margin-left" : "0px",
                            }}
                          />
                        )}
                      </TitleDiv>

                    )}
                  </BootstrapDropdown>
                </TankOptions>
              )}
              {(controlCard && !['Sanitarian', 'MilkHauler'].includes(user.role)) && (
                <TankOptions className={`border-0 ${controlCard && 'turckControlCard--top'}`}>
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                      <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic" className="p-0">
                        <Image src={Dot} />
                      </BootstrapDropdown.Toggle>
                    <BootstrapDropdown.Menu
                      menualign="right"
                      flip={false}
                      id="dropdown-menu-align-right"
                      className="edit-dropdown rounded-1 border-0 p-0"
                    >
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0  text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          editTank();
                        }}
                      >
                        {t('edit')}
                      </BootstrapDropdown.Item>
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          remove();
                        }}
                      >
                        {t('delete')}
                      </BootstrapDropdown.Item>
                      {controlSerialNumber !== '' && (
                        <BootstrapDropdown.Item
                          variant="link"
                          className="tank-option-btn rounded-0 text-capitalize text-left w-100 text-truncate"
                          onClick={e => {
                            e.stopPropagation();
                            toggleRegistrationCode();
                          }}
                        >
                          {registrationId === '' ? t('re_register') : t('cancel_re_register')}
                        </BootstrapDropdown.Item>
                      )}
                    </BootstrapDropdown.Menu>
                  </BootstrapDropdown>
                </TankOptions>
              )}
              <div>
                {
                  controlCard ? (
                    <span 
                    className={isAlert ? 'vertical-tank-red-indicator position-relative' : ''}
                    style={{"padding" : "1px"}}
                    >
                      <TankCardImg src={controlType === 'Stratus'? stratusControl : nimbusControl} alt="" />
                    </span>
                  ) :
                  (tankType === null ? (
                    <div className="active offline offlineTank">
                      <span className="tankStatusName" style={{ width: '40px' }}>
                        --
                      </span>
                    </div>
                  ) : tankType === 1 ? (
                    <span 
                      className={isAlert ? 'vertical-tank-red-indicator position-relative' : ''}
                      style={{"padding" : "1px"}}
                    >
                      <TankCardImg src={tankVerticalIcon} alt="" style={{"width" : "23px"}} />
                    </span>
                  ) : (
                        <span 
                          className={isAlert ? 'tank-red-indicator position-relative' : ''}
                          style={{"padding" : "1px"}}
                        >
                          <TankCardImg src={horizontalTank} alt="" style={{"width" : "30px"}} />
                        </span>
                      ))
                }
              </div>

              <div className="mx-0 ml-1 text-truncate">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="annotation-tooltip">
                    {controlName ? controlName : tankName}
                    </Tooltip>
                  }
                >
                  <div className="mx-0 text-truncate" style={{"display" : "flex" , "height" : "25px", "fontWeight": isactive && "bold"}}>
                    {(!controlCard && tempValue !== "--") && <Image src={Group3} alt="" style={{"position" : "relative" , "top" : "1px"}}/>}
                    {controlName ? (
                    <>{controlName?.length > 13 ? `${controlName?.substring(0, 10)}...` : `${controlName}`}</>
                    ): (
                    <>{tankName?.length > 13 ? `${tankName?.substring(0, 10)}...` : `${tankName}`}</>
                  )}
                  </div>
                </OverlayTrigger>
                {
                  controlCard ? (
                    <p className="text-muted text-truncate m-0" style={{"display" : "flex", "flexDirection": "column", "fontSize" : "0.6rem", "fontWeight": isactive && "bold"}}>
                      <div>
                        PHE 1: 
                        &nbsp;
                        <span style={{"fontWeight" : "bolder" , "color" : "#212F46"}}>
                          {`${ph1Temp} ${ph1Unit}`}
                        </span>
                      </div>
                      <div>
                        PHE 2: 
                        &nbsp;
                        <span style={{"fontWeight" : "bolder" , "color" : "#212F46"}}>
                          {`${ph2Temp} ${ph2Unit}`}
                        </span>
                      </div>
                    </p>
                  ) : (
                    <p className="text-muted text-truncate m-0" style={{"display" : "flex" , "fontSize" : "0.6rem", "fontWeight": isactive && "bold"}}>
                      {tempValue !== "--" && <Image src={tempIcon} alt="" />}
                      &nbsp;
                      {`${tempValue} ${tempUnit} |`}
                      &nbsp;
                      {volumeValue !== "--" && <Image src={volumeIcon} alt="" />}
                      &nbsp;
                      {`${volumeValue} ${volumeUnit}`}
                      &nbsp;
                      {percentageValue !== "" && `(${percentageValue}%)`}
                    </p>
                  )
                }
              </div>
            </CardBody>
          </Card>
        ) : (
            <Card
              style={isAlert ? { "background-color": "#ffe8e8" } : null}
              // className={isDesktopOrLaptop ? "mb-3 border-0" : "mb-3 border"}
              className={ (controlCard && isDesktopOrLaptop) ?  'mb-3 border' : 'mb-2 border mx-1'}
              >
              {!['Sanitarian', 'MilkHauler'].includes(user.role) && (
                <TankOptions className="border-0">
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                    <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic" className="p-0">
                      <Image src={Dot} />
                    </BootstrapDropdown.Toggle>
                    <BootstrapDropdown.Menu
                      menualign="right"
                      flip={false}
                      id="dropdown-menu-align-right"
                      className="edit-dropdown rounded-1 border-0 p-0"
                    >
                      <Button
                        variant="link"
                        className="tank-option-btn rounded-0  text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          editTank();
                        }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          remove();
                        }}
                      >
                        {t('delete')}
                      </Button>
                      {
                        (tank?.tankName?.length > 0) ? (
                          HPFSerialNumber !== '' && (
                            <Button
                              variant="link"
                              className="tank-option-btn rounded-0 text-capitalize text-left w-100 text-truncate"
                              onClick={e => {
                                e.stopPropagation();
                                toggleRegistrationCode();
                              }}
                            >
                              {registrationId === '' ? t('re_register') : t('cancel_re_register')}
                            </Button>
                          )
                        ) : (
                          controlSerialNumber !== '' && (
                            <Button
                              variant="link"
                              className="tank-option-btn rounded-0 text-capitalize text-left w-100 text-truncate"
                              onClick={e => {
                                e.stopPropagation();
                                toggleRegistrationCode();
                              }}
                            >
                              {registrationId === '' ? t('re_register') : t('cancel_re_register')}
                            </Button>
                          )
                        )
                      }
                    </BootstrapDropdown.Menu>
                  </BootstrapDropdown>
                </TankOptions>
              )}
              <UnregTankCard>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="annotation-tooltip">
                      {controlName ? controlName : tankName}
                    </Tooltip>
                  }
                >
                  {controlName ? (
                    <span>{controlName?.length > 13 ? `${controlName?.substring(0, 10)}...` : `${controlName}`}</span>
                    ): (
                    <span>{tankName?.length > 13 ? `${tankName?.substring(0, 10)}...` : `${tankName}`}</span>
                  )}
                </OverlayTrigger>
                <p className="m-0">{registrationId}</p>
              </UnregTankCard>
            </Card>
          )}
      </>
    );
  }
);

TurckTankCard.propTypes = {
  tank: PropTypes.any,
  onClick: PropTypes.func,
  isactive: PropTypes.bool,
  remove: PropTypes.func.isRequired,
  editTank: PropTypes.func.isRequired,
};
TurckTankCard.defaultProps = {
  tank: {},
  isactive: false,
  controlCard: false,
};

export default TurckTankCard;
