const config = {
  DairyAlertUrl: process.env.REACT_APP_CoreDataAPI,
  PMCUrl: "https://my.paulmueller.com/Account/Authorize",
  PMCClientId: "33E80F6C82124F8DAE61252BB747723B",
  status_tank: {
    "0": "NULL",
    "1": "INIT",
    "2": "Power_Fail_Recovery",

    "1000": "Standby",
    "1001": "Sample_Agitate",
    "1002": "Editing_Parameters",
    "1003": "Diagnostic",
    "1004": "Settings",

    "2000": "Cooling - BU_Delay_Start",
    "2001": "Cooling - AU_Bottom_Delay_Start",
    "2002": "Cooling - AU_Upper_Delay_Start",
    "2003": "Cooling - BU_Cool",
    "2004": "Cooling - AU_Cool",
    "2005": "Cooling - BU_Delay_BU_Pulsed_Cool",
    "2006": "Cooling - AU_Delay_BU_Pulsed_Cool",

    "3000": "Washing - FW - Rinse_1_WWB",
    "3001": "Washing - FW - Delay_WWB",
    "3002": "Washing - FW - Rinse_2_WWB",
    "3003": "Washing - FW - Detergent_Wash_WWB",
    "3004": "Washing - FW - Rinse_3_WWB",
    "3005": "Washing - FW - Acid_Rinse_WWB",
    "3006": "Washing - FW - Rinse_4_WWB",
    "3007": "Washing - Sanitize_Cycle_WWB",
    "3008": "Washing - AC - Pre_Rinse_WWB",
    "3009": "Washing - AC - Acid_Rinse_WWB",
    "3010": "Washing - AC - Post_Rinse_WWB",
    "3011": "Washing - FW - Complete_WWB",
    "3012": "Washing - AC - Complete_WWB",
    "3013": "Washing - SC - Complete_WWB",

    "3100": "Washing - FW - Rinse_1_ADB",
    "3101": "Washing - FW - Delay_ADB",
    "3102": "Washing - FW - Acid_Wash_ADB",
    "3103": "Washing - FW - Rinse_2_ADB",
    "3104": "Washing - FW - Detergent_Wash_ADB",
    "3105": "Washing - FW - Rinse_3_ADB",
    "3106": "Washing - FW - Acid_Rinse_ADB",
    "3107": "Washing - FW - Rinse_4_ADB",
    "3108": "Washing - Sanitize_Cycle_ADB",
    "3109": "Washing - AC - Pre_Rinse_ADB",
    "3110": "Washing - AC - Acid_Rinse_ADB",
    "3111": "Washing - AC - Post_Rinse_ADB",
    "3112": "Washing - FW - Complete_ADB",
    "3113": "Washing - AC - Complete_ADB",
    "3114": "Washing - SC_Complete_ADB",

    "3200": "Washing - FW - Rinse_1_MMB",
    "3201": "Washing - FW - Delay_MMB",
    "3202": "Washing - FW - Rinse_2_MMB",
    "3203": "Washing - FW - Detergent_Wash_MMB",
    "3204": "Washing - FW - Rinse_3_MMB",
    "3205": "Washing - FW - Acid_Rinse_MMB",
    "3206": "Washing - Sanitize_Cycle_MMB",
    "3207": "Washing - AC - Pre_Rinse_MMB",
    "3208": "Washing - AC - Acid_Rinse_MMB",
    "3209": "Washing - FW - Complete_MMB",
    "3210": "Washing - AC - Complete_MMB",
    "3211": "Washing - SC_Complete_MMB",
  },

  status_chiller: {
		'128': 'INIT',
		'129': 'STANDBY',
		'130': 'TECH_OVERRIDE',
		'131': 'CHILLER_IDLE',
		'132': 'ALARM', //! "CHILLING_FLOW_SWITCH_FAILURE"
		'133': 'CHILLING',
		'134': 'ALARM', //! CHILLING_LOW_TEMP
		'135': 'ALARM', //! CHILLING_LOW_FLOW
		'136': 'PUMP_IDLE',
		'137': 'ALARM', //! PUMPING_FLOW_SWITCH_FAILURE
		'138': 'PUMPING',
		'139': 'ALARM', //! PUMPING_LOW_FLOW
	},

  tank_states_timer: {
    1001: "HPF_INPUT_REG_SAMPLE_AGITATE_TIMER",
    2000: "HPF_INPUT_REG_BU_DELAY_TIMER",
    2001: "HPF_INPUT_REG_BU_DELAY_TIMER",
    2002: "HPF_INPUT_REG_AU_DELAY_TIMER",
    3001: "HPF_INPUT_REG_WASH_DELAY_TIMER",
    3008: "HPF_INPUT_REG_PRE_DRAIN_TIMER",
    3010: "HPF_INPUT_REG_POST_DRAIN_TIMER",
    3101: "HPF_INPUT_REG_WASH_DELAY_TIMER",
    3109: "HPF_INPUT_REG_PRE_DRAIN_TIMER",
    3111: "HPF_INPUT_REG_POST_DRAIN_TIMER",
    3201: "HPF_INPUT_REG_WASH_DELAY_TIMER",
    3207: "HPF_INPUT_REG_PRE_DRAIN_TIMER",
  },

  status_turck_tank: {
    1000: "STANDBY",
    1001: "AGITATE",
    2007: "COOL",
    3000: "Washing - FW - Rinse_1_WWB",
    3002: "Washing - FW - Rinse_2_WWB",
    3003: "Washing - FW - Detergent_Wash_WWB",
    3004: "Washing - FW - Rinse_3_WWB",
    3005: "Washing - FW - Acid_Rinse_WWB",
    3006: "Washing - FW - Rinse_4_WWB",
    3007: "Washing - Sanitize_Cycle_WWB",
    3011: "Washing - FW - Complete_WWB",
    3013: "Washing - SC - Complete_WWB",
  },

  turck_control_parlor_wash_status:{
    3000: "FW_Rinse_1",
    3003: "FW_Detergent_Wash",
    3005: "FW_Acid_Rinse",
    3007: "Sanitize_Cycle",
    3011: "FW_Complete",
  },
  turck_control_loadOutLoop_wash_status:{
    3000: "FW_Rinse_1",
    3003: "FW_Detergent_Wash",
    3005: "FW_Acid_Rinse",
    3007: "Sanitize_Cycle",
    3011: "FW_Complete",
    3013: "SC_Complete",
  },

  /**
   * Bug Fix PL-465
   * Adding List of Values which are faulty in register #30104
   */
  REG_30104_CHILLER_ERROR_VALUES: ["132", "134", "135", "137", "139"],
  TURCK_TANKS_STATUS_VALUES: [1000, 1001, 2007, 3000, 3002, 3003, 3004, 3005, 3006, 3007, 3011, 3013]
};
module.exports = config;
