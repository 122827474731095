import React, { memo } from "react";
import { Row, Col, Image } from "react-bootstrap";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Fan from "../../assets/images/ic_fan-dashboard-new.png";
import bottomUnitIcon from "../../assets/images/BU.svg";
import { ReactComponent as PumpIcon } from "../../assets/images/noun-water-pump-2734490.svg";
import { useDeviceContext } from "context/deviceContext";

const PumpContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 12px;
	justify-content: center;
	flex-grow: 1;
`;

const PumpInfo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	width: 100%;
`;

const GreenInfoDisplay = styled.span`
	padding: 3px;
	font-size: 10px;
	border-radius: 10px;
	border: 1px solid #22bf82;
	color: #22bf82;
`;

const RedInfoDisplay = styled.span`
	padding: 3px;
	font-size: 10px;
	border-radius: 10px;
	border: 1px solid #ff4848;
	color: #ff4848;
`;

const LiveDataSideBar = memo(({ tankStatusObj, agitatedTime, liveDataHelper, deviceType, secondsToHourMin, currentTankData, dropdownTime }) => {
	const { t } = useTranslation();
	const { deviceTypeGlobal } = useDeviceContext();
	return (
		<>
			{deviceTypeGlobal === "TURCK_TANKS" && (
				<Row className="mb-4">
					<Col lg={12} className="font-weight-bold mb-2">
						{t("parlor_control_status")}
					</Col>

					{tankStatusObj?.parlorWashMode && (
						<Col lg={12} className=" d-flex align-items-center">
							<div className="mr-2">
								<div className="font-weight-bold mb-2">{tankStatusObj?.parlorWashMode}</div>
							</div>
						</Col>
					)}
					{(!tankStatusObj?.parlorWashMode ) && (
						<Col lg={12} className=" d-flex align-items-center">
							<div className="mr-2">
								<div className="font-weight-bold mb-3">--</div>
							</div>
						</Col>
					)}
					{liveDataHelper["TURCK"].map((data, index) => {
					return !data.isSkip ? (
						<Col lg={12} key={index} className="mb-2">
							<div className="d-flex align-items-center">
								<div style={{ width: "33px" }}>{data.isComponent ? data.icon : <Image src={data.icon} alt="" fluid style={{ marginRight: "12px" }} />}</div>
								<div>
									<p className="m-0">{data.name}:</p>
									{data.isLabel ? (
										data.unit === "--" ? (
											<span className="font-weight-bold">
												<span>--</span>
											</span>
										) : data.unit ? (
											<GreenInfoDisplay>{data.value}</GreenInfoDisplay>
										) : (
											<RedInfoDisplay>{data.value}</RedInfoDisplay>
										)
									) : (
										<span className="font-weight-bold">
											{data.value} {data.unit}
										</span>
									)}
								</div>
							</div>
						</Col>
					) : null;
				})}
				</Row>
			)}
			<Row className="mb-1">
				<Col lg={12} className="font-weight-bold mb-3">
					{deviceTypeGlobal === "TURCK_TANKS" ? `${(tankStatusObj?.turckTankName) ? (tankStatusObj?.turckTankName?.length > 13) ? `${tankStatusObj?.turckTankName?.substring(0, 15)}...` : `${tankStatusObj?.turckTankName}` : t('tank')} ${t("STATUS")}` : t("control_status")}
				</Col>
				{/* TODO: Verify Tank Status value from BE */}
				<Col lg={12} className="mb-3 d-flex align-items-center">
					<div className="mr-0">{tankStatusObj && tankStatusObj.image}</div>
					<div>
						<p
							className="font-weight-bold m-0"
							style={{
								color: tankStatusObj ? tankStatusObj.color : "black",
							}}
						>
							{(tankStatusObj && tankStatusObj?.textOne) ? tankStatusObj.textOne : currentTankData !== undefined ? "--" : "--"}
						</p>
						<span>
							{tankStatusObj?.mainTimer}
							{(tankStatusObj?.timerVal !== undefined && tankStatusObj?.timerVal !== "0") || tankStatusObj?.timerVal !== "0" ? (
								<span className="timerTag" id="xyz">
									{secondsToHourMin(tankStatusObj?.timerVal)}
								</span>
							) : null}
						</span>
					</div>
				</Col>

				<Col lg={12} className="mb-3 d-flex align-items-center">
					{tankStatusObj && tankStatusObj.textTwo && (
						<>
							<Image src={bottomUnitIcon} fluid />
							<div className="ml-3 text-truncate">
								{tankStatusObj.textTwo}
								{tankStatusObj.textThree ? ` - ${tankStatusObj.textThree}` : ""}
							</div>
						</>
					)}
				</Col>
				{deviceTypeGlobal === "CHILLER" && tankStatusObj && (
					<Col lg={12} className="mb-3 d-flex align-items-center">
						<PumpIcon className={`icon-pump img-fluid ${tankStatusObj?.pump1 === "1" ? "icon-pump--rotate" : ""}`} />
						<PumpContainer>
							<PumpInfo>
								<span>Pump 1</span>
								{tankStatusObj?.pump1 === "1" ? <GreenInfoDisplay>LEAD</GreenInfoDisplay> : <RedInfoDisplay>BACKUP</RedInfoDisplay>}
							</PumpInfo>
							{/* <span>(0d:0h:0m)</span> */}
						</PumpContainer>
					</Col>
				)}
				{deviceTypeGlobal === "CHILLER" && tankStatusObj && (
					<Col lg={12} className="mb-3 d-flex align-items-center">
						<PumpIcon className={`icon-pump img-fluid ${tankStatusObj?.pump2 === "1" ? "icon-pump--rotate" : ""}`} />
						<PumpContainer>
							<PumpInfo>
								<span>Pump 2</span>
								{tankStatusObj?.pump2 === "1" ? <GreenInfoDisplay>LEAD</GreenInfoDisplay> : <RedInfoDisplay>BACKUP</RedInfoDisplay>}
							</PumpInfo>
							{/* <span>(0d:0h:0m)</span> */}
						</PumpContainer>
					</Col>
				)}
			</Row>
			{(deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") && (
				<Row>
					{agitatedTime ? (
						<Col lg={12} className="font-weight-bold  mb-3">
							{t("last_agitated")}
						</Col>
					) : (
						""
					)}
					<Col lg={12} className="pr-0 d-flex align-items-center">
						<div>
							<Image src={Fan} fluid className={tankStatusObj?.hpfFan === "1" ? "fan-rotate" : "hpf-fan mr-2"} />
						</div>
						<div className="">
							<p className="m-0">
								{agitatedTime && tankStatusObj?.hpfFan === "0"
									? dropdownTime === undefined
										? moment(agitatedTime).format("D MMM, hh:mm A")
										: moment.tz(new Date(agitatedTime), dropdownTime).format("D MMM, hh:mm A")
									: ""}
							</p>
						</div>
					</Col>
				</Row>
			)}
			{deviceTypeGlobal === "TURCK_TANKS" && (
			<Row className="mb-1">
				{liveDataHelper[deviceType].map((data, index) => {
					return !data.isSkip ? (
						<Col lg={12} key={index} className="mb-2">
							<div className="d-flex align-items-center">
								<div style={{ width: "33px" }}>{data.isComponent ? data.icon : <Image src={data.icon} alt="" fluid style={{ marginRight: "12px" }} />}</div>
								<div>
									<p className="m-0">{data.name}:</p>
									{data.isLabel ? (
										data.unit === "--" ? (
											<span className="font-weight-bold">
												<span>--</span>
											</span>
										) : data.unit ? (
											<GreenInfoDisplay>{data.value}</GreenInfoDisplay>
										) : (
											<RedInfoDisplay>{data.value}</RedInfoDisplay>
										)
									) : (
										<span className="font-weight-bold">
											{data.value} {data.unit}
										</span>
									)}
								</div>
							</div>
						</Col>
					) : null;
				})}
			</Row>)}
			{ deviceTypeGlobal !== "TURCK_TANKS" && <Row className="mt-4">
				<Col lg={12} className="font-weight-bold mb-3">
					{t("live_data")}
				</Col>
				{liveDataHelper[deviceType].map((data, index) => {
					return !data.isSkip ? (
						<Col lg={12} key={index} className="mb-4">
							<div className="d-flex align-items-center">
								<div style={{ width: "33px" }}>{data.isComponent ? data.icon : <Image src={data.icon} alt="" fluid style={{ marginRight: "12px" }} />}</div>
								<div className={data.name === t("subcool_temperature") ? "ml-1" : ""}>
									<p className="m-0">{data.name}:</p>
									{data.isLabel ? (
										data.unit === "--" ? (
											<span className="font-weight-bold">
												<span>--</span>
											</span>
										) : data.unit ? (
											<GreenInfoDisplay>{data.value}</GreenInfoDisplay>
										) : (
											<RedInfoDisplay>{data.value}</RedInfoDisplay>
										)
									) : (
										<span className="font-weight-bold">
											{data.value} {data.unit}
										</span>
									)}
								</div>
							</div>
						</Col>
					) : null;
				})}
			 </Row>
			}
			{deviceTypeGlobal === "TURCK_TANKS" && (
				<Row className="mt-3">
					<Col lg={12} className="font-weight-bold mb-2">
						{t("load_out_loop_status")} 
					</Col>

					{tankStatusObj?.loadOutLoopStatus && (
						<Col lg={12} className=" d-flex align-items-center">
							<div className="mr-2">
								<div className="font-weight-bold mb-2">{tankStatusObj?.loadOutLoopStatus}</div>
							</div>
						</Col>
					)}
					{(!tankStatusObj?.loadOutLoopStatus ) && (
						<Col lg={12} className=" d-flex align-items-center">
							<div className="mr-2">
								<div className="font-weight-bold mb-2">--</div>
							</div>
						</Col>
					)}
				</Row>
			)}
		</>
	);
});

export default LiveDataSideBar;
