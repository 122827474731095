import React, { memo, useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { useParams, withRouter } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { isCancel } from "axios";
import TankCard from "../../components/TankCard";
import Settings from "../../containers/Tank/Settings";
import TankHPF from "../../assets/images/Tank002.png";
import EVC_GIF_ICON from "../../assets/images/EVC-fan-gif.gif";
import EVC_ICON from '../../assets/images/EVC-fan.png';
import {ReactComponent as TelosChillerIcon} from "../../assets/images/Telos_Chiller.svg";
import {ReactComponent as NimbusIcon} from "../../assets/images/Nimbus.svg";
import {ReactComponent as StratusIcon} from "../../assets/images/Stratus.svg";
import {ReactComponent as HiperformIcon} from "../../assets/images/HiPerForm_Plus.svg";
import {ReactComponent as SaturatedSuctionIcon} from '../../assets/images/Group 3757.svg';
import TankTwo from "../../assets/images/EVC-fan-gif.gif";
import io from "../../actions/socket";
import TanksLoader from "../../components/TankLoader";
import GraphLoader from "../../components/GraphLoader";
import LiveDataSideBar from "../../components/LiveDataContent";
import { isEmpty } from "lodash";
import { useMediaQuery } from "react-responsive";
import { Drawer } from "antd";

import {
  getTanks,
  fetchDairy as fetchFarmUsers,
  getChillers,
  getTankHPFandEVCId,
  getTankCHILLERandEVCId,
  getLastAgitatedValue,
  fetchGraph,
  fetchGraphChiller,
  fetchDairy,
  addTank,
  addNewChillerController,
  deleteTank,
  deleteChiller,
  updateTank,
  updateChiller,
  getTankById,
  getChillerById,
  getUser,
  getTankAlerts,
  getChillerAlerts,
  fetchConfigs,
  UpdateNoOfEVCsByID,
  getDeviceByID,
  getDevices,
  getTurckControlById,
  fetchTurckGraph,
  getTurckTankandEVCId,
  addTurckControl,
  deleteTurckControl,
  updateTurckControl,
  getTurckAlerts,
  getTurckLastAgitateStatus
} from "../../actions/actions";
import addTankIcon from "../../assets/images/white-plus-icon.svg";
import downCaret from "../../assets/images/date-box-caret.svg";
import AddTankForm from "../../forms/TankForm/AddTankForm";
import AddDeviceControlForm from "../../forms/DeviceControlForm/AddDeviceControlForm";
import Toaster from "../../components/Toaster";
import volumeIcon from "../../assets/images/Volume.svg";
import thermometerIcon from "../../assets/images/thermometer.svg";
import pipelineTempIcon from "../../assets/images/pipeline-temp.svg";
import valveIcon from "../../assets/images/EVC-valve.svg";
import hspIcon from "../../assets/images/HSP-Pressure.svg";
import lspIcon from "../../assets/images/LSP-Pressure.svg";
import subCoolTempIcon from "../../assets/images/sub-cool-temp.svg";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import threeLineIcon from "../../assets/images/menu.svg";
import NoTankDataAvailable from "../../components/NoTankDataAvailable";
import {ReactComponent as StandByImg} from '../../assets/images/Standby.svg';
import {ReactComponent as AlertImg} from "../../assets/images/alert-icon.svg";
import coolingIcon from '../../assets/images/chiller_control_cool_icon.svg';
import waveIcon from '../../assets/images/chiller_control_wave_icon.svg';
import Tank_Temperature from '../../assets/images/Tank_Temperature.svg';
import Load_Out_Loop_Temperature from '../../assets/images/Load_Out_Loop_Temperature.svg';
import Tank_Level from '../../assets/images/Tank_Level.svg';
import PHE_Temperature_Sensor_1 from '../../assets/images/PHE_Temperature_Sensor_1.svg';
import PHE_Temperature_Sensor_2 from '../../assets/images/PHE_Temperature_Sensor_2.svg';
import {
  status_tank as statusTank,
  status_chiller as statusChiller,
  status_turck_tank as statusTurckTank,
  tank_states_timer as tankStatesTimer,
  TURCK_TANKS_STATUS_VALUES,
  turck_control_parlor_wash_status as parlorWashStates,
  turck_control_loadOutLoop_wash_status as loadOutLoopWashStates,
  REG_30104_CHILLER_ERROR_VALUES,
} from "../../config";
import statusImgCooling from "../../assets/images/cool-icon.svg";
import statusImgWashing from "../../assets/images/washing.png";
import standByIcon from "../../assets/images/Standby_Mode.png";
import settingsIcon from "../../assets/images/settings.png";
import diagnosticIcon from "../../assets/images/Diagnostics.png";
import LiveAlertsNotifications from "../../containers/Tank/LiveAlertsNotifications";
import AlertIcon from "../../assets/images/alert-icon.svg";
import fanSpeedIcon from "../../assets/images/Group 3861.svg";
import CondensingIcon from "../../assets/images/noun-temperature-3208741.svg";
import PromptModel from "../../components/PromptModel";
import ExportCsvForm from "../../forms/ExportCsvForm/ExportCsv";
import NoDataAvailable from "../../components/NoDataAvailable";
import Evc from "./EVC";
import Hpf from "./HPF";
import Chiller from "./CHILLER"
import { rootApi } from "../../axiosConfig";

import { sensors, ranges, secondsToHourMin,
  SETTINGS_VIEW, GRAPH_VIEW, LIVE_ALERT_VIEW
} from "../../actions/passwordRegex";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getStringFromDecimalToBinary,
  getBinaryStringFromDecimal
} from "../../helpers";

import {ReactComponent as ThermometerIcon} from '../../assets/images/thermometer.svg';
import { useDeviceContext } from "context/deviceContext";
import TurckDropdown from "components/TurckCustomDropdown/TurckDropdown";
import Turck from "./TURCK";

const GraphButtonsDropdown = styled.div`
  position: absolute;
  top: 33px;
  left: -128px;
  display: ${({ show }) => (show ? "initial" : "none")};
  > span {
    display: block;
    font-size: 14px;
    color: #212f46;
    // cursor: pointer;
    padding: 5px 15px;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  span.date-range-span {
    display: flex;
    justify-content: space-between;
  }
  width: 150px;
  z-index: 10;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 5px 0;
`;

const TankStatusImg = styled(Image)`
  align-self: center;
  margin-right: 10px;
  height: 34px;
`;
let tempSensorStatusRegisterBits = [];
const TankListingMobile = memo((props) => {
  const { t } = useTranslation();
  const user = getUser();
  const { deviceTypeGlobal, setDeviceTypeGlobal } = useDeviceContext();
  const [agitatedTime, setAgitatedTime] = useState(null);
  const [hpfGraphData, setHPFGraphData] = useState("");
  const [chillerGraphData, setChillerGraphData] = useState("");
  const [evcGraphData, setEVCGraphData] = useState(["", "", ""]);
  const [showAlertIcon, setShowAlertIcon] = useState({});
  const [liveData, setLiveData] = useState({
    temperature: "--",
    volume: "--",
    pipelineTemperature: "--",
    hspValue: "--",
    lspValue: "--",
    valvePos: "--",
    subCoolTemp: "--",
    saturatedSuctionTemp: "--",
    condensingTemp: "--",
    fanSpeed: "--",
    voltageInputStatus: "--",
    probeSensorStatus: "--",
    chillerOutletTemp: "--",
    chillerReturnTemp: "--",
    processOutletTemp: "--",
    processOutletTemp: "--",
    chillerFlow: "--",
    processFlow: "--",
    accumulatorTemperatureDifference: "--"
  });
  const [showAddTank, setShowAddTank] = useState(false);
  const [isTankRegistered, setIsTankRegistered] = useState(true);
  const [customDates, setCustomDates] = useState([
    new Date(ranges.Yesterday[0]).toISOString(),
    new Date().toISOString(),
  ]);
  const { dairyID, tankID } = useParams();
  const [tanks, setTanks] = useState([]);
  const [dairyName, setDairyName] = useState("");
  const [dairyAdmin, setDairyAdmin] = useState(undefined);
  const [dealerID, setDealerID] = useState("");
  const [currentEditTank, setCurrentEditTank] = useState({});
  const [isTankEdit, setIsTankEdit] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [deviceIdObj, setDeviceIdObj] = useState(null);
  const [currentEnableDeviceIDs, setCurrentEnableDeviceIDs] = useState(deviceIdObj)
  const [reloadCount, setReloadCount] = useState(0);
  const [autoReloadInterval, setAutoReloadInterval] = useState(null);
  const [relIntVar, setRelIntVar] = useState(null);
  const [graphLoader, setGraphLoader] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(new Date());
  const [dateTimeIntVar, setDateTimeIntVar] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [tankStatusCode, setTankStatusCode] = useState("");
  const [chillerStatusCode, setChillerStatusCode] = useState("");
  const [tankStatusObj, setTankStatusObj] = useState(null);
  const [tankDeleteAlert, setTankDeleteAlert] = useState(false);
  const [tankDeleteIndex, setTankDeleteIndex] = useState(null);
  const [tanksLoader, setTanksLoader] = useState(false);
  const [drag, setDrag] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [washData, setWashData] = useState([]);
  const [currentTankData, setCurrentTankData] = useState({});
  const [showGraphButtons, setShowGraphButtons] = useState(false);
  const [configId, setConfigId] = useState(null);
  const [currentActiveView, setCurrentActiveView] = useState(GRAPH_VIEW);
  const [isFanRotating, setIsFanRotating] = useState(Array.from('0'.repeat(6)));
  const node = useRef();

  /** Define Export csv state */
  const [show, setShow] = useState(false);
  const [exportDeviceIds, setExportDeviceIds] = useState({});
  const [tankType, setTankType] = useState(null);
  const [visible, setVisible] = useState(false);
  // const [redIndicator, setRedIndicator] = useState([]);
  const [unResolvedDevices, setUnResolvedDevices] = useState([]);
  const [tankAlert, setTankAlert] = useState([]);
  const [chillerTemperatureFailure, setChillerTemperatureFailures] = useState({
    chillerOutletTempFailure: 0,
    chillerReturnTempFailure: 0,
    processInletTempFailure: 0,
    processOutletTempFailure: 0,
  });
  const [turckTotalTanks, setTurckTotalTanks] = useState([]);
  const [turckcontrolData, setTurckControlData] = useState(null);
  const [turckControlObjectId, setTurckControlObjectId] = useState("");
  const [turckGraphData, setTurckGraphData] = useState("");
  const [turckTankStatusCode, setTurckTankStatusCode] = useState("");
  const isResponsiveMode = true;

  const isMobile = useMediaQuery({
    query: "(min-device-width: 1199px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const [toggleShow, setToggleShow] = useState(false);

  const tankDeviceType = useMemo(() => {
    if (tankID) {
      const foundTank = tanks?.find(tank => tank.id === tankID);
      return foundTank?.deviceType;
    }
    return null
  }, [tankID, tanks.length]);

  const TankChillerIcon = useMemo(() => {
    switch (tankDeviceType) {
      case "HiPerForm_Plus":
        return HiperformIcon;
      case "Telos_Chiller":
        return TelosChillerIcon;
      case "Telos_Tank_EU":
        return TelosChillerIcon;
      case "Telos_Tank_NA":
        return TelosChillerIcon;
      case "Nimbus":
        return NimbusIcon;
      case "Stratus":
        return StratusIcon;
      default:
        return () => '--';
    }
  }, [tankDeviceType]);

  // To toggle the three-line-menu
  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setShowGraphButtons(false);
  };

const getloadOutLoopStatus = (regVal1, regVal2, regVal3, regVal4) => {
	//regVal1 = 30018(Register Address), regVal2 = 30019, regVal3 = 30039, regVal1 = 30040,
	if (regVal1 === 0 && regVal3 === 0 && regVal4 === 0) {
		return t("STANDBY");
	} else if (regVal1 !== 0 && regVal3 === 0 && regVal4 === 0 && TURCK_TANKS_STATUS_VALUES.includes(regVal2)) {
		return t(loadOutLoopWashStates[regVal2]);
	} else if (regVal3 !== 0 && regVal1 === 0 && regVal4 === 0) {
		return `${t("tank")} ${regVal3} ${t("selected_for_load_out")}`;
	} else if (regVal4 !== 0 && regVal1 === 0 && regVal3 === 0) {
		return `${t("tank")} ${regVal4} ${t("selected_for_wash")}`;
	}
};

const getParlorControlStatus = (regVal1, regVal2) => {
  if (regVal1 === 0 && regVal2 === 0) {
    return t("STANDBY");
  } else if (regVal1 === 0 && regVal2 !== 0 && TURCK_TANKS_STATUS_VALUES.includes(regVal2)) {
    return t(parlorWashStates[regVal2]);
  } else if (regVal1 !== 0 && regVal2 === 0) {
    return t("MILK_MODE");
  }
};

/* Clear the DeviceType & DeviceTypeGlobal when component unmounts */
useEffect(()=> {
  return () => {
    setDeviceType(null);
    setDeviceTypeGlobal(null);
  }
},[]);

  useEffect(() => {
    if (showGraphButtons) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showGraphButtons]);

  /** Fetch config Api call */
  useEffect(() => {
    fetchConfigs()
      .then((response) => {
        if (response.status === 200) {
          const item = response.data.find((i) =>
            i.key === "MANUAL_ANNOTATION" ? i : response.data
          );
          setConfigId(item.id);
          const sensorItems = response.data;
          const sensor = sensorItems.filter((sensorData) => sensorData.unit !== "");
          localStorage.setItem("configs", JSON.stringify(sensor));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const getGraphData = async () => {
    setGraphLoader(true);
    let devID = "";
        if (!deviceId) {
      if (!deviceIdObj) {
        const { data } = deviceTypeGlobal === "HPF" ?
                          await getTankHPFandEVCId(tankID)
                          : deviceTypeGlobal === "CHILLER"? await getTankCHILLERandEVCId(tankID): await getTurckTankandEVCId(tankID, turckControlObjectId);
        setExportDeviceIds(data);
        devID = data[deviceType]?.[0];
      } else {
        devID = deviceIdObj[deviceType]?.[0];
      }
    } else {
      devID = deviceId;
    }
    if (deviceType === "HPF" && deviceTypeGlobal === "HPF") {      
      try {
        const value = await fetchGraph(
          "Custom",
          deviceType,
          sensors.HPF,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }else if (deviceType === "TURCK_TANKS" && deviceTypeGlobal === "TURCK_TANKS") {
      try {
				setTurckGraphData(null);
				const value = await fetchTurckGraph(
					"Custom",
					deviceType,
					sensors.TURCK_TANKS,
					tankID,
					turckControlObjectId,
					devID,
					turckcontrolData?.controlSerialNumber,
					customDates[0],
					customDates[1]
				);
				setTurckGraphData(value.data.graphData);
				setWashData(value.data.washStatus);
				setGraphLoader(false);
				return;
			} catch (error) {
				if (isCancel(error)) {
					console.log("Request canceled:", error);
				} else {
					console.log(error);
					toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
				}
			}
    } else if (deviceType === "CHILLER" && deviceTypeGlobal === "CHILLER") {
      try {
        const value = await fetchGraphChiller(
          "Custom",
          "CHILLER",
          sensors.CHILLER,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setChillerGraphData(value.data.graphData);
        // setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    for (let i = 0; i < sensors.EVC.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = deviceTypeGlobal === "HPF" ?
          await fetchGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
          : deviceTypeGlobal === "CHILLER" ? await fetchGraphChiller(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          ) : (deviceTypeGlobal === "TURCK_TANKS" && deviceType === "EVC") && await fetchTurckGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            turckControlObjectId,
            devID,
            turckcontrolData?.controlSerialNumber,
            customDates[0],
            customDates[1]
          );
        evcGraphData[i] = res?.data?.graphData;
      } catch (error) {
        if (isCancel(error)) {
					console.log("Request canceled:", error);
				} else {
					console.log(error);
					toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
				}
      }
    }
    setEVCGraphData([...evcGraphData]);
    setGraphLoader(false);
  };

  const getTankDetail = async () => {
    setGraphLoader(true);
    setAgitatedTime(null);
    setDeviceIdObj(null);
    let tempDeviceID;
    if(deviceTypeGlobal === "HPF") {
      const { data } = await getTankHPFandEVCId(tankID);
      setDeviceType("HPF")
      if (data.HPF.length > 0) {
				const result = await getLastAgitatedValue(tankID, data.HPF[0]);
				setAgitatedTime(result?.data[0]?.time);
				setIsTankRegistered(true);
				let HPF = [];
				if (data.HPF) {
					HPF[0] = data.HPF[0];
				}
				let EVC = [];
				if (data.EVC) {
					EVC = data.EVC;
				}
        await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
				setDeviceIdObj({ HPF, EVC });
				setDeviceId(data.HPF[0]);
				tempDeviceID = data.HPF[0];
			} else {
        const { data: tankData } = await getTankById(tankID);
        if (tankData.registrationId) {
          setIsTankRegistered(false);
        } else {
          tempDeviceID = tankData.id;
          setIsTankRegistered(true);
        }
      } 
      try {
        const value = await fetchGraph(
          "Custom",
          "HPF",
          sensors.HPF,
          tempDeviceID,
          data.HPF[0],
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    } else if(deviceTypeGlobal === "CHILLER") {
        const { data } = await getTankCHILLERandEVCId(tankID);
        setDeviceType("CHILLER");
        if (data.CHILLER.length > 0) {
          setIsTankRegistered(true);
          let CHILLER = [];
          if (data.CHILLER) {
            CHILLER[0] = data.CHILLER[0];
          }
          let EVC = [];
          if (data.EVC) {
            EVC = data.EVC;
          }
          await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
          setDeviceIdObj({ CHILLER, EVC });
          setDeviceId(data.CHILLER[0]);
          tempDeviceID = data.CHILLER[0];
        } else {
          const { data: tankData } = await getChillerById(tankID);
          setDeviceId(tankData.deviceSerialNumber)
          if (tankData.deviceRegistrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = tankData.id;
            setIsTankRegistered(true);
          }
        }
        try {
          const value = await fetchGraphChiller(
            "Custom",
            "CHILLER",
            sensors.CHILLER,
            tankID,
            tempDeviceID,
            customDates[0],
            customDates[1]
          );
          setChillerGraphData(value.data.graphData);
        } catch (error) {
          console.log(error);
          toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
        }
    } else if (deviceTypeGlobal === "TURCK_TANKS" && turckControlObjectId) {
        const { data } = await getTurckTankandEVCId(tankID, turckControlObjectId);
        setDeviceType("TURCK_TANKS");
        if(data.TURCK_TANKS?.length > 0) { 
          const result = await getTurckLastAgitateStatus(tankID);
          setAgitatedTime(result?.data[0]?.time);
          let currentControl;
        
          if (tanks?.length === 0) {
            const currentTanktype = await getTankById(tankID);
            currentControl = await getTurckControlById(currentTanktype?.data?.type?.id);
          }
          let totalTurck = (tanks?.length === 0) ?  currentControl?.data.tanks : turckTotalTanks;
          
          totalTurck?.length > 0 && totalTurck.map(async(turckTank,index) => {
            if (turckTank.id === tankID) {
 
              setIsTankRegistered(true);
              let TURCK_TANKS = [];
            if (data?.TURCK_TANKS?.length) {
              // TURCK_TANKS[index] = data.TURCK_TANKS[index]
                TURCK_TANKS = data.TURCK_TANKS;
              }
              let EVC = [];
              if (data.EVC) {
                EVC = data.EVC;
              }
              await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
              setDeviceIdObj({ TURCK_TANKS, EVC });
              setDeviceId(data?.TURCK_TANKS[0]);
            }
            tempDeviceID = data?.TURCK_TANKS[0];
          }) 
        } else {
          const { data: tankData } = await getTankById(tankID);
          if (tankData.registrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = tankData.id;
            setIsTankRegistered(true);
          }
        }
        try {
					const value = await fetchTurckGraph(
						"Custom",
						"TURCK_TANKS",
						sensors.TURCK_TANKS,
						tankID,
						turckControlObjectId,
						tempDeviceID,
						turckcontrolData?.controlSerialNumber,
						customDates[0],
						customDates[1]
					);
					setTurckGraphData(value.data.graphData);
					setWashData(value.data.washStatus);
				} catch (error) {
					if (isCancel(error)) {
						console.log("Request canceled:", error);
					} else {
						console.log(error);
						toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
					}
				}
      }
    setGraphLoader(false);
  };

  /** Add new Tank functionality */
  const addNewTank = async (data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = data.deviceType === "HiPerForm_Plus" ? await addTank(temp) : data.deviceType==='Telos_Chiller' ? await addNewChillerController(temp) : await addTurckControl(temp);
      if (((data.deviceType === "HiPerForm_Plus" || data.controlType) && dairyID === res.data.dairy.id) || (data.deviceType === "Telos_Chiller" && dairyID === res.data.locationName.id)) {
        setTanks([...tanks, res.data]);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={t("device_added")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (err) {
      console.log(err);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };
  /** Edit Tank functionality */
  const editTank = async (id, data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = data.deviceType === "HiPerForm_Plus" ? await updateTank(id, temp) : ( data.controlType ) ? await updateTurckControl(id, temp) : await updateChiller(id, temp);
      if (res.status === 200) {
        const response = await getDevices(dairyID);
        const tempStaticItems = sortItems(response.data);
        setTanks(tempStaticItems);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={t("device_updated")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Remove tank functionality  */
  const removeTank = async (index) => {
    const { location, history } = props;
    const currentTankId = tanks[index].id;
    try {
      tanks[index]?.deviceType ? await deleteChiller(currentTankId) : ( tanks[index]?.tanks && tanks[index]?.controlType ) ? await deleteTurckControl(currentTankId) : await deleteTank(currentTankId); 
      const res = await getDevices(dairyID);
      setTankDeleteAlert(false);
      setTanks(res.data);
      toast.dark(
        <Toaster
          className="text-capitalize"
          icon="notify"
          message={t("device_deleted")}
        />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const handleDateCallback = (start, end, label) => {
    customDates[0] = start.toISOString();
    customDates[1] = end.toISOString();
    setCustomDates([...customDates]);
  };

  const onBeforeDragStart = () => {
    setDrag(true);
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    setDrag(false);
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }
    const newTankOrder = Array.from(tanks);
    const [replaceItem] = newTankOrder.splice(source.index, 1);
    newTankOrder.splice(destination.index, 0, replaceItem);
    setTanks([...newTankOrder]);
  };

  const tick = () => {
    setCurrentDatetime(new Date());
  };

  const setStatusTank = () => {
    let image;
    let textOne;
    let textTwo;
    let textThree;
    let color = "black";
    let hpfFan = "0";
    let mainTimer;
    let timerVal;
    let pump1;
    let pump2;
    let parlorWashMode ='';
    let loadOutLoopStatus = '';
    let turckTankName = '';
    if (
      currentTankData &&
      currentTankData["CHILLER"] &&
      Object.keys(currentTankData.CHILLER).length
    ) {
      pump1 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        12
      );
      pump2 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        14
      );
    }
    if(deviceTypeGlobal === "HPF") {
      if (tankStatusCode !== "") {
        if (statusTank[tankStatusCode].indexOf("Washing") > -1) {
          image = <TankStatusImg src={statusImgWashing} fluid />;
          mainTimer = (
            <span className="timerTag">
              (
              {secondsToHourMin(
                currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
                  .HPF_INPUT_REG_WASH_TIME_REMAINING
              )}
              )
            </span>
          );
        } else if (statusTank[tankStatusCode].indexOf("Cooling") > -1) {
          textOne = t("cooling");
          image = <TankStatusImg src={statusImgCooling} fluid />
          color = "#2c61a5";
          const minToSec =
            currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
              .HPF_INPUT_REG_COOL_CYCLE_TIMER * 60;

          mainTimer =
            minToSec !== "" && tankStatusCode > 2002 ? (
              <span className="timerTag">
                {minToSec === "" ? "" : secondsToHourMin(minToSec)}
              </span>
            ) : null;
      } else if (statusTank[tankStatusCode].indexOf("Standby") > -1) {
          image = <TankStatusImg src={standByIcon} fluid /> 
        } else if (
          statusTank[tankStatusCode].indexOf("Editing Parameters") > -1 ||
          statusTank[tankStatusCode].indexOf("Setup") > -1
        ) {
          image = <TankStatusImg src={settingsIcon} fluid />
        } else if (statusTank[tankStatusCode] === "Diagnostic") {
          image = <TankStatusImg src={diagnosticIcon} fluid />
        }
        hpfFan =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
            .AGITATOR_MOTOR_RELAY;

        textOne = t(statusTank[tankStatusCode].split(" - ")[0]);
        textTwo = t(statusTank[tankStatusCode].split(" - ")[1]);
        textThree = t(statusTank[tankStatusCode].split(" - ")[2]);
        if (currentTankData !== null && tankStatusCode !== "") {
          let timerName = tankStatesTimer[tankStatusCode.toString()];
          timerVal =
            currentTankData.HPF[Object.keys(currentTankData.HPF)[0]][timerName];
        }
      } else {
        mainTimer = "";
      }
    } else if(deviceTypeGlobal === "CHILLER"){
      if(chillerStatusCode !== "") {
        color = REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? 'red' : 'black'
        textOne = t(statusChiller[chillerStatusCode]);

        if(statusChiller[chillerStatusCode] === 'CHILLER_IDLE'  || statusChiller[chillerStatusCode] === 'CHILLING') {
          image = <TankStatusImg src={coolingIcon} fluid  className="mr-0"/>
        }
        else if (statusChiller[chillerStatusCode] === 'PUMP_IDLE' || statusChiller[chillerStatusCode] === 'PUMPING' ) {
          image = <TankStatusImg src={waveIcon} fluid className="mr-0"/>
        }
        else if (statusChiller[chillerStatusCode] === 'ALARM'){
          image = <AlertImg className={`${REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? "fill-red" : ""} + mr_8`}/>
        }
        else if (statusChiller[chillerStatusCode] === 'TECH_OVERRIDE'){
          image = <TankStatusImg src={diagnosticIcon} fluid className="status__icon--height"/>
        }
        else {
          image = <StandByImg className={`${REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? "fill-red" : "" } + mr_4`} />;
        }
      }
    } else if (deviceTypeGlobal === 'TURCK_TANKS') {
      if (turckTankStatusCode && turckTankStatusCode !== '' && TURCK_TANKS_STATUS_VALUES.includes(turckTankStatusCode)) {
        if (statusTurckTank[turckTankStatusCode].indexOf('Washing') > -1) {
          image = <TankStatusImg src={statusImgWashing} fluid />;
          mainTimer = (
            <span className="timerTag">
              ({secondsToHourMin(currentTankData?.TURCK_TANKS?.[tankID]?.WASH_TIME_REMAINING)})
            </span>
          );
        } else if (statusTurckTank[turckTankStatusCode].indexOf('COOL') > -1) {
          textOne = t('cooling');
          image = <TankStatusImg src={statusImgCooling} fluid />;
          color = '#2c61a5';
          const minToSec = currentTankData?.TURCK_TANKS?.[tankID]?.COOL_CYCLE_TIMER * 60;

          mainTimer = minToSec !== '' && turckTankStatusCode > 2002 ? <span className="timerTag">{minToSec === '' ? '' : secondsToHourMin(minToSec)}</span> : null;
        } else if (statusTurckTank[turckTankStatusCode].indexOf('STANDBY') > -1) {
          image = <TankStatusImg src={standByIcon} fluid /> 
        }
        hpfFan = currentTankData?.TURCK_TANKS?.[tankID]?.AGITATOR_OUTPUT.toString();
        textOne = t(statusTurckTank[turckTankStatusCode].split(' - ')[0]);
        textTwo = t(statusTurckTank[turckTankStatusCode].split(' - ')[1]);
        textThree = t(statusTurckTank[turckTankStatusCode].split(' - ')[2]);
        if (currentTankData !== null && turckTankStatusCode !== '') {
          let timerName = tankStatesTimer[turckTankStatusCode.toString()];
          timerVal = currentTankData?.TURCK_TANKS?.[tankID]?.[timerName];
        }
      } else {
        mainTimer = '';
      }
      if(turckcontrolData?.tanks?.length) {
        turckTankName = turckcontrolData?.tanks?.find((tank) => tank.id === tankID)?.tankName;
      }
      if (currentTankData && currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]) {
				parlorWashMode = getParlorControlStatus(
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_PARLOR_MILK_MODE"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_PARLOR_WASH_MODE"]
				);
				loadOutLoopStatus = getloadOutLoopStatus(
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_LOAD_OUT_LOOP_WASH_SELECTED"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_LOAD_OUT_LOOP_WASHING"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_TANK_SELECTED_FOR_LOADOUT"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_TANK_SELECTED_FOR_WASH"]
				);
			}
    }

    const temp = {
      image,
      textOne,
      textTwo,
      textThree,
      color,
      hpfFan,
      mainTimer,
      timerVal,
      pump1,
      pump2,
      parlorWashMode,
      loadOutLoopStatus,
      turckTankName
    };
    setTankStatusObj(temp);
  };

  const getAlerts = async () => {
    try {
      const startTime = Date.parse(customDates[0]);
      const endTime = Date.parse(customDates[1]);
      const res = deviceTypeGlobal === "HPF" ? await getTankAlerts(tankID, startTime, endTime) : deviceTypeGlobal === "TURCK_TANKS" ? await getTurckAlerts(tankID, turckControlObjectId, startTime, endTime) : await getChillerAlerts(tankID, startTime, endTime);
      setAlerts(res.data);
    } catch (error) {
      console.error(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Index wise sort Item */
  function sortItems(obj) {
      return obj.sort((item1, item2) => {
        let tmpItem1 = item1.index;
        let tmpItem2 = item2.index;
        if (tmpItem1 < tmpItem2) {
          return -1;
        }
        if (tmpItem1 > tmpItem2) {
          return 1;
        }
        return 0;
      });
  }

  const handleDeviceClick = (key, id, evcDisabledSignal) => {
      if ((deviceType === key && deviceId === id) || (key === "EVC" && evcDisabledSignal)) {
        return;
      }
      setDeviceType(`${key}`);
      setDeviceId(`${id}`);
      setCurrentActiveView(GRAPH_VIEW)
  }

  const getDevice = async (tankID, tanks) => {
    if(tankID && tanks?.length) {
      const deviceObj = tanks.find(tank => tank.id === tankID);
      if(deviceObj && deviceObj.type) {
        const {data: controlData} = await getTurckControlById(deviceObj.type);
        setTurckTotalTanks(controlData?.tanks);
        setTurckControlData(controlData);
        setTurckControlObjectId(deviceObj.type);
      }
      if(deviceObj && Object.keys(deviceObj).length){
        if(deviceObj.deviceType){
          setDeviceId(deviceObj.deviceSerialNumber)
          setDeviceTypeGlobal("CHILLER")
          setDeviceType("CHILLER")
          setCurrentTankData({})
          setTankType(null)
          setTurckControlObjectId(null);
        }
        else if(deviceObj.type){
          setDeviceId(tankID)
          setDeviceTypeGlobal("TURCK_TANKS")
          setDeviceType("TURCK_TANKS")
          setTurckGraphData("")
          setCurrentTankData({})
          setTankType(null)
        }else {
          setDeviceId(deviceObj.HPFSerialNumber)
          setDeviceTypeGlobal("HPF")
          setDeviceType("HPF")
          setCurrentTankData({})
          setTankType(null)
          setTurckControlObjectId(null);
        }
      }
    }
  }

  useEffect(() => {
    getDevice(tankID,tanks)
    return () => {
      // emptying global deviceType on component unmount
      setDeviceTypeGlobal(null)
    }
  }, [tankID,tanks])

  useEffect(() => {
    if (deviceTypeGlobal) {
      getTankDetail();
      getAlerts();
      fetchFarmUsers(dairyID)
      .then((response) => {
        const result = response?.data?.farmUsers.some(item => item.isActive === true && item.role === "DairyAdmin");
        setDairyAdmin(result);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      });
    }
  }, [deviceTypeGlobal, tankID, turckControlObjectId, turckTotalTanks]);

  useEffect(() => {
    if(deviceTypeGlobal) {
      setLiveData({
        temperature: "--",
        tankLevel : "--",
        temperatureTURCK: "--",
        pheSensor1 : "--",
        pheSensor2 : "--",
        loadOutLoop: "--",
        volume: "--",
        pipelineTemperature: "--",
        hspValue: "--",
        lspValue: "--",
        valvePos: "--",
        subCoolTemp: "--",
        saturatedSuctionTemp: "--",
        condensingTemp: "--",
        fanSpeed: "--",
        voltageInputStatus: "--",
        probeSensorStatus: "--",
        pump1: null,
        pump2: null,
        chillerFlow: "--",
        processFlow: "--",
        accumulatorTemperatureDifference: "--",
      });
      setShowAnnotation(false);
      setTankStatusObj(null);
           io.socket.on(`influxData:${deviceTypeGlobal === 'TURCK_TANKS'? turckControlObjectId: tankID}`, (data) => {
        const statusNumber = deviceTypeGlobal === "HPF"
          ? data?.HPF?.[Object.keys(data.HPF)[0]].HPF_INPUT_REG_MAIN_STATE
          : deviceTypeGlobal=== 'CHILLER'? data?.CHILLER?.[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE : [];
        let temp;
        let chillerTempFailures = {
          chillerOutletTempFailure: 0,
          chillerReturnTempFailure: 0,
          processInletTempFailure: 0,
          processOutletTempFailure: 0,
        };
        if (deviceType === "CHILLER" && data?.CHILLER) {
          const temperature =
            data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1;

          const temperatureSensor = Number(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_1) || 0;

          const pump1 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 12);
          const pump2 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 14);
          const voltageInputStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS, 0);
          const probeSensorStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS, 0);
          const chillerOutletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1
          const chillerReturnTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_2
          const processInletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_4
          const processOutletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_3
          const shouldDisplayProcessFlow = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_PROCESS_FLOW_METER_PRESENT_PARAM == "1"
          const shouldDisplayChillerFlow = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_CHILLER_FLOW_METER_PRESENT_PARAM == "1"
          const processFlow = shouldDisplayProcessFlow ? data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ANALOG_1_UA : "--"
          const chillerFlow = shouldDisplayChillerFlow ? data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ANALOG_2_UA : "--"
          chillerTempFailures = {
            chillerOutletTempFailure: (temperatureSensor >> 3) & 1,
            chillerReturnTempFailure: (temperatureSensor >> 4) & 1,
            processInletTempFailure: (temperatureSensor >> 6) & 1,
            processOutletTempFailure: (temperatureSensor >> 5) & 1,
          };
          
          temp = {
            temperature,
            pump1,
            pump2,
            voltageInputStatus,
            probeSensorStatus,
            chillerOutletTemp,
            chillerReturnTemp,
            processInletTemp,
            processOutletTemp,
            chillerFlow,
            processFlow,
          };
        }
        if (deviceType === "HPF" && data?.HPF) {
          const temperature =
            data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP1;
          const volume =
            data.HPF[Object.keys(data.HPF)[0]]
              .HPF_INPUT_REG_VOLUME_4TO20MA_READING ||
              Number(
                data.HPF[Object.keys(data.HPF)[0]]
                  .HPF_INPUT_REG_VOLUME_4TO20MA_READING
              ) === 0
              ? data.HPF[Object.keys(data.HPF)[0]]
                .HPF_INPUT_REG_VOLUME_4TO20MA_READING
              : "--";
          const pipelineTemperature =
            data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP2;
          temp = {
            temperature,
            volume,
            pipelineTemperature,
          };
        }
        if (deviceType === "EVC" && data?.EVC) {
          if(deviceTypeGlobal === "TURCK_TANKS") {
            statusNumber.push(data?.TURCK_TANKS[tankID]?.TANK_STATE)
          }
          const hspValue =
            data?.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_HIGH_SIDE_PRESSURE;

          const lspValue =
            data?.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_LOW_SIDE_PRESSURE;

          const valvePos =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_VALVE_POS;

          const subCoolTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_SUBCOOL_TEMP;

          const saturatedSuctionTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_LO_SAT_TEMP;

          const condensingTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_HI_SAT_TEMP;

          const fanSpeed =
            Math.floor((data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_FAN_OUTPUT / 4096) * 100);

          const accumulatorTemperatureDifference =
            Math.abs(data.EVC[deviceId || Object.keys(data.EVC)[0]]?.EVC_INPUT_REG_TEMP_1 -
              data.EVC[deviceId || Object.keys(data.EVC)[0]]?.EVC_INPUT_REG_TEMP_2);

          temp = {
            subCoolTemp,
            accumulatorTemperatureDifference,
            hspValue,
            condensingTemp,
            lspValue,
            saturatedSuctionTemp,
            valvePos,
            fanSpeed
          };
        }
        if (deviceType === "TURCK_TANKS" && data?.TURCK_TANKS) {
          let pheSensor1;
          let pheSensor2;
          let loadOutLoop;
          let temperatureTurck;
          let tankLevel;
          turckTotalTanks?.length > 0 && (
            turckTotalTanks.map((turckTank,index) => {
              if (turckTank.id === tankID) {
                statusNumber.push(data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_STATE);

                pheSensor1 = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_PHE_TEMPERATURE_SENSOR_1;
                pheSensor2 = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_PHE_TEMPERATURE_SENSOR_2;
                loadOutLoop = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_LOAD_OUT_LOOP_TEMPERATURE;
                temperatureTurck = data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_TEMPERATURE;
                tankLevel = data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_LEVEL;
                setTankType(data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]?.['SILO/MILK_COOLER_SELECTION'])
              }
            })
          )
            
          temp = {
            pheSensor1,
            pheSensor2,
            loadOutLoop,
            tankLevel,
            temperatureTurck,
          };
        }  
        setCurrentTankData(data);
        deviceTypeGlobal === "HPF" ? setTankStatusCode(statusNumber) : deviceTypeGlobal === "TURCK_TANKS"? setTurckTankStatusCode(statusNumber[0]): setChillerStatusCode(statusNumber);
        setLiveData({ ...liveData, ...temp });
        if(deviceType !== "TURCK_TANKS" && deviceType !== "EVC") {
          setTankType(data.tankType);
        }
        if(JSON.stringify(chillerTempFailures) !== JSON.stringify(chillerTemperatureFailure)){
          setChillerTemperatureFailures(chillerTempFailures)
        }
      });
      io.socket.on();
      if(deviceTypeGlobal === "HPF") {
        io.socket.on(`alerthistory:manual:${dairyID}`, (data) => {
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceID];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.tank === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.tank === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      } else if(deviceTypeGlobal === "TURCK_TANKS") {
        io.socket.on(`turck-alerthistory:manual:${dairyID}`, (data) => {
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceID];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.tank === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.tank === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      } else if(deviceTypeGlobal === "CHILLER") {
        io.socket.on(`chiller-alerthistory:manual:${dairyID}`, (data) => {
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceSerialNumber];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.device === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.data.device === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      }
      return () => {
        io.socket.removeAllListeners();
      };
    }
  }, [deviceTypeGlobal, deviceType, deviceId, turckControlObjectId]);

  useEffect(() => {
    if(
      (deviceTypeGlobal === "HPF" && currentTankData && currentTankData.HPF) || 
      (deviceTypeGlobal === "CHILLER" && currentTankData && currentTankData.CHILLER) || 
      (deviceTypeGlobal === "TURCK_TANKS" && currentTankData && currentTankData.TURCK && currentTankData.TURCK_TANKS)
      ) {
      setStatusTank();
    }
  }, [deviceTypeGlobal, tankStatusCode, chillerStatusCode, currentTankData]);

  useEffect(() => {
    setTanksLoader(true);
    getDevices(dairyID).then((data) => {
      let tempStaticItems = sortItems(data.data);
      setTanks(tempStaticItems);
      setTanksLoader(false);
    })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    fetchDairy(dairyID)
      .then((data) => {
        setDairyName(data.data.name);
        setDealerID(data.data.createdBy?.id)
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    if(deviceTypeGlobal && deviceType){
      getGraphData();
      setTankStatusObj(null);
    }
  }, [deviceTypeGlobal, deviceType, deviceId]);

  useEffect(() => {
    if (reloadCount !== 0) {
      getGraphData();
      getAlerts();
    }
  }, [reloadCount]);

  useEffect(() => {
    if (autoReloadInterval) {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
      setRelIntVar(
        setInterval(() => {
          tick();
          getGraphData();
          getAlerts();
        }, autoReloadInterval * 1000)
      );
    } else if (relIntVar) {
      clearInterval(relIntVar);
    }
    return () => {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
    };
  }, [autoReloadInterval]);

  useEffect(() => {
    setDateTimeIntVar(setInterval(tick, 10000));
    return () => {
      clearInterval(dateTimeIntVar);
    };
  }, []);

  if(!isEmpty(currentTankData?.CHILLER) && !isEmpty(deviceIdObj?.CHILLER)){
    tempSensorStatusRegisterBits = getBinaryStringFromDecimal(currentTankData?.CHILLER[deviceIdObj?.CHILLER[0]]?.CHILLER_CONTROLLER_INPUT_REG_TEMP_SENSOR_STATUS,4);
  }
  const liveDataHelper = {
    CHILLER: [
      {
        icon: <ThermometerIcon className="fill-blue" />,
        name: t("chiller_outlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.chillerOutletTemp),undefined,chillerTemperatureFailure.chillerOutletTempFailure, tempSensorStatusRegisterBits[0])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.chillerOutletTemp),undefined,chillerTemperatureFailure.chillerOutletTempFailure, tempSensorStatusRegisterBits[0])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-green" />,
        name: t("chiller_return_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.chillerReturnTemp),undefined,chillerTemperatureFailure.chillerReturnTempFailure, tempSensorStatusRegisterBits[1])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.chillerReturnTemp),undefined,chillerTemperatureFailure.chillerReturnTempFailure, tempSensorStatusRegisterBits[1])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-purple" />,
        name: t("process_outlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.processOutletTemp),undefined,chillerTemperatureFailure.processOutletTempFailure, tempSensorStatusRegisterBits[2])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.processOutletTemp),undefined,chillerTemperatureFailure.processOutletTempFailure, tempSensorStatusRegisterBits[2])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-yellow" />,
        name: t("process_inlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.processInletTemp),undefined,chillerTemperatureFailure.processInletTempFailure, tempSensorStatusRegisterBits[3])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.processInletTemp),undefined,chillerTemperatureFailure.processInletTempFailure, tempSensorStatusRegisterBits[3])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <SaturatedSuctionIcon className="fill-yellow" />,
        name: t("process_flow"),
        value: liveData.processFlow,
        unit: liveData.processFlow !== "--" && "uA",
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <SaturatedSuctionIcon className="fill-blue" />,
        name: t("chiller_flow"),
        value: liveData.chillerFlow,
        unit: liveData.processFlow !== "--" && "uA",
        isComponent: true,
        isSkip: false,
      },
    ],
    HPF: [
      {
        icon: volumeIcon,
        name: t("tank_volume"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
          .volumeUnit,
        isSkip: false,
      },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
        isSkip: false,
      },
      {
        icon: pipelineTempIcon,
        name: t("pipeline_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).tempUnit,
        isSkip: false,
      },
    ],
    EVC: [
      {
        icon: subCoolTempIcon,
        name: t("subcool_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.subCoolTemp),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.subCoolTemp), true)
          .tempUnit,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-blue" />,
        name: t("accumulator_temperature_deference"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.accumulatorTemperatureDifference),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.accumulatorTemperatureDifference), true)
          .tempUnit,
        isComponent: true,
        isSkip: deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS" ? true : false
      },
      {
        icon: hspIcon,
        name: t("HSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressureUnit,
        isSkip: false,
      },
      {
        icon: CondensingIcon,
        name: t("sct"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .tempUnit,
        isSkip: deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS" ? true : false,
      },
      {
        icon: lspIcon,
        name: t("LSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressureUnit,
        isSkip: false,
      },
      {
        icon: <SaturatedSuctionIcon />,
        name: t("sst"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .tempUnit,
        isSkip: deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS" ? true : false,
        isComponent: true,
      },
      {
        icon: valveIcon,
        name: t("valve_position"),
        value: liveData.valvePos,
        unit: "",
        isSkip: false,
      },
      {
        icon: fanSpeedIcon,
        name: t("fan_speed"),
        value: liveData.fanSpeed,
        unit: liveData.fanSpeed !== "--" && "%",
        isSkip: deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS" ? true : false,
      },
    ],
    TURCK: [
      {
        icon: PHE_Temperature_Sensor_1,
        name: t("TURCK_PHE_TEMPERATURE_SENSOR_1"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor1)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor1)).tempUnit,
      },
      {
        icon: PHE_Temperature_Sensor_2,
        name: t("TURCK_PHE_TEMPERATURE_SENSOR_2"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor2)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor2)).tempUnit,
      },
      {
        icon: Load_Out_Loop_Temperature,
        name: t("TURCK_LOAD_OUT_LOOP_TEMPERATURE"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.loadOutLoop)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.loadOutLoop)).tempUnit,
      },
    ],
    TURCK_TANKS: [
      {
        icon: Tank_Level,
        name: t("TANK_LEVEL"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.tankLevel)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.tankLevel))
          .volumeUnit,
      },
      {
        icon: Tank_Temperature,
        name: t("TANK_TEMPERATURE"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperatureTurck)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperatureTurck)).tempUnit,
      }
    ],
  };

  const toggleRegistrationCode = async (id, data) => {
    let type = "";
    let message = "";
    let currentDeviceType = "";
    let temp = {};
    // checking whether selected device is HPF or CHILLER\
    if (data.HPFSerialNumber) {
      if (!data.registrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "HPF"
      data.calibrationChart = data.calibrationChart ? data.calibrationChart.id : null
      data.dairy = data?.dairy?.id
      temp = {
        ...data,
        updateRegistrationCode: type,
      } 
    } else if (data.deviceSerialNumber) {
      if (!data.deviceRegistrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "CHILLER"
      data.locationName = data?.locationName?.id
      temp = {
        ...data,
        updateRegistrationCode: type,
      };
    } else if (data.controlSerialNumber) {
      if (!data.registrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "TURCK"
      data.dairy = data?.dairy?.id;
      data.tanks = [...data.tanks.map((tank) => tank.id)];
      temp = {
        ...data,
        updateRegistrationCode: type
      };
    } 

    if(currentDeviceType === "HPF" || currentDeviceType === "CHILLER" || currentDeviceType === "TURCK") { 
      try {
        const res = currentDeviceType === "HPF" ? await updateTank(id, temp) : currentDeviceType === "TURCK" ? await updateTurckControl(id, temp) : await updateChiller(id, temp);
        if ((currentDeviceType === "HPF" && dairyID === res.data.dairy.id) || (currentDeviceType === "CHILLER" && dairyID === res.data.locationName.id) || (currentDeviceType === "TURCK" && dairyID === res.data.dairy.id)) {
          const resObj = await getDevices(dairyID);
          setTanks(resObj.data);
          toast.dark(<Toaster icon="notify" message={message} />, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    }
  };

  /** Show hide section in mobile */
  useEffect(() => {
    if (!deviceTypeGlobal) {
      setToggleShow(true);
    } else {
      setToggleShow(false);
    }
  }, [deviceTypeGlobal]);

  /** Show red indicator in device icon */
  useEffect(() => {
    if(deviceTypeGlobal === "HPF") {
      rootApi
      .get(
        `/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"tank":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        let devices = [];
        response.data.map((item) => {
          if (item.tank && item.deviceID !== "") {
            if (!devices.includes(item.deviceID)) {
              devices.push(item.deviceID);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    } else if(deviceTypeGlobal === "CHILLER") {
      rootApi
      .get(
        `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"device":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          const chillerAlertsList = response.data
          const alertsByEvcDeviceID = [];
          let devices = [];
        for (let compressorIndex = 0; compressorIndex < 6; compressorIndex++) {
          if (!chillerAlertsList.length) {
            setShowAlertIcon(
              Object.assign(showAlertIcon, { [compressorIndex]: false })
            );
            break;
          }
          for (let chillerAlert of chillerAlertsList) {
            if (
              chillerAlert?.alertType?.includes("INDIVIDUAL") &&
              (chillerAlert?.configKey ===
                `CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_1_BIT_${
                  compressorIndex + 10
                }` ||
                chillerAlert?.configKey ===
                  `CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_2_BIT_${compressorIndex}`)
            ) {
              setShowAlertIcon(
                Object.assign(showAlertIcon, { [compressorIndex]: true })
              );
              break;
            }

            setShowAlertIcon(
              Object.assign(showAlertIcon, { [compressorIndex]: false })
            );
          }
        }

        chillerAlertsList.map((item) => {
          if (item.tank && item.deviceSerialNumber !== "") {
            if (!devices.includes(item.deviceSerialNumber)) {
              devices.push(item.deviceSerialNumber);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    }

  }, [deviceTypeGlobal]);

  /** Display live alert in tank card and alert icon */
  const updateTankAlert = useCallback(() => {
    if(deviceTypeGlobal === "HPF") {
      io.socket.get(`/api/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.tank?.id) : [];
          setTankAlert(tankIds);
        }
      );
    } else if(deviceTypeGlobal === "TURCK_TANKS") {
      io.socket.get(`/api/turckalerthistories?where={"and":[{"turckAlert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS_TURCK"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.tank?.id || i?.control?.id) : [];
          setTankAlert(tankIds);
        }
      );
    } else if(deviceTypeGlobal === "CHILLER"){
      io.socket.get(`/api/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.device?.id) : [];
          setTankAlert(tankIds);
        }
      );
    }
  });
  useEffect(updateTankAlert, [deviceTypeGlobal]);

  useEffect(() => {
    // filtering only enable devices whenever live data emits
    if(currentTankData?.CHILLER && deviceIdObj?.CHILLER?.length && deviceIdObj?.EVC?.length && Object.keys(currentTankData.CHILLER).length){
      const EVCDeviceIdsList = [];
      // on respective modus and on present influx data for the evc -> adding those evc deviceIDs, rest ignoring.
        for (let i = 0; i < 6; i++) {
          const isCompressorEnable = Number(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${i + 1}_ENABLED_PARAM`])
          
          if(isCompressorEnable) {
            EVCDeviceIdsList.push(deviceIdObj.EVC[i]);
          }
        }
        const relayStatusCompressorBits = getStringFromDecimalToBinary(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS`]).slice(4, 10);
        setIsFanRotating(relayStatusCompressorBits)
        setCurrentEnableDeviceIDs({ ...deviceIdObj, "EVC": EVCDeviceIdsList });
    } else if(currentTankData?.HPF && deviceIdObj?.HPF?.length && deviceIdObj?.EVC?.length && Object.keys(currentTankData.HPF).length) {
      setCurrentEnableDeviceIDs({ ...deviceIdObj });
    } else if (currentTankData?.TURCK_TANKS && deviceIdObj?.TURCK_TANKS?.length  && Object.keys(currentTankData.TURCK_TANKS).length) {
      setCurrentEnableDeviceIDs({ ...deviceIdObj });
    }
  }, [currentTankData])

  return (
    <>
      <Row className="row-full-width">
        {!toggleShow ? (
          <Col xl={8} lg={12} md={12} className="second-section">
            {tankID ? (
              isTankRegistered && dairyAdmin ? (
                <>
                  <div className="d-flex align-items-center graph-mobile-device">
                    <div
                      className={
                        deviceIdObj === null
                          ? "graph-device pl-0 pr-2"
                          : "graph-device px-2"
                      }
                    >
                      {currentEnableDeviceIDs && 
                      (
                        (deviceTypeGlobal === "HPF" && true) || (deviceTypeGlobal === "TURCK_TANKS" && true)|| 
                        (deviceTypeGlobal === "CHILLER" && currentActiveView !== LIVE_ALERT_VIEW)
                      ) &&
                        Object.keys(currentEnableDeviceIDs).map((key) => {
                          return currentEnableDeviceIDs[key].map((id, index) => {
                            let evcIcon;
                            const evcRotateSignal = Number.parseInt(currentTankData?.EVC?.[id]?.RUN_SIGNAL) !== 0;
                            const evcDisabledSignal = Number.parseInt(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${index + 1}_EVC_ENABLED_PARAM`]) === 0;
                            if(key === "EVC") {
                              const evcRotateSignal = Number(isFanRotating[index]);
                              evcIcon = evcRotateSignal ? EVC_GIF_ICON : EVC_ICON;
                            }

                            let isAlert = unResolvedDevices.includes(id) || (key === "EVC" && showAlertIcon[index]);
                            return (
                              <div
                                className={
                                  deviceId === id
                                    ? "mobile-device-image-active"
                                    : "mobile-device-image"
                                }
                                key={id}
                              >
                                {tankType === null ? (
                                  <div
                                    className="active offline offlineTank pt-1 pb-1"
                                    onClick={() => {
                                      if ((deviceType === key && deviceId === id) || (key === "EVC" && evcDisabledSignal)) {
                                        return;
                                      }
                                      setDeviceType(`${key}`);
                                      setDeviceId(`${id}`);
                                      setCurrentActiveView(GRAPH_VIEW)
                                    }}
                                  >
                                    <span
                                      className={
                                        key === "HPF" || key === "CHILLER" || key === "TURCK_TANKS" 
                                          ? "single-tank-page"
                                          : "evc-img"
                                      }
                                    >
                                      --
                                    </span>
                                  </div>
                                ) : (
                                  <span
                                    className={
                                      isAlert
                                        ? "tank-red-indicator position-relative"
                                        : ""
                                    }
                                  >
                                    {key === 'CHILLER' ? (<TankChillerIcon onClick={() => handleDeviceClick(key, id, evcDisabledSignal)} />):
                                      <Image
                                        className={
                                          key === "HPF" || key === "TURCK_TANKS"
                                            ? tankType == "1"
                                              ? "vertical-image"
                                              : "hpf-image"
                                            : "evc-image"
                                        }
                                        src={
                                          key === "HPF" ||  key === "TURCK_TANKS"
                                            ? tankType == "1"
                                              ? tankVerticalIcon
                                              : TankHPF
                                            : evcIcon
                                        }
                                          onClick={() => handleDeviceClick(key, id, evcDisabledSignal)}
                                      />}
                                  </span>
                                )}
                              </div>
                            );
                          });
                        })}
                      <div>
                        {deviceIdObj &&
                          (deviceType === "HPF" ? deviceIdObj?.HPF : deviceIdObj?.CHILLER) !== null &&
                          deviceIdObj?.EVC.length !== null && (
                            <div class="vertical-line mx-2" />
                          )}

                        <Button
                          className={`${currentActiveView === LIVE_ALERT_VIEW
                            ? "mr-auto mt-1 px-2 alert-btn-active"
                            : "mr-auto mt-1 px-2 alert-btn"
                            }`}
                          variant="none"
                          onClick={() => {
                            setCurrentActiveView(currentActiveView === LIVE_ALERT_VIEW ? GRAPH_VIEW : LIVE_ALERT_VIEW)
                            setDeviceId(false);
                          }}
                        >
                          <span
                            className={`${tankAlert.includes(tankID)
                              ? "alert-red-indicator position-relative"
                              : "text-primary"
                              }`}
                          >
                            <Image
                              src={AlertIcon}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Row>
                    <Col
                      md={12}
                      sm={12}
                      className="d-flex justify-content-between pt-3 "
                    >
                      <div>
                        <Button
                          className="border-primary text-primary"
                          variant="outline-none"
                          type="button"
                          onClick={() => setVisible(true)}
                        >
                          {t("live_data")}
                        </Button>{" "}
                      </div>
                      <div className="position-relative pr-3">
                        <Image
                          src={threeLineIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowGraphButtons(!showGraphButtons);
                          }}
                        />
                        <GraphButtonsDropdown show={showGraphButtons}>
                          <span
                            className="text-capitalize"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCustomDates([
                                new Date(ranges.Yesterday[0]).toISOString(),
                                new Date().toISOString(),
                              ]);
                              setReloadCount(reloadCount + 1);
                            }}
                          >
                            {t("reload")}
                          </span>
                          <span className="d-lg-block d-md-block d-none">
                            <div
                              className="white"
                              show={showAnnotation}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (showAnnotation) {
                                  setShowAnnotation(false);
                                } else {
                                  setShowAnnotation(true);
                                }
                              }}
                            >
                              {showAnnotation
                                ? t("hide_annotation")
                                : t("show_annotation")}
                            </div>
                          </span>
                          <span
                            onClick={() => {
                              setShow(true);
                              setCurrentEditTank(() =>
                                tanks.find((i) => i.id === tankID)
                              );
                            }}
                          >
                            {t("export_csv")}
                          </span>
                          <span className="date-range-span">
                            <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                timePicker: true,
                                startDate: new Date(),
                                endDate: new Date(),
                                ranges,
                                opens: "left",
                              }}
                              onApply={() => {
                                getGraphData();
                                getAlerts();
                              }}
                              onCallback={handleDateCallback}
                            >
                              <span>
                                {`${moment(customDates[0]).format(
                                  "DD MMM"
                                )} - ${moment(customDates[1]).format(
                                  "DD MMM"
                                )}`}
                              </span>
                            </DateRangePicker>
                            <Image src={downCaret} alt="" />
                          </span>
                        </GraphButtonsDropdown>
                      </div>
                    </Col>
                  </Row>
                  {currentActiveView === SETTINGS_VIEW  && (
                    <Row>
                      <Col lg={12}>
                        <Settings />
                      </Col>
                    </Row>
                  )}

                  {currentActiveView === LIVE_ALERT_VIEW  && (
                    <Row>
                      <Col lg={12}>
                        <LiveAlertsNotifications
                          currentTankData={currentTankData}
                          dropdownTime={props.dropdownTime}
                          turckControlObjectId={turckControlObjectId}
                          tankType={tankType}
                        />
                      </Col>
                    </Row>
                  )}

                  <Chiller
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    currentActiveView={currentActiveView}
                    chillerGraphData={chillerGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    getAlerts={getAlerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                  />

                  <Hpf
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    currentActiveView={currentActiveView}
                    hpfGraphData={hpfGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                    getAlerts={getAlerts}
                    isResponsiveMode={isResponsiveMode}
                  />
                  
                   <Turck
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      turckGraphData={turckGraphData}
                      currentActiveView={currentActiveView}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />

                  <Evc
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    currentActiveView={currentActiveView}
                    evcGraphData={evcGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                    getAlerts={getAlerts}
                    isResponsiveMode={isResponsiveMode}
                  />
                </>
              ) : (
                  <center>
                    {dairyAdmin !== undefined && (!dairyAdmin ? <NoDataAvailable title={t("the_site_owner_has_not_registered")} />
                  : <NoDataAvailable title={t("device_not_registered")} /> )}
                  </center>
                )
            ) : (
                <center>
                  <NoDataAvailable title={t("no_data")} />
                </center>
              )}
          </Col>
        ) : (
            <Col xl={2} lg={12} md={12} className="first-section">
              {tanksLoader ? (
                <TanksLoader />
              ) : (
                  <>
                    <Row className="mb-3">
                      {tanks.length === 0 && (
                        <Col lg={12} className="d-lg-block d-md-block text-center">
                          {user.role === "MilkHauler" ||
                            user.role === "Sanitarian" ? (
                              ""
                            ) : (
                              <NoTankDataAvailable
                                setIsTankEdit={setIsTankEdit}
                                setShowAddTank={setShowAddTank}
                              />
                            )}
                        </Col>
                      )}
                      <Col lg={9} sm={12} md={8} />
                      <Col
                        lg={3}
                        sm={12}
                        md={4}
                        className="d-lg-block d-md-block d-none"
                      >
                        {user.role === "MilkHauler" || user.role === "Sanitarian"
                          ? ""
                          : tanks.length > 0 && (
                            <Button
                              variant="primary"
                              size="lg"
                              type="button"
                              block
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsTankEdit(false);
                                setShowAddTank(true);
                              }}
                            >
                              <Image
                                src={addTankIcon}
                                className="mr-2 pb-1"
                                fluid
                              />
                              {t("add_device")}
                            </Button>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      {tanks.map((tank, index) => {
                        const { location, history } = props;
                        const isAlert = tankAlert.includes(tank.id);
                        if(tank.controlName !== undefined  && tank.tanks) {
                          return (
                            <Col lg={3} md={4} key={tank.id}>
                                <TurckDropdown 
                                    tanks = {tanks}
                                    props = {props}
                                    setDeviceType={setDeviceType}
                                    tankAlert={tankAlert}
                                    deviceIdObj={deviceIdObj}
                                    deviceId={deviceId}
                                    setDeviceId={setDeviceId}
                                    setTankDeleteAlert={setTankDeleteAlert}
                                    setTankDeleteIndex={setTankDeleteIndex}
                                    setIsTankEdit={setIsTankEdit}
                                    setCurrentEditTank={setCurrentEditTank}
                                    setShowAddTank={setShowAddTank}
                                    tankID={tankID}
                                    toggleRegistrationCode={toggleRegistrationCode}
                                    control = {tank}
                                    controlIndex={index}
                                    turckTotalTanks = {tank.tanks}
                                    setCurrentActiveView= {setCurrentActiveView}
                                    setDeviceTypeGlobal={setDeviceTypeGlobal}
                                    getDevice={getDevice}
                                    setDeviceIdObj={setDeviceIdObj}
                                  />
                            </Col>
                          );
                        }
                       else {
                        if(tank?.type === undefined || tank?.type === null || tank.deviceType) {
                          return (
                            <Col lg={3} md={4} key={tank.id}>
                              <TankCard
                                click={() => {
                                  let url = location.pathname.split("/");
                                  url.splice(8, 1);
                                  if (index === tanks.length - 1) {
                                    url.push(tank.id);
                                  } else {
                                    url.push(tank.id);
                                  }
                                  url = url.join("/");
                                  history.replace(url);
                                  setCurrentActiveView(GRAPH_VIEW)
                                  setDeviceIdObj(null);
                                  setDeviceId("");
                                  setDeviceTypeGlobal("");
                                  setDeviceType("");
                                  getDevice(tank.id);
                                  setToggleShow((prevToggle) => !prevToggle);
                                }}
                                remove={() => {
                                  setTankDeleteAlert(true);
                                  setTankDeleteIndex(index);
                                }}
                                editTank={() => {
                                  setIsTankEdit(true);
                                  setCurrentEditTank({ ...tank });
                                  setShowAddTank(true);
                                }}
                                tank={tank}
                                deviceType={tank && tank.deviceType ? "CHILLER" : "HPF"}
                                isactive={tankID === tank.id}
                                toggleRegistrationCode={() =>
                                  toggleRegistrationCode(tank.id, tank)
                                }
                                isAlert={isAlert}
                                setCurrentActiveView={setCurrentActiveView}
                              />
                            </Col>
                          );
                        }
                       }
                      })}
                    </Row>
                    <Row
                      className="mx-md-auto text-center position-sticky d-md-none d-lg-none"
                      style={{ bottom: "0" }}
                    >
                      <Col lg={6} sm={12} md={6}>
                        {user.role === "MilkHauler" ||
                          user.role === "Sanitarian" ? (
                            ""
                          ) : (
                            <>
                              {tanks.length === 0 ? (
                                ""
                              ) : (
                                  <Button
                                    className="mt-4 mb-2"
                                    variant="primary"
                                    size="lg"
                                    type="button"
                                    block
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsTankEdit(false);
                                      setShowAddTank(true);
                                    }}
                                  >
                                    <Image
                                      src={addTankIcon}
                                      className="mr-2 pb-1"
                                      fluid
                                    />
                                    {t("add_device")}
                                  </Button>
                                )}
                            </>
                          )}
                      </Col>
                    </Row>
                  </>
                )}
            </Col>
          )}

        <Drawer
          title={t("live_data")}
          placement="bottom"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
          className="live-data"
        >
          <LiveDataSideBar
            tankStatusObj={tankStatusObj}
            agitatedTime={agitatedTime}
            liveDataHelper={liveDataHelper}
            deviceType={deviceType}
            secondsToHourMin={secondsToHourMin}
            currentTankData={currentTankData}
            dropdownTime={props.dropdownTime}
          />
        </Drawer>
      </Row>
      { deviceType === "HPF" ? 
          <AddTankForm
            dealerID={dealerID}
            show={showAddTank}
            close={() => setShowAddTank(false)}
            dairy={dairyName}
            addNewTank={addNewTank}
            edit={isTankEdit}
            tank={currentEditTank}
            editTank={editTank}
          /> : <AddDeviceControlForm
              dealerID={dealerID}
              show={showAddTank}
              close={() => setShowAddTank(false)}
              dairy={dairyName}
              addNewTank={addNewTank}
              edit={isTankEdit}
              tank={currentEditTank}
              editTank={editTank}
            /> 
        }
      <PromptModel
        open={tankDeleteAlert}
        closeAlert={() => {
          setTankDeleteAlert(false);
        }}
        modelContent={t("are_you_sure")}
        callback={() => {
          if (tankDeleteIndex !== null) {
            removeTank(tankDeleteIndex);
          }
        }}
      />
      {show && (
        <ExportCsvForm
          showModel={show}
          closeModel={() => setShow(false)}
          tank={currentEditTank}
          exportDeviceIds={exportDeviceIds}
          dropdownTime={props.dropdownTime}
        />
      )}
    </>
  );
});
TankListingMobile.propTypes = {
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};
export default withRouter(TankListingMobile);
