/* eslint-disable*/
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import TurckTankCard from "components/TurckTankCard";
import { GRAPH_VIEW } from "actions/passwordRegex";

const DropdownWrapper = styled.div`
  width: 100%;
  padding: 0;
  border-radius: 4px;
  display: inline-block;
  align-self: center;
  position: relative;
  margin-right: ${({ pause }) => (pause ? "10px" : "0")};
`;

const TitleDiv = styled.div`
  text-align: center;
  padding: ${({ pause }) => (pause ? "0 10px" : "0 5px")};
  cursor: pointer;
  img.caret {
    margin-left: 10px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #2c61a5;
  }
`;

const DropContainer = styled.div`
  position: relative;
  display: ${({ show }) => (show ? "block" : "none")};
  span {
    display: block;
    font-size: 14px;
    color: #212f46;
    cursor: pointer;
    padding: 5px 15px;

    &:hover {
      background: #f0f6ff;
    }
  }
  z-index: 1;
  background: white;
  height: 100%;
  border-radius: 4px;
  box-shadow: 4px 3px 12px 0 rgba(0, 0, 0, 0.08);
  padding: 2px;
  padding-top: 3px;
  border: 2px dashed #EBEBEB;
  border-radius: 0px 0px 3px 3px; 
  border-top-color: white;
`;

const TurckDropdown = ({ 
  tanks ,
  props ,
  setDeviceType,
  tankAlert,
  deviceIdObj,
  deviceId,
  setDeviceId,
  setTankDeleteAlert,
  setTankDeleteIndex,
  setIsTankEdit,
  setCurrentEditTank,
  setShowAddTank,
  tankID,
  toggleRegistrationCode,
  control,
  controlIndex,
  turckTotalTanks,
  setCurrentActiveView,
  setDeviceTypeGlobal,
  getDevice,
  setDeviceIdObj,
  turckControlObjectId
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [intervalText, setIntervalText] = useState("None");
  const node = useRef();


  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowDropdown(false);
  };

  const { location, history } = props;
  const isAlert = tankAlert.includes(control.id);
  const controlCard = true;
  return (
		<DropdownWrapper ref={node}>
			<TurckTankCard
				key={'tank' + controlIndex}
				click={() => {
					if (control?.tanks?.[0]?.id === tankID) {
						setShowDropdown(!showDropdown);
						return;
					}
					let url = location.pathname.split('/');
					if(url[url.length -1] !== 'tank') {
						url.pop();
					}
					url.push(control?.tanks[0] && control.tanks[0].id);
					url = url.join('/');
					history.replace(url);
					setShowDropdown(!showDropdown);
					setDeviceIdObj(null);
					setDeviceTypeGlobal('');
					setDeviceType('');
					setCurrentActiveView(GRAPH_VIEW);
					// TODO: Uncomment and use the below code once the tankNumber feature is implemented on the backend.
					// setDeviceId('');
					// getDevice(tankID)
				}}
				remove={() => {
					setTankDeleteAlert(true);
					setTankDeleteIndex(controlIndex);
				}}
				editTank={() => {
					setIsTankEdit(true);
					setCurrentEditTank({ ...control });
					setShowAddTank(true);
				}}
				tank={control}
				isactive={turckControlObjectId === control?.id}
				toggleRegistrationCode={() => toggleRegistrationCode(control.id, control)}
				isAlert={isAlert}
				setShowDropdown={setShowDropdown}
				showDropdown={showDropdown}
				controlCard={controlCard}
				setCurrentActiveView={setCurrentActiveView}
			/>
			{ control?.registrationId === '' && <DropContainer show={showDropdown}>
				{turckTotalTanks.map((tank, index) => {
					const { location, history } = props;
					const isAlert = tankAlert.includes(tank.id);
					const turckTankCard = true;
					return (
						<TurckTankCard
							key={'tank' + index}
							click={() => {
								if (tank.id === tankID) return;
								let url = location.pathname.split('/');
								if(url[url.length -1] !== 'tank') {
									url.pop();
								}
								url.push(tank.id);
								url = url.join('/');
								history.replace(url);
								setCurrentActiveView(GRAPH_VIEW);
								setDeviceIdObj(null);
								setDeviceTypeGlobal('');
								setDeviceType('');
								// TODO: Uncomment and use the below code once the tankNumber feature is implemented on the backend.
								// setDeviceId('');
								// getDevice(tank.id)
							}}
							remove={() => {
								setTankDeleteAlert(true);
								setTankDeleteIndex(index);
							}}
							editTank={() => {
								setIsTankEdit(true);
								setCurrentEditTank({ ...tank });
								setShowAddTank(true);
							}}
							tank={tank}
							turckTotalTanks={turckTotalTanks}
							isactive={tankID === tank.id}
							toggleRegistrationCode={() => toggleRegistrationCode(tank.id, tank)}
							isAlert={isAlert}
							turckTankCard={turckTankCard}
							setCurrentActiveView={setCurrentActiveView}
						/>
					);
				})}
			 </DropContainer>
			}
		</DropdownWrapper>
	);
};

TurckDropdown.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

TurckDropdown.defaultProps = {
};

export default TurckDropdown;
