import React, { memo, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Badge,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CountryPhoneInput from "../components/CountryPhoneInput";
import { updateUser, getUser, fetchVersion } from "../actions/actions.js";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Toaster from "../components/Toaster";
import BackArrow from "../assets/images/back-arrow.svg";
import { appVersion } from "../axiosConfig";
import { useAuthContext } from "../context/auth";

const ProfilePage = memo(() => {
  const [spinner, setSpinner] = useState(false);
  const { t, i18n } = useTranslation();
  // const [phone, setPhone] = useState();
  const { setAuthUser, authUser } = useAuthContext();

  const [updateflag, setFlag] = useState(true);
  const [backEndVersion, setBackEndVersion] = useState(null);
  const schema = yup.object().shape({
    name: yup.string().required(t("name_error")),
    email: yup.string().required(t("email_error")),
    contact: yup.lazy((value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return yup.string().min(10, t("contact_digit"));
      }
      return yup.mixed().notRequired();
    }),
    languagePreference: yup.string().required(),
    temperatureUnit: yup.string().required(),
    volumeUnit: yup.string().required(),
    pressureUnit: yup.string().required(),
  });
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
  });

  const {
    name,
    email,
    id,
    contact,
    languagePreference,
    temperatureUnit,
    volumeUnit,
    pressureUnit,
    sendEmail,
    sendSMS,
  } = getUser();

  const [phone, setPhone] = useState(contact);
  const [selectedContact, setSelectedContact] = useState(contact);

  const history = useHistory();
  const enableFlag = () => {
    setFlag(false);
  };
  const updateDetails = async (data) => {
    setSpinner(true);
    let changedContact = selectedContact.trim();
    data.contact = changedContact === "" ? changedContact : phone;
    try {
      const result = await updateUser(id, data);
      setSpinner(false);
      if (result.status === 200) {
        const {
          name,
          email,
          contact,
          languagePreference,
          temperatureUnit,
          volumeUnit,
          pressureUnit,
          sendEmail,
          sendSMS,
        } = result.data;
        i18n.changeLanguage(languagePreference.replace(" ", ""));
        localStorage.setItem("i18nextLng", languagePreference.replace(" ", ""));
        let userData = JSON.parse(localStorage.getItem("user"));
        userData = {
          ...userData,
          name,
          email,
          contact,
          languagePreference,
          temperatureUnit,
          volumeUnit,
          pressureUnit,
          sendEmail,
          sendSMS,
        };
        setAuthUser(userData, localStorage.getItem("token"));
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={t("details_updated_successfully")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
        setFlag(true);
      }
    } catch (err) {
      if (
        err.response &&
					((err.response.status === 400 && err.response.data && err.response.data.code === 'E_UNIQUE') ||
						(err.response.status === 500 && err.response.data && err.response.data.includes('E_UNIQUE')))
      ) {
        toast.dark(
          <Toaster icon="error" message={t("email_already_exists")} />
        );
        setSpinner(false);
      } else {
        toast.dark(
          <Toaster icon="error" message={t("profile_error_message")} />
        );
      }
    }
  };

  /** Display version summary */
  useEffect(() => {
    fetchVersion()
      .then((response) => {
        setBackEndVersion(response.data.version);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Container>
        <Form className="mb-5" onSubmit={handleSubmit((d) => updateDetails(d))}>
          <Row className="mt-4 mb-3">
            <Col className="pl-0" lg="auto" md={4} sm={4} xs={12}>
              <Badge
                className="px-3 py-2 back-arrow-hover"
                onClick={history.goBack}
              >
                <Image src={BackArrow} />
              </Badge>
            </Col>
            <Col lg="auto" md={4} sm={4} xs={12} className="ml-auto">
              <Button
                className="mt-1 mb-2 text-capitalize"
                variant="outline-primary"
                size="lg"
                type="button"
                onClick={() => history.push("/change-password")}
                block
              >
                {t("reset_password")}
              </Button>
            </Col>
            <Col lg="auto" md={4} sm={4} xs={12}>
              <Button
                className="mt-1 text-capitalize custom-update-btn"
                variant="primary"
                size="lg"
                type="submit"
                block
                disabled={updateflag}
              >
                {spinner ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                  t("update")
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="text-primary lead font-weight-bold mt-1">
                {t("basic_details")}
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row className="mt-4 mb-3">
            <Col
              className="my-auto"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-capitalize" size="lg">
                  {t("name")}
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  placeholder="Name"
                  ref={register()}
                  defaultValue={name}
                  isInvalid={!!errors.name}
                  onChange={() => enableFlag()}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4 mb-3">
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-capitalize" size="lg">
                  {t("email")}
                </Form.Label>
                <Form.Control
                  name="email"
                  size="lg"
                  type="email"
                  placeholder="Email"
                  ref={register()}
                  defaultValue={email}
                  isInvalid={!!errors.email}
                  onChange={() => enableFlag()}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 1 }}
            >
              {/* <Form.Group controlId="formBasicPhone">
                <Form.Label className="text-capitalize" size="lg">
                  {t("phone_number")}
                </Form.Label>
                <CountryPhoneInput
                  onChangeVal={(val, country, e, formattedValue) => {
                    setPhone(formattedValue);
                    enableFlag();
                  }}
                  phoneInputValue={contact}
                  inputRef={register}
                  onChange={() => enableFlag()}
                />
              </Form.Group> */}
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="text-capitalize" size="lg">
                  {t("phone_number")}
                </Form.Label>
                <CountryPhoneInput
                  onChangeVal={(val, country, e, formattedValue) => {
                    let contactNumber =
                      formattedValue &&
                      formattedValue.slice(country.dialCode.length + 1);
                    setPhone(formattedValue);
                    setSelectedContact(contactNumber);
                    let formContactVal =
                      val &&
                      val.match(/\d/g).join("").slice(country.dialCode.length);
                    setValue("contact", formContactVal, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    enableFlag();
                  }}
                  phoneInputValue={contact}
                  onChange={() => enableFlag()}
                />

                <Form.Control
                  name="contact"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.contact}
                  className="d-none"
                />
              </Form.Group>
              {errors.contact && (
                <div className="custom-invalid-feedback text-danger">
                  {errors.contact.message}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="my-auto">
              <div className="text-primary lead font-weight-bold mt-4 mb-1">
                {t("language_preferences")}
                <hr></hr>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 mb-3">
            <Col
              className="my-auto"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{t("language_dropdown")}</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  name="languagePreference"
                  defaultValue={languagePreference}
                  ref={register()}
                  onChange={() => enableFlag()}
                  custom
                >
                  <option value="English">English</option>
                  <option value="Spanish">Español</option>
                  <option value="Dutch">Nederlands</option>
                  <option value="Canadian French">Français canadien</option>
                  <option value="Japanese">日本語</option>
                  <option value="German">Deutsche</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-primary lead font-weight-bold mt-4 mb-3">
                {t("unit_preference")}
              </div>
              <hr></hr>
            </Col>
          </Row>
          <Row className="mt-4 mb-1">
            <Col
              className="my-auto"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{t("temperature_unit_preference")}</Form.Label>
                <Form.Group as={Row}>
                  <Col lg={3} sm={3} xs={4}>
                    <Form.Check
                      custom
                      type="radio"
                      inline
                      className="unit-hover mt-2 w-100 text-center d-block"
                      label="°F"
                      value="F"
                      name="temperatureUnit"
                      id="formHorizontalRadios1"
                      defaultChecked={temperatureUnit === "F" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />{" "}
                  </Col>
                  <Col lg={3} sm={3} xs={4}>
                    <Form.Check
                      custom
                      inline
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="°C"
                      value="C"
                      name="temperatureUnit"
                      id="formHorizontalRadios2"
                      defaultChecked={temperatureUnit === "C" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                  <Col lg={3} sm={3} xs={4}>
                    <Form.Check
                      custom
                      inline
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="K"
                      value="K"
                      name="temperatureUnit"
                      id="formHorizontalRadios3"
                      defaultChecked={temperatureUnit === "K" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                </Form.Group>
              </Form.Group>
            </Col>
            <Col
              className="my-auto"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{t("pressure_unit")}</Form.Label>
                <Form.Group as={Row}>
                  <Col lg={3} sm={4} xs={4}>
                    <Form.Check
                      type="radio"
                      custom
                      className="unit-hover mt-2  w-100 text-center d-block"
                      inline
                      label="psig"
                      value="psig"
                      name="pressureUnit"
                      id="formHorizontalRadios4"
                      defaultChecked={pressureUnit === "psig" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />{" "}
                  </Col>
                  <Col lg={3} sm={4} xs={4}>
                    <Form.Check
                      inline
                      custom
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="bar"
                      value="bar"
                      name="pressureUnit"
                      id="formHorizontalRadios5"
                      defaultChecked={pressureUnit === "bar" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                  <Col lg={3} sm={4} xs={4}>
                    <Form.Check
                      inline
                      custom
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="MPa"
                      value="MPa"
                      name="pressureUnit"
                      id="formHorizontalRadios6"
                      defaultChecked={pressureUnit === "MPa" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4 mb-1">
            <Col
              className="my-auto"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
            >
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{t("volume_unit")}</Form.Label>
                <Form.Group as={Row}>
                  <Col sm={3} xs={4}>
                    <Form.Check
                      type="radio"
                      custom
                      inline
                      className="unit-hover mt-2  w-100 text-center d-block"
                      label="gal"
                      value="gal"
                      name="volumeUnit"
                      id="formHorizontalRadios7"
                      defaultChecked={volumeUnit === "gal" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />{" "}
                  </Col>
                  <Col sm={3} xs={4}>
                    <Form.Check
                      inline
                      custom
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="L"
                      value="L"
                      name="volumeUnit"
                      id="formHorizontalRadios8"
                      defaultChecked={volumeUnit === "L" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                  <Col sm={3} xs={4}>
                    {" "}
                    <Form.Check
                      inline
                      custom
                      className="unit-hover mt-2  w-100 text-center d-block"
                      type="radio"
                      label="lb"
                      value="lb"
                      name="volumeUnit"
                      id="formHorizontalRadios9"
                      defaultChecked={volumeUnit === "lb" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                  <Col sm={3} xs={4}>
                    {" "}
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      className="unit-hover mt-2  w-100 text-center d-block"
                      label="kg"
                      value="kg"
                      name="volumeUnit"
                      id="formHorizontalRadios10"
                      defaultChecked={volumeUnit === "kg" ? true : false}
                      ref={register()}
                      onChange={() => enableFlag()}
                    />
                  </Col>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="my-auto">
              <div className="text-primary lead font-weight-bold mt-4 mb-1">
                {t("alert_preference")}
                <hr></hr>
              </div>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Col lg={3} sm={4} xs={4}>
              <Form.Check
                custom
                type="checkbox"
                inline
                label={t("sms")}
                name="sendSMS"
                id="formHorizontalCheckbox1"
                defaultChecked={sendSMS}
                ref={register()}
                onChange={() => enableFlag()}
              />{" "}
            </Col>
            <Col lg={3} sm={4} xs={4}>
              <Form.Check
                inline
                custom
                type="checkbox"
                label={t("email")}
                name="sendEmail"
                id="formHorizontalCheckbox2"
                defaultChecked={sendEmail}
                ref={register()}
                onChange={() => enableFlag()}
              />
            </Col>
          </Form.Group>
        </Form>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end text-dark-gray">
            {t("version")}: F-{appVersion}, B-{backEndVersion}
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default ProfilePage;
