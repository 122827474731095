import React from "react";
import moment from "moment";
import i18n from '../i18n';

import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem
} from "../helpers";

const t = i18n.t.bind(i18n);

/** Password regex */
export const PASSWORD_REGEXP = new RegExp(
  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=])(?=\\S+$).{8,16}$"
);

/** Global farmUserRole static array */
export const farmUsersRole = [
  {
    label: i18n.t("site_owner"),
    value: "DairyAdmin",
  },
  {
    label: i18n.t("site_operator"),
    value: "DairyOperator",
  },
  {
    label: i18n.t("milk_hauler"),
    value: "MilkHauler",
  },
  {
    label: i18n.t("sanitarian"),
    value: "Sanitarian",
  },
];

/** global tank state array */
export const tankStateArr = {
  cool: t("cool"),
  wash: t("wash"),
  standby: t("standby"),
  2003: t("BOTTOM_UNIT_COOL"),
  2004: t("ALL_UNIT_COOL"),
  2005: t("BU_DELAY_BU_PULSE_COOL"),
  2006: t("AU_DELAY_BU_PULSED_COOL"),
  3000: t("WWB_FULL_WASH_RINSE_1"),
  3001: t("WWB_FULL_WASH_DELAY"),
  3002: t("WWB_FULL_WASH_RINSE_2"),
  3003: t("WWB_FULL_WASH_DETERGENT_WASH"),
  3004: t("WWB_FULL_WASH_RINSE_3"),
  3005: t("WWB_FULL_WASH_ACID_RINSE"),
  3006: t("WWB_FULL_WASH_RINSE_4"),
  3007: t("WWB_SANITIZE_CYCLE"),
  3008: t("WWB_ACID_CYCLE_PRE_RINSE"),
  3009: t("WWB_ACID_CYCLE_ACID_RINSE"),
  3010: t("WWB_ACID_CYCLE_POST_RINSE"),
  3011: t("WWB_FULL_WASH_COMPLETE"),
  3012: t("WWB_ACID_CYCLE_COMPLETE"),
  3013: t("WWB_SANITIZE_CYCLE_COMPLETE"),
  3100: t("ADB_FULL_WASH_RINSE_1"),
  3101: t("ADB_FULL_WASH_DELAY"),
  3102: t("ADB_FULL_WASH_ACID_WASH"),
  3103: t("ADB_FULL_WASH_RINSE_2"),
  3104: t("ADB_FULL_WASH_DETERGENT_WASH"),
  3105: t("ADB_FULL_WASH_RINSE_3"),
  3106: t("ADB_FULL_WASH_ACID_RINSE"),
  3107: t("ADB_FULL_WASH_RINSE_4"),
  3108: t("ADB_SANITIZE_CYCLE"),
  3109: t("ADB_ACID_CYCLE_PRE_RINSE"),
  3110: t("ADB_ACID_CYCLE_ACID_RINSE"),
  3111: t("ADB_ACID_CYCLE_POST_RINSE"),
  3112: t("ADB_FULL_WASH_COMPLETE"),
  3113: t("ADB_ACID_CYCLE_COMPLETE"),
  3114: t("ADB_SANITIZE_CYCLE_COMPLETE"),
  3200: t("MMB_FULL_WASH_RINSE_1"),
  3201: t("MMB_FULL_WASH_DELAY"),
  3202: t("MMB_FULL_WASH_RINSE_2"),
  3203: t("MMB_FULL_WASH_DETERGENT_WASH"),
  3204: t("MMB_FULL_WASH_RINSE_3"),
  3205: t("MMB_FULL_WASH_ACID_RINSE"),
  3206: t("MMB_SANITIZE_CYCLE"),
  3207: t("MMB_ACID_CYCLE_PRE_RINSE"),
  3208: t("MMB_ACID_CYCLE_ACID_RINSE"),
  3209: t("MMB_FULL_WASH_COMPLETE"),
  3210: t("MMB_ACID_CYCLE_COMPLETE"),
  3211: t("MMB_SANITIZE_CYCLE_COMPLETE"),
};

export const chillerStateArr = {
	128: t("INIT"),
	129: t("STANDBY"),
	130: t("TECH_OVERRIDE"),
	131: t("CHILLER_IDLE"),
	132: t("CHILLING_FLOW_SWITCH_FAILURE"),
	133: t("CHILLING"),
	134: t("CHILLING_LOW_TEMP"),
	135: t("CHILLING_LOW_FLOW"),
	136: t("PUMP_IDLE"),
	137: t("PUMPING_FLOW_SWITCH_FAILURE"),
	138: t("PUMPING"),
	139: t("PUMPING_LOW_FLOW"),
};

export const turckTankStateArr = {
  1000: t("STANDBY"),
  1001: t("AGITATE"),
  2007: t("COOL"),
  3000: t("WWB_FULL_WASH_RINSE_1"),
  3002: t("WWB_FULL_WASH_RINSE_2"),
  3003: t("WWB_FULL_WASH_DETERGENT_WASH"),
  3004: t("WWB_FULL_WASH_RINSE_3"),
  3005: t("WWB_FULL_WASH_ACID_RINSE"),
  3006: t("WWB_FULL_WASH_RINSE_4"),
  3007: t("WWB_SANITIZE_CYCLE"),
  3011: t("WWB_FULL_WASH_COMPLETE"),
  3013: t("WWB_SANITIZE_CYCLE_COMPLETE"),
};

export const controlStatesArr = [{
  3000: t("FW_Rinse_1"),
  3003: t("FW_Detergent_Wash"),
  3005: t("FW_Acid_Rinse"),
  3007: t("Sanitize_Cycle"),
  3011: t("FW_Complete"),
},{
  0: t("standby"),
  1: `${t("tank")} 1 ${t("selected_for_load_out")}`,
  2: `${t("tank")} 2 ${t("selected_for_load_out")}`,
  3: `${t("tank")} 3 ${t("selected_for_load_out")}`,
  4: `${t("tank")} 4 ${t("selected_for_load_out")}`,
  10: `${t("tank")} 1 ${t("selected_for_wash")}`,
  20: `${t("tank")} 2 ${t("selected_for_wash")}`,
  30: `${t("tank")} 3 ${t("selected_for_wash")}`,
  40: `${t("tank")} 4 ${t("selected_for_wash")}`,
  3000: t("FW_Rinse_1"),
  3003: t("FW_Detergent_Wash"),
  3005: t("FW_Acid_Rinse"),
  3007: t("Sanitize_Cycle"),
  3011: t("FW_Complete"),
  3013: t("SC_Complete"),
}];


export const controlStateTypes = {
  STANDBY:  t("standby"),
  MILK_MODE: t("MILK_MODE"),
  TURCK_PARLOR_WASH_MODE : t("parlor_wash_state"),
  TURCK_LOAD_OUT_LOOP_WASHING : t("load_out_loop_wash_state")
}
/** Global condition array */
export const conditionArr = { lt: i18n.t("less_than"), gt: i18n.t("greater_than") };

export const getAlertValueBasedOnUnitPreference = (
  value,
  unit,
  whatToReturn,
  deviceType
) => {
  switch (unit) {
    case "°F":
      ({ tempUnit: unit, temperature: value } = getTempAsPerSelectedUnitSystem(Number(value)));
      break;
    case "psig":
      ({
        pressureUnit: unit,
        pressure: value,
      } = getPressureAsPerSelectedUnitSystem(Number(value)));
      break;
    case "gal":
      ({ volumeUnit: unit, volume: value } = getVolumeAsPerSelectedUnitSystem(
        Number(value)
      ));
      break;
    default:
      break;
  }

  switch (whatToReturn) {
    case "value":
      return value;
    case "unit":
      return unit;
    case "valueAndUnit":
    default:
      return value + " " + unit;
  }
};

export const registerNames = {
  "Bottom Unit #1": "32",
  "Bottom Unit #2": "33",
  "Side Unit #3": "34",
  "Side Unit #4": "35",
};

/** HPF and EVC Sensors */
export const sensors = {
  CHILLER: [
    "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
    "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
    "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
    "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
    "CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS",
    "CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS",
    "CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS",
    "CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS",
    "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
    "CUSTOM_PARAM_ACTIVE_STEPS"
  ],
  HPF: [
    "HPF_INPUT_REG_TEMP1",
    "HPF_INPUT_REG_TEMP2",
    "HPF_INPUT_REG_TEMP3",
    "HPF_INPUT_REG_TEMP4",
    "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
    "HPF_INPUT_REG_MAIN_STATE",
  ],
  EVC: [
    [
      "EVC_INPUT_REG_LOW_SIDE_PRESSURE",
      "EVC_INPUT_REG_LO_SAT_TEMP",
      "EVC_INPUT_REG_HIGH_SIDE_PRESSURE",
      "EVC_INPUT_REG_HI_SAT_TEMP",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
      "CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS",
      "EVC_INPUT_REG_VALVE_POS",
      "EVC_INPUT_REG_FAN_OUTPUT",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
      "TANK_TEMPERATURE" ,
      "TANK_LEVEL" ,
      "TANK_STATE",
    ],
    [
      "EVC_INPUT_REG_SUBCOOL_TEMP",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
      "TANK_TEMPERATURE" ,
      "TANK_LEVEL" ,
      "TANK_STATE",
    ],
    [
      "EVC_INPUT_REG_TEMP_1",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
      "EVC_INPUT_REG_TEMP_2",
      "TANK_TEMPERATURE" ,
      "TANK_LEVEL" ,
      "TANK_STATE",
    ],
  ],
  TURCK_TANKS : [
    "TURCK_PHE_TEMPERATURE_SENSOR_1" ,
    "TURCK_PHE_TEMPERATURE_SENSOR_2" ,
    "TURCK_LOAD_OUT_LOOP_TEMPERATURE" ,
    "TANK_LEVEL" ,
    "TANK_TEMPERATURE" ,
    "WASH_TIME_REMAINING" ,
    
    "AGITATOR_OUTPUT",
    "CONDENSING_UNIT_1",
    "CONDENSING_UNIT_2",
    "TANK_STATE",
    "PERCENT_FULL",

    "TURCK_FLOW_METER" ,
    "TURCK_MOTOR_FREQUENCY_COMMANDED" ,
    "TURCK_VAT_LEVEL_PERCENTAGE" ,
  ],

};

/** Display data ranges function */
export const ranges = {
  Yesterday: [new Date().setDate(new Date().getDate() - 1), new Date()],
  "Last 7 Days": [new Date().setDate(new Date().getDate() - 7), new Date()],
  "Last 30 Days": [new Date().setDate(new Date().getDate() - 30), new Date()],
  "Last Month": [
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  ],
  "This Month": [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  ],
};

/** Display timer below unit */
export const secondsToHourMin = (seconds) => {
  const duration = moment.duration(parseInt(seconds, 10), "seconds");
  let formatted;
  if (duration._isValid === true) {
    formatted = (
      <>
        {duration.get("days") !== 0 ? (
          <>
            {duration.get("days")}d<span className="blink">:</span>
          </>
        ) : (
            ""
          )}
        {(duration.get("hours") > 9 ? "" : "0") + duration.get("hours")}h
        <span className="blink">:</span>
        {(duration.get("minutes") > 9 ? "" : "0") + duration.get("minutes")}m
        <span className="blink">:</span>
        {(duration.get("seconds") > 9 ? "" : "0") + duration.get("seconds")}s
      </>
    );
    return formatted;
  }
  return "";
};

/** Display tank type */
export const TankTypes = [
  {
    label: 'horizontal',
    value: "HORIZONTAL",
  },
  {
    label: 'vertical',
    value: "VERTICAL",
  },
];

/** Display manufacturer type */
export const TankManufactureTypes = [
  {
    label: 'mueller',
    value: "MUELLER",
  },
  {
    label: 'non_mueller',
    value: "NON-MUELLER",
  },
];

export const getFlexWidth = (numDevices) => {
  if (numDevices === 0) {
    return 0;
  }
  if (numDevices === 1) {
    return 9;
  }
  if (numDevices <= 2) {
    return 14;
  }
  if (numDevices === 3) {
    return 24;
  }
  if (numDevices === 4) {
    return 30;
  }
  if (numDevices === 5) {
    return 30;
  }
  return 30;
};

export const RAW_REGISTER_VIEW = "rawRegistersView";
export const PARSED_REGISTER_VIEW = "parsedRegistersView";
export const LIVE_ALERT_VIEW = "liveAlertsView";
export const GRAPH_VIEW = "graphView";
export const SETTINGS_VIEW = "settingsView";