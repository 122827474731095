/* Japanese Language */
export default {
  email_address: "Eメール",
  powered_by: "搭載",
  send_a_link: "リンクを送信",
  register_email:
    "ご登録のメールアドレスを入力して、私たちはあなたを確認するためのリンクをお送りします。",
  contact_administrator_text:
    "あなたのmyMuellerユーザーがまだMilkSecureアカウントとリンクされていません。電子メールを送信することにより、管理者に連絡してください。",
  home: "家",
  page_not_found: "おっと、あなたが探しているページは存在しません。",
  add_new_region: "新しい地域を追加",
  search: "探す",
  add_new_regional_manager: "新たな地域マネージャーを追加",
  data_not_available: "該当データなし",
  regionId: "地域ID",
  region_name: "地域名",
  regional_managers: "地域マネージャー",
  user_detail: "プロファイルの設定",
  change_password: "パスワードを変更する",
  region: "領域",
  phone_number: "電話番号",
  dealers: "ディーラー",
  independent_dairies: "独立した酪農場",
  add_new_dealer: "新しいディーラーを追加",
  add_new_super_admin: "新しいスーパー管理者を追加します",
  delete_record_msg:
    "警告！！ユーザーのデータを削除すると、敷地内にインストールされているサイトやデバイスのすべての情報が損なわれます。情報を取り戻すことはできません。続けたいですか？",
  edit_super_admin: "編集スーパー管理者",
  edit_region: "編集領域",
  region_added_successfully: "領域が正常に追加します",
  something_went_wrong: "いくつかのことは間違っていました",
  region_updated_successfully: "領域が正常に更新します",
  region_deleted_successfully: "領域は正常に削除しました",
  super_admin_deleted_successfully: "スーパー管理者は、正常に削除しました",
  super_admin_added_successfully: "スーパー管理者は、正常に追加しました",
  super_admin_updated_successfully: "スーパー管理者は、正常に更新します",
  regional_manager_added_successfully: "地域のマネージャが正常に追加しました",
  regional_manager_deleted_successfully: "地域のマネージャが正常に削除しました",
  regional_manager_updated_successfully: "地域のマネージャが正常に更新します",
  edit_regional_manager: "編集地域マネージャー",
  dealer_added_successfully: "ディーラーは正常に追加します",
  dealer_deleted_successfully: "ディーラーは正常に削除しました",
  edit_dealer: "編集ディーラー",
  dealer_updated_successfully: "ディーラーは正常に更新します",
  edit_independent_dairy: "編集の独立した乳製品",
  independent_dairy_deleted_successfully: "独立した乳製品は正常に削除しました",
  independent_dairy_updated_successfully: "独立した酪農正常に更新",
  producer_id: "プロデューサーのID",
  dairy_farm: "酪農場",
  dairy_farms: "酪農農場",
  technicians: "技術者",
  add_new_dairy_farm: "新しい酪農を追加",
  technician_deleted_successfully: "技術者は正常に削除しました",
  add_new_technician: "新しい技術を追加",
  technician: "テクニシャン",
  edit_technician: "編集技師",
  technician_name: "技術者の名前",
  assign_dairies: "アサイン酪農場",
  technician_added_successfully: "技術者は正常に追加しました",
  dairy_updated_successfully: "酪農正常に更新",
  dairy_deleted_successfully: "乳製品は正常に削除しました",
  technician_updated_successfully: "技術者は正常に更新します",
  old_password: "以前のパスワード",
  re_enter_new_password: "新しいパスワードを再入力してください",
  changed_password_message: "パスワードが正常に設定されました。",
  password_reset_link_invalid: "パスワードリセットのリンクを無効",
  required_field_error: "この項目は必須です。",
  password_length_error: "パスワードは8つの文字を持っている必要があります",
  user_not_registered: "ユーザーは、このメールに登録されていません",
  region_id_required: "領域IDが必要です。",
  region_name_error: "地域名が必要です。",
  name_error: "名前が必要です。",
  email_error: "メールが必要です。",
  region_error: "領域が必要です。",
  password_error: "パスワードが必要です。",
  dealer_name: "ディーラー名が必要です。",
  dealer_email: "ディーラーの電子メールが必要です。",
  producer_id_error: "プロデューサーIDが必要です。",
  dairy_name_error: "乳製品名が必要です。",
  dairy_address: "乳製品アドレスが必要です。",
  dairy_city: "乳製品市が必要とされます。",
  dairy_state: "酪農状態が必要です。",
  technician_name_error: "技術者名が必要です。",
  technician_email: "技術者の電子メールが必要です。",
  login_screen_text: "あなたのアカウントにログイン",
  PMC_Login: "でログイン",
  forgot_password: "ログインできませんか？",
  or: "OR",
  privacy_policy: "個人情報保護方針",
  terms_and_conditions: "規約と条件",
  data_policy: "データポリシー",
  end_user_license_agreement: "エンドユーザーライセンス契約",
  contact_administrator: "連絡先管理",
  are_you_sure: "あなたが継続してもよろしいですか？",
  tank_mode: "タンクのモード",
  last_agitated: "最終撹拌時間",
  live_data: "ライブデータ",
  re_register: "再登録",
  raw_register: "生のレジスタ",
  register_values: "値を登録します",
  tank_updated: "タンクに成功しました更新",
  tank_deleted: "タンクは正常に削除しました",
  edit_dairy_farm: "編集daityファーム",
  create: "作成",
  update: "更新",
  regional_manager: "地域担当マネージャー",
  independent_dairy: "独立した乳製品",
  language_preferences: "言語のプリファレンス",
  unit_preferences: "ユニットPregeferences",
  temperature_unit: "温度単位",
  pressure_unit: "プレッシャーユニット",
  volume_unit: "ボリューム単位",
  alert_preference: "アラートの設定",
  language_dropdown: "言語ドロップダウン",
  details_updated_successfully: "ユーザーの詳細が正常に更新します",
  regions: "地域",
  login: "ログイン",
  password: "パスワード",
  enter_email: "Eメール",
  actions: "アクション",
  edit: "編集",
  delete: "削除",
  add: "追加",
  super_admin: "特別管理者",
  city: "都市",
  state: "状態",
  county: "郡/地区",
  address: "所在地",
  new_password: "新しいパスワード",
  reset_password: "パスワードをリセット",
  current_password_wrong: "現在のパスワードは正しくありません。",
  link_sent_successfully: "パスワードリセットのリンクをメール送信しました",
  no_data: "データはありません。",
  back_to_login: "ログインに戻る",
  tank_not_registered: "タンクは登録されていません。",
  the_dairy_owner_has_not_registered: "乳製品の所有者は登録していません。",
  tank_added: "タンクは正常に追加されました",
  language_preferences: "言語のプリファレンス",
  name: "名称",
  unit_preference: "単位設定",
  temperature_unit_preference: "温度単位",
  pressure_unit: "プレッシャーユニット",
  volume_unit: "ボリューム単位",
  basic_details: "基本的な詳細",
  alert_preference: "アラートの設定",
  language_dropdown: "言語ドロップダウン",
  log_out: "ログアウト",
  email: "Eメール",
  details_updated_successfully: "ユーザーの詳細が正常に更新します",
  regions: "地域",
  phone: "電話",
  is_registered: "登録",
  dairies: "明治乳業",
  unable_to_connect_server:
    "サーバーに接続できません。 後でもう一度お試しください。",
  loading: "読み込み中です…",
  username_not_found: "ユーザー名が見つかりません。",
  tank_temperature: "タンク内温度",
  tank_volume: "タンク内容量",
  pipeline_temperature: "パイプラインの温度",
  add_new_tank: "新規タンクを追加",
  num_evcs: "EVCのNo.…",
  dairy_name: "牧場名",
  tank_name: "タンク名称",
  clear: "晴れ",
  sms: "エスエムエス",
  confirm_new_password: "新しいパスワードを確認",
  confirm_password_required: "パスワードの確認が必要です",
  dairy_added_successfully: "牧場は正常に追加されました",
  dealer: "ディーラー",
  enter_a_new_password: "新しいパスワードを入力してください",
  not_register: "未登録",
  password_min: "パスワードには少なくとも8から16文字が含まれている必要があります",
  password_not_match: "新しいパスワードと確認パスワードが異なります",
  password_regex_message: "パスワードが有効ではありません",
  password_required: "パスワードが必要です。",
  resend_link: "再送リンク",
  send_invitation_link: "アカウントのアクティベーションリンク送信",
  sent: "送信",
  set_password: "パスワードを設定してください",
  set_validation_message:
    "8間でパスワードを使用してください - 少なくとも1の数字、1つの特殊文字、1つの大文字と隙間なく1つの小文字を含まなければならない16個の文字を。",
  user_has_been_not_registered: "ユーザはまだ登録されていません",
  milk_pipeline_temperature: "ミルク配管温度",
  temperature_sensor_3: "温度センサー ３",
  temperature_sensor_4: "温度センサー 4",
  evc_temperature: "EVC 温度",
  subcool_temperature: "サブクール温度",

  Cooling: "冷却",
  BU_Cool: "ボトム冷凍機冷却",
  Washing: "洗浄",
  NULL: "NULL",
  INIT: "INIT",
  Power_Fail_Recovery: "電源障害復旧",
  Standby: "スタンバイ",
  Sample_Agitate: "集乳攪拌",
  Editing_Parameters: "パラメーター編集",
  Diagnostic: "診断",
  Setup: "セットアップ",
  BU_Delay_Start: "ボトム冷凍機ディレイ遅延スタート",
  AU_Bottom_Delay_Start: "AUボトムディレイスタート",
  AU_Upper_Delay_Start: "AUアッパーディレイスタート",
  AU_Cool: "全冷凍機冷却",
  BU_Delay_BU_Pulsed_Cool: "ボトム冷凍機遅延　ボトム冷凍機パルス冷却",
  AU_Delay_BU_Pulsed_Cool: "全冷凍機遅延　ボトム冷凍機パルス冷却",
  FW: "FW",
  AC: "AC",
  SC: "SC",
  Rinse_1_WWB: "第１すすぎ (WWB)",
  Delay_WWB: "遅延 (WWB)",
  Rinse_2_WWB: "第２すすぎ (WWB)",
  Detergent_Wash_WWB: "アルカリ洗浄 (WWB)",
  Rinse_3_WWB: "第３すすぎ (WWB)",
  Acid_Rinse_WWB: "酸リンス (WWB)",
  Post_Rinse_WWB: "すすぎ (WWB)",
  Complete_WWB: "完了 (WWB)",
  Rinse_1_ADB: "第１すすぎ (ADB)",
  Delay_ADB: "ディレイ (ADB)",
  Acid_Wash_ADB: "酸洗浄 (ADB)",
  Rinse_2_ADB: "第２すすぎ (ADB)",
  Detergent_Wash_ADB: "アルカリ洗浄 (ADB)",
  Rinse_3_ADB: "第３すすぎ (ADB)",
  Acid_Rinse_ADB: "酸リンス (ADB)",
  Post_Rinse_ADB: "すすぎ (ADB)",
  Complete_ADB: "完了 (ADB)",
  SC_Complete_ADB: "殺菌完了 (ADB)",
  Rinse_1_MMB: "第１すすぎ (MMB)",
  Delay_MMB: "遅延 (MMB)",
  Rinse_2_MMB: "第２すすぎ (MMB)",
  Detergent_Wash_MMB: "アルカリ洗浄 (MMB)",
  Rinse_3_MMB: "第３すすぎ (MMB)",
  Acid_Rinse_MMB: "酸リンス (MMB)",
  Sanitize_Cycle_MMB: "殺菌サイクル (MMB)",
  Pre_Rinse_MMB: "予備すすぎ (MMB)",
  Complete_MMB: "完了 (MMB)",
  SC_Complete_MMB: "殺菌 完了(MMB)",
  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "バージョン",
  farm_users: "ファームユーザー",
  alerts: "アラート",
  add_new_farm_users: "新しいファームのユーザーを追加",
  role: "役割",
  user_role: "農場ユーザーのタイプ",
  farm_users_added_successfully: "農場ユーザーが正常に追加します",
  farm_users_deleted_successfully: "農場ユーザーが正常に削除しました",
  user_create_different_role_error:
    "異なる役割で作成したユーザーがすでにあります。これを解決するために、サイト管理者にお問い合わせください。",
  farm_users_updated_successfully: "ファームユーザーが正常に更新します.",
  close: "閉じる",
  assign: "割当",
  email_already_exists:
    "上記の電子メールIDを持つユーザーが既に存在しています。",
  user_create_different_dairy_to_assign:
    "その役割を作成したユーザーはすでにあります。あなたは、この乳製品に彼/彼女を追加しますか？",

  alert_history_error: "フェッチのアラート履歴中にエラー",
  can_not_login: "ログインできませんか？",
  new_password_required: "新しいパスワードが必要です。",
  old_password_required: "古いパスワードが必要です。",
  re_enter_new_password_required: "新しいパスワードを再入力が必要です。",
  by_signing_up:
    "サインアップすると、以下にリンクされているプラ​​イバシーポリシーに同意したことになります。",
  resend_email_link_expired:
    "あなたのリンクは有効期限が切れている、あなたの管理者に連絡してください",
  configure_alerts: "アラートの設定",
  add_alert: "追加の警告",
  tank_name: "タンク名前",
  alert_deleted_successfully: "アラートが正常に削除しました",
  alert_not_found: "ノーカスタマイズされたアラートが見つかりました。",
  sensor_name: "センサー名",
  sensor_value: "センサ値",
  duration: "間隔",
  tank_state: "タンク状態",
  condition: "状態",
  delay: "遅延",
  minutes: "分",
  sensor_value_error: "センサが必要です。",
  delay_value_error: "遅延が必要です。",
  duration_value_error: "期間が必要です。",
  duplicate_email: "重複電子メールID。",
  invalid_email: "メールのフォーマットが間違っています。",
  alert_added_successfully: "アラートが正常に追加しました",
  maximum_email: "あなたは10通の以上の電子メールを追加することはできません。",
  contact_number_error: "問い合わせ番号が必要です。",
  duplicate_contact_number: "重複する連絡先番号",
  maximum_contact_number:
    "あなたは10個の以上の数字を追加することはできません。",
  contact_digit: "連絡先番号は10桁の数字でなければなりません。",
  config_alert:
    "アラートを設定するには、1つの電話番号や電子メール、少なくとも入力してください。",
  update_form_button: "更新",
  live_alert_not_found: "何のライブアラートはありません。",
  live_alerts: "ライブアラート",
  resolved_alerts: "解決済みアラート",
  live_alert_history_error: "エラーのライブ通知履歴を取得しながら、",
  loading_text: "、読み込みを待ってください...",
  resolved_alert_not_found: "何の解決アラートはありません",
  triggered: "発生日時",
  resolved: "解決済み",
  show_annotation: "注釈を示す",
  hide_annotation: "隠す注釈",
  clear: "晴れ",
  submit: "参加する",
  export_csv: "エクスポートCSV",
  add_annotation_instruction:
    "グラフにカーソルを合わせて、注釈を追加する場所をクリックしてください。",
  from_date_time: "から",
  to_date_time: "に",
  select_date: "日付を選択してください。",
  select_from_date: "日付から選択してください。",
  select_to_date: "これまでに選択してください。",
  date_not_same: "日付は同じにすることはできません。",
  to_date_greater_than_today:
    "現在までに、今日よりも大きくすることはできません。",
  from_date_greater_than_today:
    "日付から現在までよりも大きくすることはできません。",
  select_date_range: "31日以内の日付範囲を選択してください。",
  tank_data_not_available:
    "タンクデバイスは、これらの日付の間にはデータがありません。",
  download_failed: "ダウンロードに失敗しました。",
  file_downloaded: "あなたのファイルがダウンロードされています。",
  select_date_time: "日付と時刻を選択",
  regional_managers_error: "地域の経営者が必要とされます。",
  independent_dealers: "独立したディーラー",
  cancel_re_register: "再登録をキャンセル",
  re_registers_successfully: "再登録に成功",
  evc_error: "EVCが必要です.",
  cancel_registers_successfully: "成功しました再登録取り消し",
  lsp: "低圧圧力",
  sst: "飽和吸引温度",
  hsp: "高圧圧力",
  sct: "凝縮温度",
  no_dairy_farm_data_available:
    "この酪農場のためのそのようなデータはありません。",
  add_custom_alert: "カスタムアラートを追加します",
  farm_user: "農家",
  calibration_charts: "キャリブレーションチャート",
  add_new_chart: "新しいチャートを追加してください",
  chart_name: "チャート名",
  type: "タイプ",
  manufacturer: "メーカー",
  calibration_chart: "キャリブレーションチャート",
  create_myMueller_chart: "MyMuellerチャートを作成します",
  horizontal_inside_diameter: "タンク水平方向の直径",
  vertical_inside_diameter: "タンク垂直直径",
  shell_length: "シェルの長さ（縫い目への縫い目）",
  shell_material_thickness: "シェル材料の厚さ",
  tank_pitch_foot: "一歩あたりのタンクピッチ（インチ）",
  head_material_thickness: "ヘッド材の厚さ",
  head_inside_knuckle: "ナックル半径の中に頭",
  height_increment_value: "高さの増分値",
  right_head_material_thickness: "右頭材の厚さ",
  right_head_inside_knuckle: "ナックル半径の中の右頭",
  create_chart: "チャートを作成します",
  transducer_limit: "トランスデューサの制限",
  calibration_deleted_successfully:
    "キャリブレーションチャートは正常に削除されました。",
  chart_name_required: "チャート名が必要です。",
  tank_type_required: "タイプが必要です。",
  manufacture_required: "製造が必要です。",
  transducer_limit_required: "トランスデューサの制限が必要です。",
  transducer_limit_invalid: "無効なトランスデューサの制限",
  tank_detail: "タンクの詳細",
  calibration: "較正",
  create_new_with: "新しいものを作成します",
  tank_height: "デバイスの高さ",
  inch: "インチ",
  gal_usa: "ギャルアメリカ",
  volume_increment: "インクリメンタルボリューム",
  accumulated_volume: "全容積",
  horizontal_inside_diameter_required: "水平方向の内径が必要です。",
  vertical_inside_diameter_required: "タンク垂直内径が必要です。",
  shell_length_required: "シェルの長さが必要です。",
  shell_material_thickness_required: "シェル材料の厚さが必要です。",
  tank_pitch_foot_required: "足（インチ）あたりのタンクピッチが必要です。",
  head_material_thickness_required: "ヘッドの材料の厚さが必要です。",
  head_inside_knuckle_required: "ナックル半径内のヘッドが必要です。",
  height_increment_value_required: "高さの増分値が必要です。",
  right_head_material_thickness_required: "右頭材の厚さが必要です。",
  right_head_inside_knuckle_required: "ナックル半径内の右頭が必要です。",
  drag_drop_file: "ここでファイルをドラッグアンドドロップします",
  just_click_browse_file: "ファイルを参照してください",
  chart_added_successfully: "チャートが正常に追加されました。",
  invalid_excel: "間違ったファイル形式をアップロードしました",
  calibration_chart_required: "キャリブレーションチャートが必要です。",
  special_instruction: "特別命令",
  accumulate_height_csv_text: "高さ（インチ）",
  accumulate_volume_csv_text: "累積ボリューム（Gal US）",
  volume_increment_csv_text: "ボリュームインクリメント（GAL US）",
  sample_calibration_chart: "キャリブレーションチャートテンプレートをダウンロードしてください",
  dairy_admin_exists: "Dairy Adminはすでにこの酪農場で終了しています。",
  all_tanks: "すべての戦車",
  producerId_max_length_error: "プロデューサーIDは50文字を超えてはなりません",
  producerId_alphanumeric_error: "使用できるのは英数字のみです",
  invalid_email_error: '有効なメールアドレスを入力してください',
  Agitating: 'かき混ぜる',
  AGITATE: "アジテート",
  COOL: "いいね",
  DIAGNOSTIC: "診断",
  SETTINGS: "設定",
  STANDBY: "待機する",
  WASH: "洗う",
  alert: "警戒している",
  tank: "タンク",
  tanks: "タンク",
  no_manufacturer: "なし",
  mueller: "ミューラー",
  non_mueller: "他社メーカー",
  no_type: "なし",
  horizontal: "横型",
  vertical: "縦型",
  dairy_owner: "経営者",
  dairy_operator: "搾乳者",
  milk_hauler: "運送業者",
  sanitarian: "衛生士",
  HSP: "高圧圧力",
  LSP: "低圧圧力",
  valve_position: "バルブの位置",
  less_than: "未満",
  greater_than: "以上",
  cool: "冷却",
  wash: "洗浄",
  standby: "待機",
  BOTTOM_UNIT_COOL: "第1冷凍機冷却",
  ALL_UNIT_COOL: "全冷凍機冷却",
  BU_DELAY_BU_PULSE_COOL: "第1冷凍機パルス冷却遅延",
  AU_DELAY_BU_PULSED_COOL: "全冷凍機パルス冷却遅延",
  WWB_FULL_WASH_RINSE_1: "WWBフルウォッシュリンス1",
  WWB_FULL_WASH_DELAY: "WWBフルウォッシュディレイ",
  WWB_FULL_WASH_RINSE_2: "WWBフルウォッシュリンス2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWBフルウォッシュ洗剤洗浄",
  WWB_FULL_WASH_RINSE_3: "WWBフルウォッシュリンス3",
  WWB_FULL_WASH_ACID_RINSE: "WWBフルウォッシュ酸リンス",
  WWB_FULL_WASH_RINSE_4: "WWBフルウォッシュリンス4",
  WWB_SANITIZE_CYCLE: "WWBサニタイズサイクル",
  WWB_ACID_CYCLE_PRE_RINSE: "WWB酸サイクルプレリンスプレ",
  WWB_ACID_CYCLE_ACID_RINSE: "WWB酸サイクル酸リンス",
  WWB_ACID_CYCLE_POST_RINSE: "WWB酸サイクルポストリンス",
  WWB_FULL_WASH_COMPLETE: "WWBフル洗浄コンプリート",
  WWB_ACID_CYCLE_COMPLETE: "WWB酸サイクルの完了",
  WWB_SANITIZE_CYCLE_COMPLETE: "WWBサニタイズサイクルの完了",
  ADB_FULL_WASH_RINSE_1: "ADBフルウォッシュリンス1",
  ADB_FULL_WASH_DELAY: "ADBフルウォッシュディレイ",
  ADB_FULL_WASH_ACID_WASH: "ADBフルウォッシュ酸洗浄",
  ADB_FULL_WASH_RINSE_2: "ADBフルウォッシュリンス2.",
  ADB_FULL_WASH_DETERGENT_WASH: "ADBフルウォッシュ洗剤洗浄",
  ADB_FULL_WASH_RINSE_3: "ADBフルウォッシュリンス3",
  ADB_FULL_WASH_ACID_RINSE: "ADBフルウォッシュ酸リンス",
  ADB_FULL_WASH_RINSE_4: "ADBフルウォッシュリンス4",
  ADB_SANITIZE_CYCLE: "ADBサニタイズサイクル",
  ADB_ACID_CYCLE_PRE_RINSE: "ADB酸サイクルプレリンスプレ",
  ADB_ACID_CYCLE_ACID_RINSE: "ADB酸サイクル酸リンス",
  ADB_ACID_CYCLE_POST_RINSE: "ADB酸回路ポストリンス",
  ADB_FULL_WASH_COMPLETE: "ADBフル洗浄コンプリート",
  ADB_ACID_CYCLE_COMPLETE: "ADB酸サイクルの完了",
  ADB_SANITIZE_CYCLE_COMPLETE: "ADBサニタイズサイクルコンプリート",
  MMB_FULL_WASH_RINSE_1: "すすぎ 1 (MMB)",
  MMB_FULL_WASH_DELAY: "洗浄遅延 (MMB)",
  MMB_FULL_WASH_RINSE_2: "すすぎ 2 (MMB)",
  MMB_FULL_WASH_DETERGENT_WASH: "アルカリ洗浄 (MMB)",
  MMB_FULL_WASH_RINSE_3: "すすぎ 3 (MMB)",
  MMB_FULL_WASH_ACID_RINSE: "酸性洗浄 (MMB)",
  MMB_SANITIZE_CYCLE: "殺菌洗浄 (MMB)",
  MMB_ACID_CYCLE_PRE_RINSE: "前すすぎ (酸性サイクル/MMB)",
  MMB_ACID_CYCLE_ACID_RINSE: "酸性洗浄 (酸性サイクル/MMB)",
  MMB_FULL_WASH_COMPLETE: "アルカリ洗浄 (MMB)",
  MMB_ACID_CYCLE_COMPLETE: "酸性サイクル (MMB)",
  MMB_SANITIZE_CYCLE_COMPLETE: "殺菌サイクル (MMB)",
  EVC_INPUT_REG_TEMP_1: "温度センサー 1",
  EVC_INPUT_REG_TEMP_2: "温度センサー 2",
  EVC_INPUT_REG_TEMP_3: "温度センサー 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "サブクール温度",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "高圧",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "低圧",
  EVC_INPUT_REG_HI_SAT_TEMP: "飽和温度 (高圧)",
  EVC_INPUT_REG_LO_SAT_TEMP: "飽和温度 (低圧)",
  EVC_INPUT_REG_FAN_OUTPUT: "ファンスピード",
  HPF_INPUT_REG_TEMP1: "タンク内温度",
  HPF_INPUT_REG_TEMP2: "ミルクパイプライン温度",
  HPF_INPUT_REG_TEMP3: "温度 3",
  HPF_INPUT_REG_TEMP4: "温度 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "タンクボリューム",
  select_user_role: "ユーザーの役割を選択してください",
  forget_password_description:
    "ユーザーアカウントの検証済みメールにパスワードリセットリンクを送信します。",
  height: "身長",
  Inches: "インチ",
  Centimeters: "センチメートル",
  Gallons: "ガロン",
  Litres: "リットル",
  Pounds: "ポンド",
  Kilograms: "キログラム",
  chart_name_exists: 'チャート名はすでに存在します',
  height_unit: '高さユニット',
  device_name: "装置名",
  device_type: "デバイスタイプ",
  location_name: "場所の名前",
  equipment_model_number: "機器の種類",
  processed_solution: "処理されたソリューション",
  Industry: "業界",
  add_device: "新しいデバイスを追加します",
  edit_device: "デバイスの編集",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_1: "チラーアウトレット温度",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_2: "チラー戻り温度",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_3: "アウトレット温度を処理します",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_4: "インレット温度を処理します",
  temperature_sensor_1: "温度センサー1",
  temperature_sensor_2: "温度センサー2",
  pump: "ポンプ",
  chillers: "チラー",
  dashboard_users: "ダッシュボードユーザー",
  fan_speed: "ファン回転速度",
  chiller_added: "チラーは正常に追加されました。",
  chiller_updated: "チラーは正常に更新されました。",
  chiller_deleted: "チラーは正常に削除されました。",
  device_not_registered: "登録されていないデバイス",
  water_relay_status: "ウォーターリレーステータス",
  voltage_input_status: "電圧入力ステータス",
  probe_sensor_status: "プローブセンサーステータス",
  lsp_1: "低側の圧力1",
  lsp_2: "低側の圧力12",
  lsp_3: "低側の圧力13",
  lsp_4: "低側の圧力14",
  lsp_5: "低側の圧力15",
  lsp_6: "低側の圧力16",
  add_control: "コントロールを追加します",
  setting_parameters: "パラメーターの設定",
  devices: "デバイス",
  process_outlet_temperature: "アウトレット温度を処理します",
  process_inlet_temperature: "イントレット温度を処理します",
  chiller_return_temperature: "チラー戻り温度",
  chiller_outlet_temperature: "チラーアウトレット温度",
  control_status: "制御ステータス",
  send_alert_if: "ifを送信します",
  is: "は",
  when_tank_in: "タンクインするとき",
  when_chiller_in: "チラーで",
  state_for_more_than: "それ以上のために述べてください",
  INACTIVE: "非活性",
  SOFT_STAR_UNLOADED: "ソフトスターがアンロードされています",
  SOFT_STAR_LOADED: "ソフトスターがロードされました",
  WAITING: "待っている",
  LOADED: "ロード",
  UNLOADED: "降ろす",
  ANTI_SHORT_CYCLE: "アンチショートサイクル",
  FREEZE_PROTECT: "フリーズ保護",
  INIT: "初期化",
	STANDBY: "待機する",
	TECH_OVERRIDE: "技術オーバーライド",
	CHILLER_IDLE: "チラーアイドル",
	CHILLING_FLOW_SWITCH_FAILURE: "冷えたフロースイッチスイッチの障害",
	CHILLING: "冷やしている",
	CHILLING_LOW_TEMP: "低温の寒さ",
	CHILLING_LOW_FLOW: "低い流れを寒くします",
	PUMP_IDLE: "ポンプアイドル",
	PUMPING_FLOW_SWITCH_FAILURE: "ポンピングフロースイッチの障害",
	PUMPING: "ポンピング",
	PUMPING_LOW_FLOW: "低流量をポンピングします",
  date: '日にち',
  time: '時間',
  timezone: 'タイムゾーン',
  sleep_mode: 'スリープモード',
  chiller_model: 'チラーモデル',
  chiller_name: 'チラー名',
  company_name: '会社名',
  producer_id: 'プロデューサーID',
  milksecure_integration: 'Milksecure統合',
  version_details: 'バージョンの詳細',
  ip_address: 'IPアドレス',
  mac_address: 'Macアドレス',
  language: '言語',
  compressor_rolling_start: 'コンプレッサーローリングスタート',
  degrees_fc: '度f/c',
  pressure_unit: '圧力ユニット',
  temperature_1_offset: '温度1オフセット',
  temperature_2_offset: '温度2オフセット',
  temperature_3_offset: '温度3オフセット',
  temperature_4_offset: '温度4オフセット',
  process_flow_meter: 'プロセスフローメーター',
  process_velocity_max: 'プロセス速度最大',
  process_velocity_min: 'プロセス速度min',
  process_tubing_inside_diameter: 'プロセスチューブ内径',
  process_flow_offset: 'プロセスフローオフセット',
  process_flow_unit: 'プロセスフローユニット',
  redundant_pump: '冗長ポンプ',
  pump_rotating_start: 'ポンプ回転開始',
  water_level_control: '水位制御',
  high_level_probe_sensitivity: '高レベルプローブ感度',
  low_level_probe_sensitivity: '低レベルプローブ感度',
  chiller_flow_meter: 'チラーフローメーター',
  chiller_velocity_max: 'チラー速度最大',
  chiller_velocity_min: 'チラー速度min',
  chiller_solution_pipe_inside_diameter: 'チラーソリューションパイプ内径',
  chiller_flow_offset: 'チラーフローオフセット',
  chiller_flow_unit: 'チラーフローユニット',
  high_temp_alert_1_setpoint: '高温。アラート1セットポイント',
  high_temp_alert_1_sensor: '高温。アラート1センサー',
  high_temp_alert: '高温。アラート',
  high_temp_alert_2: '高温。アラート2',
  high_temp_alert_2_setpoint: '高温。アラート2セットポイント',
  high_temp_alert_2_sensor: '高温。アラート2センサー',
  high_temp_alert_delay: '高温。アラート遅延',
  high_temp_alert_duration: '高温。アラート期間',
  low_temp_alert_setpoint: '低温。アラートセットポイント',
  low_temp_alert_1_sensor: '低温。アラート1センサー',
  low_temp_alert: '低温。アラート',
  low_temp_alert_2: '低温。アラート2',
  low_temp_alert_2_setpoint: '低温。アラート2セットポイント',
  low_temp_alert_2_sensor: '低温。アラート2センサー',
  low_temp_alert_delay: '低温。アラート遅延',
  low_temp_alert_duration: '低温。アラート期間',
  flow_switch: 'フロースイッチ',
  water_level_alert: '水位アラート',
  water_level_alert_duration: '水位警告期間',
  evc_plus: 'EVC+',
  unloader_1: 'アンローダー1',
  unloader_2: 'アンローダー2',
  anti_short_cycle: 'アンチショートサイクル',
  freeze_protect_setpoint: 'フリーズ保護セットポイント',
  freeze_protect_duration: 'フリーズ保護期間',
  unsafe_suction_pressure_setpoint: '安全でない吸引圧力設定値',
  unsafe_suction_pressure_duration: '安全でない吸引圧力時間',
  stage_1: 'ステージ1',
  stage_2: 'ステージ2',
  stage_3: 'ステージ3',
  stage_4: 'ステージ4',
  stage_5: 'ステージ5',
  stage_6: 'ステージ6',
  cool_setpoint: 'クールなセットポイント',
  cool_differential: 'クールなディファレンシャル',
  temperature_sensor: '温度センサー',
  unloader_1_setpoint: 'アンローダー1セットポイント',
  unloader_1_differential: 'アンローダー1差',
  unloader_2_setpoint: 'アンローダー2セットポイント',
  unloader_2_differential: 'アンローダー2差',
  system_setup: "システムのセットアップ",
  chiller_setup: "チラーセットアップ",
  pump_setup: "ポンプセットアップ",
  alarms_alerts: "アラーム/アラート",
  cooling_setup: "冷却セットアップ",
  sites: "サイト",
  site: "サイト",
  site_name: "サイト名",
  add_new_site: "新しいサイトを追加します",
  site_added_successfully: "サイトが正常に追加されました。",
  site_updated_successfully: "サイトが正常に更新されました。",
  site_deleted_successfully: "サイトが正常に削除されました。",
  site_name_error: "サイト名が必要です。",
  site_address: "サイトアドレスが必要です。",
  site_city: "サイトシティが必要です。",
  site_state: "サイト状態が必要です。",
  the_site_owner_has_not_registered: "サイトの所有者は登録していません。",
  user_create_different_site_to_assign: "その役割を作成したユーザーがすでにあります。彼/彼女をこのサイトに追加しますか？",
  device_added: "デバイスが正常に追加されました。",
  device_updated: "デバイスが正常に更新されました。",
  device_deleted: "デバイスが正常に削除されました。",
  independent_site: "独立したサイト",
  independent_sites: "独立したサイト",
  site: "サイト",
  sites: "サイト",
  device: "デバイス",
  devices: "デバイス",
  assign_sites: "サイトを割り当てます",
  no_assign_sites: "割り当てサイトはありません",
  all_sites: "すべてのサイト",
  add_new_dashboard_users: "新しいダッシュボードユーザーを追加します",
  dashboard_users_added_successfully: "ダッシュボードユーザーが正常に追加されました。",
  dashboard_users_deleted_successfully: "ダッシュボードユーザーは正常に削除されました。",
  site_admin: "サイト管理者",
  site_owner: "ウェブサイトのオーナー",
  site_operator: "サイトオペレーター",
  no_site_data_available: "このサイトにはそのようなデータはありません。",
  dashboard_user: "ダッシュボードユーザー",
  active_steps: "アクティブなステップ",
  minute: "分",
  height: "身長",
  device_state: "デバイス状態",
  process_flow: "プロセスフロー",
  chiller_flow: "チラーフロー",
  current_device_offline: "現在、デバイスはオフラインです",
  accumulator_inlet_temperature: "アキュムレータの入口温度",
  accumulator_outlet_temperature: "アキュムレータアウトレット温度",
  accumulator_temperature_deference: "アキュムレータTD",
  EVC_FIRMWARE_VERSION: "EVCファームウェアバージョン",
  HPF_FIRMWARE_VERSION: "HPFファームウェアバージョン",
  CHILLER_FIRMWARE_VERSION: "チラーファームウェアバージョン",
  RIO_FIRMWARE_VERSION: "Rioファームウェアバージョン",
  EVC_NUMBER: "EVC番号",
  HPF_NUMBER: "HPF番号",
  CHILLER_NUMBER: "チラー番号",
  HiPerForm_Plus: "HiPerForm plus",
  Telos_Chiller: "Milksecureチラー",
  Telos_Tank_EU: "Milksecureタンク (EU)",
  Telos_Tank_NA: "Milksecureタンク (NA)",
  Nimbus: "ニンバス",
  Stratus: "ストラタス",
  milk_cooler: "ミルククーラー",
  milk_tower: "牛乳塔",
  ls_chiller: "LSチラー",
  lsa_chiller: "LSAチラー",
  cp2_chiller: "CP2チラー",
  maxximizer: "MAXXimizer",
  hi_per_chill: "HiPerChill",
  falling_film_chiller: "落下フィルムチラー",
  Brewing: "醸造",
  Dairy: "乳製品",
  Bakery: "ベーカリー",
  Other: "他の",
  Milk: "牛乳",
  Beer: "ビール",
  Water: "水",
  Ethanol: "エタノール",
  Yeast: "酵母",
  Eggs: "卵",
  no_site_admin: "サイト管理者はいません",
  ALARM: '警報',
  TURCK_PHE_TEMPERATURE_SENSOR_1: "PHE 温度センサー 1",
  TURCK_PHE_TEMPERATURE_SENSOR_2: "PHE 温度センサー 2",
  TURCK_LOAD_OUT_LOOP_TEMPERATURE: "ロードアウトループ温度",
  TANK_LEVEL: "タンクレベル",
  TANK_TEMPERATURE: "タンク温度",
  WASH_TIME_REMAINING: "残りの洗浄時間",
  TURCK_FLOW_METER: "流量計" ,
  TURCK_MOTOR_FREQUENCY_COMMANDED: "モーター周波数" ,
  TURCK_VAT_LEVEL_PERCENTAGE: "バットレベル" ,
  TURCK_TANK_LEVEL: "タンクレベル",
  TANK_LEVEL_2: "タンクレベル2",
  parlor_wash_state: "パーラーウォッシュの状態",
  load_out_loop_wash_state: "ロードアウトループ状態",
  FW_Rinse_1: "FW - リンス",
  FW_Detergent_Wash: "FW - 洗剤洗浄",
  FW_Acid_Rinse: "FW - アシッドリンス",
  Sanitize_Cycle: "消毒サイクル",
  FW_Complete: "FW - 完了",
  SC_Complete: "SC完了",
  TURCK_NUMBER_OF_TANKS: "タンク数",
  tank_status: "タンクのステータス",
  Sanitize_Cycle_WWB : "サニタイズサイクル (WWB)",
  Rinse_4_WWB: "リンス 4 (WWB)",
  parlor_control_status: "パーラー制御ステータス",
  STATUS: "状態",
  TURCK_TANK_TEMPERATURE: "タンク温度",
  control_state_type: "制御状態の種類",
  TURCK_CONDUCTIVITY_SENSOR_1_PARLOR: "導電率センサー 1 パーラー",
  TURCK_CONDUCTIVITY_SENSOR_2_TANK: "導電率センサー 1タンク",
  TURCK_TANKS_LEVEL_1: "タンクレベル1",
  TURCK_TANKS_LEVEL_2: "タンクレベル2",
  when_control_in: "コントロールイン時",
  load_out_loop_status: "ロードアウトループステータス",
  selected_for_load_out: "ロードアウト用に選択されました",
  selected_for_wash: "ウォッシュに選ばれました",
  MILK_MODE: "ミルクモード",
  LOAD_OUT_LOOP: "ロードアウトループ",
  PARLOR: "パーラー"
};
