/* eslint-disable */
import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert,
  Tab,
  Tabs
} from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useHistory, useParams, withRouter } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDeviceContext } from "../../context/deviceContext";
import { isCancel } from "axios";
import TankCard from "../../components/TankCard";
import IconDownLoad from "../../assets/images/exportCsv.svg";
import Settings from "../../containers/Tank/Settings";
import io from "../../actions/socket";
import TanksLoader from "../../components/TankLoader";
import GraphLoader from "../../components/GraphLoader";
import RawRegisterLoader from "../../components/RawRegisterLoader"
import ParseRegisterLoader from "../../components/ParseRegisterLoader"
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg";
import LiveDataSideBar from "../../components/LiveDataContent";
import EVC from "../../assets/images/EVC-fan-gif.gif";
import {ReactComponent as ThermometerIcon} from '../../assets/images/thermometer.svg';
import {ReactComponent as SaturatedSuctionIcon} from '../../assets/images/Group 3757.svg';
import { ReactComponent as FallingFilmIcon } from "../../assets/images/Falling_Film_Chiller.svg";
import { ReactComponent as HiperChill50Icon } from "../../assets/images/HiPerChill_5.svg";
import { ReactComponent as BakeryChillerIcon } from "../../assets/images/Bakery_Chiller.svg";
import { ReactComponent as LsChillerIcon } from "../../assets/images/LS_Chiller.svg";
import { ReactComponent as MaxximizerIcon } from "../../assets/images/Maxximizer.svg";
import { ReactComponent as Cp2ChillerIcon } from "../../assets/images/CP2_Chiller.svg";

import {
  getTanks,
  fetchDairy as fetchFarmUsers,
  getTankHPFandEVCId,
  getTankCHILLERandEVCId,
  getLastAgitatedValue,
  fetchGraph,
  fetchGraphChiller,
  fetchDairy,
  addTank,
  addNewChillerController,
  deleteTank,
  deleteChiller,
  updateTank,
  updateChiller,
  getTankById,
  getChillerById,
  getUser,
  getChillers,
  getTankAlerts,
  getChillerAlerts,
  fetchConfigs,
  dragDropTank,
  dragDropChiller,
  UpdateNoOfEVCsByID,
  getDeviceByID,
  getDevices,
  addTurckControl,
  getTurckControlById,
  getTurckTankandEVCId,
  fetchTurckGraph,
  deleteTurckControl,
  updateTurckControl,
  dragDropTurckControl,
  getTurckAlerts,
  getTurckLastAgitateStatus
} from "../../actions/actions";
import addTankIcon from "../../assets/images/add-tank-icon.svg";
import downCaret from "../../assets/images/date-box-caret.svg";
import reloadIcon from "../../assets/images/reload-icon.svg";
import Dropdown from "../../components/CustomDropdown";
import AddTankForm from "../../forms/TankForm/AddTankForm";
import AddDeviceControlForm from "../../forms/DeviceControlForm/AddDeviceControlForm";
import Toaster from "../../components/Toaster";
import volumeIcon from "../../assets/images/Volume.svg";
import thermometerIcon from "../../assets/images/thermometer.svg";
import pipelineTempIcon from "../../assets/images/pipeline-temp.svg";
import valveIcon from "../../assets/images/EVC-valve.svg";
import hspIcon from "../../assets/images/HSP-Pressure.svg";
import lspIcon from "../../assets/images/LSP-Pressure.svg";
import subCoolTempIcon from "../../assets/images/sub-cool-temp.svg";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import horizontalTank from "../../assets/images/tank-new.png";
import threeLineIcon from "../../assets/images/menu.svg";
import plusIcon from "../../assets/images/plusIcon.svg";
import Tank_Level from '../../assets/images/Tank_Level.svg';
import Tank_Temperature from '../../assets/images/Tank_Temperature.svg';
import PHE_Temperature_Sensor_1 from '../../assets/images/PHE_Temperature_Sensor_1.svg';
import PHE_Temperature_Sensor_2 from '../../assets/images/PHE_Temperature_Sensor_2.svg';
import Load_Out_Loop_Temperature from '../../assets/images/Load_Out_Loop_Temperature.svg';

import { Collapse } from "antd";

import {
  status_tank as statusTank,
  status_chiller as statusChiller,
  status_turck_tank as statusTurckTank,
  tank_states_timer as tankStatesTimer,
  TURCK_TANKS_STATUS_VALUES,
  turck_control_parlor_wash_status as parlorWashStates,
  turck_control_loadOutLoop_wash_status as loadOutLoopWashStates,
  REG_30104_CHILLER_ERROR_VALUES
} from "../../config";
import statusImgCooling from "../../assets/images/cool-icon.svg";
import {ReactComponent as StandByImg} from '../../assets/images/Standby.svg';
import {ReactComponent as AlertImg} from "../../assets/images/alert-icon.svg";
import coolingIcon from '../../assets/images/chiller_control_cool_icon.svg';
import waveIcon from '../../assets/images/chiller_control_wave_icon.svg';
import statusImgWashing from "../../assets/images/washing.png";
import standByIcon from "../../assets/images/Standby_Mode.png";
import settingsIcon from "../../assets/images/settings.png";
import diagnosticIcon from "../../assets/images/Diagnostics.png";
import LiveAlertsNotifications from "../../containers/Tank/LiveAlertsNotifications";
import AlertIcon from "../../assets/images/alert-icon.svg";
import fanSpeedIcon from "../../assets/images/Group 3861.svg";
import CondensingIcon from "../../assets/images/noun-temperature-3208741.svg";
import PromptModel from "../../components/PromptModel";
import ExportCsvForm from "../../forms/ExportCsvForm/ExportCsv";
import NoDataAvailable from "../../components/NoDataAvailable";
import NoTankDataAvailable from "../../components/NoTankDataAvailable";
import TankDevices from "../../components/TankDevices/TankDevices";
import Evc from "./EVC";
import Hpf from "./HPF";
import Chiller from "./CHILLER"
import { rootApi } from "../../axiosConfig";
import TurckDropdown from "../../components/TurckCustomDropdown";
import {
  sensors, ranges, secondsToHourMin,
  RAW_REGISTER_VIEW, PARSED_REGISTER_VIEW, SETTINGS_VIEW, GRAPH_VIEW, LIVE_ALERT_VIEW
} from "../../actions/passwordRegex";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getStringFromDecimalToBinary,
  getBinaryStringFromDecimal,
  findCompressorNumberByValue
} from "../../helpers";
import Turck from "./TURCK";
import stratusControl from "../../assets/images/stratusControl.png";
import nimbusControl from "../../assets/images/nimbusControl.png";

const GraphTypeWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  img {
    position: relative;
    width: auto;
    max-height: 100%;
    cursor: pointer;
  }
`;
const GraphControlDiv = styled.div`
  align-self: center;
  display: flex;
  justify-content: flex-end;
  flex: auto;
  div.pause {
    img {
      position: relative;
      top: -1px;
    }
  }
`;
const GraphTypeSubWrapper = styled.div`
  padding: 10px;
  margin-right: 10px;
  border-bottom: ${({ active }) => (active ? "3px solid #2c61a5" : "none")};
  background-color: ${({ active }) => (active ? "#f0f6ff" : "white")};
  display: inline-block;
`;

const TankStatusImg = styled(Image)`
  align-self: center;
  margin-right: 10px;
  height: 34px;
`;
let tempSensorStatusRegisterBits = [];
const TankListing = memo((props) => {
  const { t } = useTranslation();
  const user = getUser();
  const { deviceTypeGlobal, setDeviceTypeGlobal } = useDeviceContext();
  const [agitatedTime, setAgitatedTime] = useState(null);
  const [rawRegisters, setRawRegisters] = useState({});
  const [registerValues, setRegisterValues] = useState({});
  const [isLoadingRegisterValues, setIsLoadingRegisterValues] = useState(false);
  const configs = JSON.parse(localStorage.getItem("configs"));
  const [config, setConfig] = useState(configs);
  const [hpfGraphData, setHPFGraphData] = useState("");
  const [chillerGraphData, setChillerGraphData] = useState("");
  const [evcGraphData, setEVCGraphData] = useState(["", "", ""]);
  const [turckGraphData, setTurckGraphData] = useState("");
  const [liveData, setLiveData] = useState({
    temperature: "--",
    tankLevel : "--",
    temperatureTURCK: "--",
    pheSensor1 : "--",
    pheSensor2 : "--",
    loadOutLoop: "--",
    volume: "--",
    pipelineTemperature: "--",
    hspValue: "--",
    lspValue: "--",
    valvePos: "--",
    subCoolTemp: "--",
    saturatedSuctionTemp: "--",
    condensingTemp: "--",
    fanSpeed: "--",
    voltageInputStatus: "--",
    probeSensorStatus: "--",
    pump1: null,
    pump2: null,
    chillerOutletTemp: "--",
    chillerReturnTemp: "--",
    processOutletTemp: "--",
    processOutletTemp: "--",
    chillerFlow: "--",
    processFlow: "--",
    accumulatorTemperatureDifference: "--",
  });
  const [showAddTank, setShowAddTank] = useState(false);
  const [isTankRegistered, setIsTankRegistered] = useState(true);
  const [customDates, setCustomDates] = useState([
    new Date(ranges.Yesterday[0]).toISOString(),
    new Date().toISOString(),
  ]);
  const { dairyID, tankID } = useParams();
  const [tanks, setTanks] = useState([]);
  const [dairyName, setDairyName] = useState("");
  const [dairyAdmin, setDairyAdmin] = useState(undefined);
  const [dealerID, setDealerID] = useState("");
  const [currentEditTank, setCurrentEditTank] = useState({});
  const [isTankEdit, setIsTankEdit] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [deviceIdObj, setDeviceIdObj] = useState(null);
  const [reloadCount, setReloadCount] = useState(0);
  const [autoReloadInterval, setAutoReloadInterval] = useState(null);
  const [relIntVar, setRelIntVar] = useState(null);
  const [graphLoader, setGraphLoader] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(new Date());
  const [dateTimeIntVar, setDateTimeIntVar] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [tankStatusCode, setTankStatusCode] = useState("");
  const [turckTankStatusCode, setTurckTankStatusCode] = useState("");
  const [chillerStatusCode, setChillerStatusCode] = useState("");
  const [tankStatusObj, setTankStatusObj] = useState(null);
  const [tankDeleteAlert, setTankDeleteAlert] = useState(false);
  const [tankDeleteIndex, setTankDeleteIndex] = useState(null);
  const [tanksLoader, setTanksLoader] = useState(false);
  const [drag, setDrag] = useState(false);
  const [isShowingRawRegisterValuesLoading, setIsShowingRawRegisterValuesLoading] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [washData, setWashData] = useState([]);
  const [currentTankData, setCurrentTankData] = useState({});
  const [currentActiveView, setCurrentActiveView] = useState(GRAPH_VIEW);
  const [chillerTemperatureFailure, setChillerTemperatureFailures] = useState({
    chillerOutletTempFailure: 0,
    chillerReturnTempFailure: 0,
    processInletTempFailure: 0,
    processOutletTempFailure: 0,
  });

  /** Define Export csv state */
  const [show, setShow] = useState(false);
  const [exportDeviceIds, setExportDeviceIds] = useState({});
  const [tankType, setTankType] = useState(null);
  const [manualAnnotation, setManualAnnotation] = useState(false);
  const [configId, setConfigId] = useState(null);
  // const [redIndicator, setRedIndicator] = useState(false);

  const [unResolvedDevices, setUnResolvedDevices] = useState([]);
  const [tankAlert, setTankAlert] = useState([]);
  const [chillerAlertsList, setChillerAlertsList] = useState([]);

  const [equipmentModelNo, setEquipmentModelNo] = useState(null)

  const [currentEnableCompressorsData, setCurrentEnableCompressorsData] = useState([])
  const [currentlyAttachedEVC, setCurrentlyAttachedEVC] = useState([])
  const [turckTotalTanks, setTurckTotalTanks] = useState([]);
  const [turckcontrolData, setTurckControlData] = useState(null);
  const [turckControlObjectId, setTurckControlObjectId] = useState("");
  const [turckTankName, setTurckTankName] = useState("");
  const tankDeviceType = useMemo(() => {
    if (tankID && tanks.length) {
      const foundTank = tanks?.find(tank => tank.id === tankID);
      return foundTank?.deviceType;
    }
    return null;
  }, [tankID, tanks]);

  
  const getloadOutLoopStatus = (regVal1, regVal2, regVal3, regVal4) => {
		//regVal1 = 30018(Register Address), regVal2 = 30019, regVal3 = 30039, regVal1 = 30040,
		if (regVal1 === 0 && regVal3 === 0 && regVal4 === 0) {
			return t("STANDBY");
		} else if (regVal1 !== 0 && regVal3 === 0 && regVal4 === 0 && TURCK_TANKS_STATUS_VALUES.includes(regVal2)) {
			return t(loadOutLoopWashStates[regVal2]);
		} else if (regVal3 !== 0 && regVal1 === 0 && regVal4 === 0) {
			return `${t("tank")} ${regVal3} ${t("selected_for_load_out")}`;
		} else if (regVal4 !== 0 && regVal1 === 0 && regVal3 === 0) {
			return `${t("tank")} ${regVal4} ${t("selected_for_wash")}`;
		}
	};

  const getParlorControlStatus = (regVal1, regVal2) => {
		if (regVal1 === 0 && regVal2 === 0) {
			return t("STANDBY");
		} else if (regVal1 === 0 && regVal2 !== 0 && TURCK_TANKS_STATUS_VALUES.includes(regVal2)) {
			return t(parlorWashStates[regVal2]);
		} else if (regVal1 !== 0 && regVal2 === 0) {
			return t("MILK_MODE");
		}
	};
  
  /* Clear the DeviceType & DeviceTypeGlobal when component unmounts */
  useEffect(()=> {
    return () => {
      setDeviceType(null);
      setDeviceTypeGlobal(null);
    }
  },[]);
  
  /** Fetch config Api call */
  useEffect(() => {
    fetchConfigs()
      .then((response) => {
        if (response.status === 200) {
          const item = response.data.find((i) =>
            i.key === "MANUAL_ANNOTATION" ? i : response.data
          );
          setConfigId(item.id);
          const sensorItems = response.data;
          const sensor = sensorItems.filter((sensorData) => sensorData.unit !== "");
          localStorage.setItem("configs", JSON.stringify(sensor));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getGraphData = async () => {
    setGraphLoader(true);
    let devID = "";
        if (!deviceId) {
      if (!deviceIdObj) {
        const { data } = deviceTypeGlobal === "HPF" ?
                          await getTankHPFandEVCId(tankID)
                          : deviceTypeGlobal === "CHILLER"? await getTankCHILLERandEVCId(tankID): await getTurckTankandEVCId(tankID, turckControlObjectId);
        setExportDeviceIds(data);
        devID = data[deviceType]?.[0];
      } else {
        devID = deviceIdObj[deviceType]?.[0];
      }
    } else {
      devID = deviceId;
    }
    if (deviceType === "HPF" && deviceTypeGlobal === "HPF") {      
      try {
        const value = await fetchGraph(
          "Custom",
          deviceType,
          sensors.HPF,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }else if (deviceType === "TURCK_TANKS" && deviceTypeGlobal === "TURCK_TANKS") {
      try {
				setTurckGraphData(null);
				const value = await fetchTurckGraph(
					"Custom",
					deviceType,
					sensors.TURCK_TANKS,
					tankID,
					turckControlObjectId,
					devID,
					turckcontrolData?.controlSerialNumber,
					customDates[0],
					customDates[1]
				);
				setTurckGraphData(value.data.graphData);
				setWashData(value.data.washStatus);
				setGraphLoader(false);
				return;
			} catch (error) {
				if (isCancel(error)) {
					console.log("Request canceled:", error);
				} else {
					console.log(error);
					toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
				}
			}
    }
    else if (deviceType === "CHILLER") {
      try {
        const value = await fetchGraphChiller(
          "Custom",
          "CHILLER",
          sensors.CHILLER,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setChillerGraphData(value.data.graphData);
        // setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    for (let i = 0; i < sensors.EVC.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = deviceTypeGlobal === "HPF" ?
          await fetchGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
          : deviceTypeGlobal === 'CHILLER'? await fetchGraphChiller(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          ) : (deviceTypeGlobal === "TURCK_TANKS" && deviceType === "EVC") && await fetchTurckGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            turckControlObjectId,
            devID,
            turckcontrolData?.controlSerialNumber,
            customDates[0],
            customDates[1]
          );
        evcGraphData[i] = res?.data?.graphData;
      } catch (error) {
				if (isCancel(error)) {
					console.log("Request canceled:", error);
				} else {
					console.log(error);
					toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
				}
			}
    }
    setEVCGraphData([...evcGraphData]);
    setGraphLoader(false);
  };

  const getTankDetail = async () => {
    setGraphLoader(true);
    setAgitatedTime(null);
    setDeviceIdObj(null);
    let tempDeviceID;
    if(deviceTypeGlobal === "HPF"){
      const { data } = await getTankHPFandEVCId(tankID);
      setDeviceType("HPF")
      if (data.HPF?.length > 0) {
				const result = await getLastAgitatedValue(tankID, data.HPF[0]);
				setAgitatedTime(result?.data[0]?.time);
				setIsTankRegistered(true);
				let HPF = [];
				if (data.HPF) {
					HPF[0] = data.HPF[0];
				}
				let EVC = [];
				if (data.EVC) {
					EVC = data.EVC;
				}
        await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
				setDeviceIdObj({ HPF, EVC });
				setDeviceId(data.HPF[0]);
				tempDeviceID = data.HPF[0];
			} else {
        const { data: tankData } = await getTankById(tankID);
        if (tankData.registrationId) {
          setIsTankRegistered(false);
        } else {
          tempDeviceID = tankData.id;
          setIsTankRegistered(true);
        }
      } 
      try {
        const value = await fetchGraph(
          "Custom",
          "HPF",
          sensors.HPF,
          tankID,
          tempDeviceID,
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    } else if(deviceTypeGlobal === "CHILLER") {
        const { data } = await getTankCHILLERandEVCId(tankID);
        setDeviceType("CHILLER");
        if (data.CHILLER.length > 0) {
          setIsTankRegistered(true);
          let CHILLER = [];
          if (data.CHILLER) {
            CHILLER[0] = data.CHILLER[0];
          }
          let EVC = [];
          if (data.EVC) {
            EVC = data.EVC;
          }
          await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
          setDeviceIdObj({ CHILLER, EVC });
          setDeviceId(data.CHILLER[0]);
          tempDeviceID = data.CHILLER[0];
        } else {
          const { data: tankData } = await getChillerById(tankID);
          setDeviceId(tankData.deviceSerialNumber)
          if (tankData.deviceRegistrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = tankData.id;
            setIsTankRegistered(true);
          }
        }
        try {
          const value = await fetchGraphChiller(
            "Custom",
            "CHILLER",
            sensors.CHILLER,
            tankID,
            tempDeviceID,
            customDates[0],
            customDates[1]
          );
          setChillerGraphData(value.data.graphData);
        } catch (error) {
          console.log(error);
          toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
        }
    } else if (deviceTypeGlobal === "TURCK_TANKS" && turckControlObjectId) {
        const { data } = await getTurckTankandEVCId(tankID, turckControlObjectId);
        setDeviceType("TURCK_TANKS");
        if(data.TURCK_TANKS?.length > 0) { 
          const result = await getTurckLastAgitateStatus(tankID);
          setAgitatedTime(result?.data[0]?.time);
          let currentControl;
        
          if (tanks?.length === 0) {
            const currentTanktype = await getTankById(tankID);
            currentControl = await getTurckControlById(currentTanktype?.data?.type?.id);
          }
          let totalTurck = (tanks?.length === 0) ?  currentControl?.data.tanks : turckTotalTanks;
          
          totalTurck?.length > 0 && totalTurck.map(async(turckTank,index) => {
            if (turckTank.id === tankID) {
 
              setIsTankRegistered(true);
              let TURCK_TANKS = [];
            if (data?.TURCK_TANKS?.length) {
              // TURCK_TANKS[index] = data.TURCK_TANKS[index]
                TURCK_TANKS = data.TURCK_TANKS;
              }
              let EVC = [];
              if (data.EVC) {
                EVC = data.EVC;
              }
              await UpdateNoOfEVCsByID(tankID, { noOfEVCs: EVC.length, deviceType: deviceTypeGlobal })
              setDeviceIdObj({ TURCK_TANKS, EVC });
              setDeviceId(data?.TURCK_TANKS[0]);
            }
            tempDeviceID = data?.TURCK_TANKS[0];
          }) 
        } else {
          const { data } = await getTankById(tankID);
          if (data.registrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = data.id;
            setIsTankRegistered(true);
          }
        }
        try {
					const value = await fetchTurckGraph(
						"Custom",
						"TURCK_TANKS",
						sensors.TURCK_TANKS,
						tankID,
						turckControlObjectId,
						tempDeviceID,
						turckcontrolData?.controlSerialNumber,
						customDates[0],
						customDates[1]
					);
					setTurckGraphData(value.data.graphData);
					setWashData(value.data.washStatus);
				} catch (error) {
					if (isCancel(error)) {
						console.log("Request canceled:", error);
					} else {
						console.log(error);
						toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
					}
				}
      }
    setGraphLoader(false);
  };

  const onBeforeDragStart = () => {
    setDrag(true);
  };

  /** Get index when drag and drop tank card */
  const getIndex = (list, sourceIndex, destIndex) => {
    if (destIndex === 0) {
      return "0" + list[destIndex].index;
    } else if (destIndex === list.length - 1) {
      return list[destIndex].index + "z";
    } else {
      return sourceIndex < destIndex
        ? list[destIndex].index + list[destIndex + 1].index
        : list[destIndex - 1].index + list[destIndex].index;
    }
  };

  /** Index wise sort Item */
  function sortItems(obj) {
    return obj.sort((item1, item2) => {
      let tmpItem1 = item1.index;
      let tmpItem2 = item2.index;
      if (tmpItem1 < tmpItem2) {
        return -1;
      }
      if (tmpItem1 > tmpItem2) {
        return 1;
      }
      return 0;
    });
  }

  /** Ondrag function call */
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    // Added condition that if we are dropping out side of droppable context, we can not do
    if (!destination) {
      setDrag(false);
      return;
    }
    let index = getIndex(tanks, source.index, destination.index);
    const tempObj = tanks[source.index];
    if(tempObj && tempObj.id) {
      // based on selected device calling further apis
      let item = tempObj.deviceType ? { deviceName: tempObj.deviceName, locationName: tempObj.locationName.id, index: index } : tempObj.controlType? { controlName: tempObj.controlName, dairy: tempObj.dairy.id, index: index } : { tankName: tempObj.tankName, dairy: tempObj.dairy.id, index: index }
      try {
      const result = tempObj.HPFSerialNumber ? await dragDropTank(draggableId, item) : tempObj.controlType? await dragDropTurckControl(draggableId, item) : await dragDropChiller(draggableId, item)
      if (result.status === 200) {
        getDevices(dairyID).then((data) => {
          let tempStaticItems = sortItems(data.data);
          setTanks(tempStaticItems);
          setTanksLoader(false);
        });
      }
        } catch(err) {
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        };
      setDrag(false);
      if (destination.index === source.index) {
        return;
      }
      const newTankOrder = Array.from(tanks);
      const [replaceItem] = newTankOrder.splice(source.index, 1);
      newTankOrder.splice(destination.index, 0, replaceItem);
      setTanks([...newTankOrder]);
    }
  };

  /** Get index on post tank data function */
  const getIndexOnCreate = (listOfTanks) => {
    return listOfTanks.length === 0
      ? "a"
      : listOfTanks[listOfTanks.length - 1].index + "z";
  };

  /** Add new Tank functionality */
  const addNewTank = async (data) => {
    const { history, location } = props;
    let index = getIndexOnCreate(tanks);
    let temp = { ...data, index: index, dairy: dairyID, locationName: dairyID };
    try {
      const res = data.deviceType === "HiPerForm_Plus" ? await addTank(temp) : data.deviceType==='Telos_Chiller' ? await addNewChillerController(temp) : await addTurckControl(temp);
      if (((data.deviceType === "HiPerForm_Plus" || data.controlType ) && dairyID === res.data.dairy.id) || (data.deviceType === "Telos_Chiller" && dairyID === res.data.locationName.id)) {
        if (tanks.length === 0) {
          let url = location.pathname.split("/");
          url.splice();
          url.push(res.data.id);
          url = url.join("/");
          history.replace(url);
        }
        setTanks([...tanks, res.data]);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={t("device_added")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
        if (tanks.length === 0) {
          let url = location.pathname.split("/");
          url.splice();
          url.push(res.data.id);
          url = url.join("/");
          history.replace(url);
        }
      }
    } catch (err) {
      console.log(err);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Edit Tank functionality */
  const editTank = async (id, data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = data.deviceType === "HiPerForm_Plus" ? await updateTank(id, temp) : ( data.controlType ) ? await updateTurckControl(id, temp) : await updateChiller(id, temp);
      if (res.status === 200) {
        const response = await getDevices(dairyID);
        const tempStaticItems = sortItems(response.data);
        setTanks(tempStaticItems);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={t("device_updated")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Remove tank functionality  */
  const removeTank = async (index) => {
    const { location, history } = props;
    const currentTankId = tanks[index].id;
    try {
      tanks[index]?.deviceType ? await deleteChiller(currentTankId) : ( tanks[index]?.tanks && tanks[index]?.controlType ) ? await deleteTurckControl(currentTankId) : await deleteTank(currentTankId); 
      const res = await getDevices(dairyID);
      setTankDeleteAlert(false);
      let tempStaticItems = sortItems(res.data);
            setTanks(tempStaticItems);
      if (tempStaticItems.length === 0) {
        let url = location.pathname.split("/");
        url.splice();
        url.length--;
        history.replace(url.join("/"));
      } else {
        setCurrentTankData({})
        setChillerGraphData("")
        setHPFGraphData("")
        setEVCGraphData(["", "", ""])
        setTurckGraphData("");
        setDeviceIdObj(null)
        setLiveData({
          temperature: "--",
          volume: "--",
          pipelineTemperature: "--",
          hspValue: "--",
          lspValue: "--",
          valvePos: "--",
          subCoolTemp: "--",
          saturatedSuctionTemp: "--",
          condensingTemp: "--",
          fanSpeed: "--",
          voltageInputStatus: "--",
          probeSensorStatus: "--",
          pump1: null,
          pump2: null,
          chillerFlow: "--",
          processFlow: "--",
          accumulatorTemperatureDifference: "--",
        })
      }
      toast.dark(
        <Toaster
          className="text-capitalize"
          icon="notify"
          message={t("device_deleted")}
        />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const handleDateCallback = (start, end, label) => {
    customDates[0] = start.toISOString();
    customDates[1] = end.toISOString();
    setCustomDates([...customDates]);
  };

  const tick = () => {
    // setCurrentDatetime(new Date());
    customDates[0] = new Date(ranges.Yesterday[0]).toISOString();
    customDates[1] = new Date().toISOString();
    setCurrentDatetime([...customDates]);
  };

  const setStatusTank = () => {
    let image;
    let textOne;
    let textTwo;
    let textThree;
    let color = "black";
    let hpfFan = "0";
    let mainTimer;
    let timerVal;
    let pump1;
    let pump2;
    let parlorWashMode ='';
    let loadOutLoopStatus = '';
    if (
      currentTankData &&
      currentTankData["CHILLER"] &&
      Object.keys(currentTankData.CHILLER).length
    ) {
      pump1 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        12
      );
      pump2 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        14
      );
    }
    if(deviceTypeGlobal === "HPF")
      if (tankStatusCode !== "") {
        if (statusTank[tankStatusCode].indexOf("Washing") > -1) {
          image = <TankStatusImg src={statusImgWashing} fluid />;
          mainTimer = (
            <span className="timerTag">
              (
              {secondsToHourMin(
                currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
                  .HPF_INPUT_REG_WASH_TIME_REMAINING
              )}
              )
            </span>
          );
        } else if (statusTank[tankStatusCode].indexOf("Cooling") > -1) {
          textOne = t("cooling");
          image = <TankStatusImg src={statusImgCooling} fluid />
          color = "#2c61a5";
          const minToSec =
            currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
              .HPF_INPUT_REG_COOL_CYCLE_TIMER * 60;

          mainTimer =
            minToSec !== "" && tankStatusCode > 2002 ? (
              <span className="timerTag">
                {minToSec === "" ? "" : secondsToHourMin(minToSec)}
              </span>
            ) : null;
      } else if (statusTank[tankStatusCode].indexOf("Standby") > -1) {
          image = <TankStatusImg src={standByIcon} fluid />
          
        } else if (
          statusTank[tankStatusCode].indexOf("Editing Parameters") > -1 ||
          statusTank[tankStatusCode].indexOf("Setup") > -1
        ) {
          image = <TankStatusImg src={settingsIcon} fluid />
        } else if (statusTank[tankStatusCode] === "Diagnostic") {
          image = <TankStatusImg src={diagnosticIcon} fluid />
        }
        hpfFan =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
            .AGITATOR_MOTOR_RELAY;

        textOne = t(statusTank[tankStatusCode].split(" - ")[0]);
        textTwo = t(statusTank[tankStatusCode].split(" - ")[1]);
        textThree = t(statusTank[tankStatusCode].split(" - ")[2]);
        if (currentTankData !== null && tankStatusCode !== "") {
          let timerName = tankStatesTimer[tankStatusCode.toString()];
          timerVal =
            currentTankData.HPF[Object.keys(currentTankData.HPF)[0]][timerName];
        }
      } else {
        mainTimer = "";
      }
    else if(deviceTypeGlobal === "CHILLER"){
      if(chillerStatusCode !== "") {
        color = REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? 'red' : 'black'
        textOne = t(statusChiller[chillerStatusCode]);

        if(statusChiller[chillerStatusCode] === 'CHILLER_IDLE'  || statusChiller[chillerStatusCode] === 'CHILLING') {
          image = <TankStatusImg src={coolingIcon} fluid  className="mr-0"/>
        }
        else if (statusChiller[chillerStatusCode] === 'PUMP_IDLE' || statusChiller[chillerStatusCode] === 'PUMPING' ) {
          image = <TankStatusImg src={waveIcon} fluid className="mr-0"/>
        }
        else if (statusChiller[chillerStatusCode] === 'ALARM'){
          image = <AlertImg className={`${REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? "fill-red" : ""} + mr_8`}/>
        }
        else if (statusChiller[chillerStatusCode] === 'TECH_OVERRIDE'){
          image = <TankStatusImg src={diagnosticIcon} fluid className="status__icon--height"/>
        }
        else {
          image = <StandByImg className={`${REG_30104_CHILLER_ERROR_VALUES?.includes(chillerStatusCode.toString()) ? "fill-red" : "" } + mr_4`} />;
        }
      }
    }
    if (deviceTypeGlobal === 'TURCK_TANKS') {
      if (turckTankStatusCode && turckTankStatusCode !== '' && TURCK_TANKS_STATUS_VALUES.includes(turckTankStatusCode)) {
        if (statusTurckTank[turckTankStatusCode].indexOf('Washing') > -1) {
          image = <TankStatusImg src={statusImgWashing} fluid />;
          mainTimer = (
            <span className="timerTag">
              ({secondsToHourMin(currentTankData?.TURCK_TANKS?.[tankID]?.WASH_TIME_REMAINING)})
            </span>
          );
        } else if (statusTurckTank[turckTankStatusCode].indexOf('COOL') > -1) {
          textOne = t('cooling');
          image = <TankStatusImg src={statusImgCooling} fluid />;
          color = '#2c61a5'; 
          const minToSec = currentTankData?.TURCK_TANKS?.[tankID]?.COOL_CYCLE_TIMER * 60;

          mainTimer = minToSec !== '' && turckTankStatusCode > 2002 ? <span className="timerTag">{minToSec === '' ? '' : secondsToHourMin(minToSec)}</span> : null;
        } else if (statusTurckTank[turckTankStatusCode].indexOf('STANDBY') > -1) {
          image = <TankStatusImg src={standByIcon} fluid /> 
        }
        hpfFan = currentTankData?.TURCK_TANKS?.[tankID]?.AGITATOR_OUTPUT.toString();
        textOne = t(statusTurckTank[turckTankStatusCode].split(' - ')[0]);
        textTwo = t(statusTurckTank[turckTankStatusCode].split(' - ')[1]);
        textThree = t(statusTurckTank[turckTankStatusCode].split(' - ')[2]);
        if (currentTankData !== null && turckTankStatusCode !== '') {
          let timerName = tankStatesTimer[turckTankStatusCode.toString()];
          timerVal = currentTankData?.TURCK_TANKS?.[tankID]?.[timerName];
        }
      } else {
        mainTimer = '';
      }
      if(turckcontrolData?.tanks?.length) {
        setTurckTankName(turckcontrolData?.tanks?.find((tank) => tank.id === tankID)?.tankName);
      }
      if (currentTankData && currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]) {
				parlorWashMode = getParlorControlStatus(
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_PARLOR_MILK_MODE"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_PARLOR_WASH_MODE"]
				);
				loadOutLoopStatus = getloadOutLoopStatus(
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_LOAD_OUT_LOOP_WASH_SELECTED"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_LOAD_OUT_LOOP_WASHING"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_TANK_SELECTED_FOR_LOADOUT"],
					currentTankData?.TURCK?.[turckcontrolData?.controlSerialNumber]?.["TURCK_TANK_SELECTED_FOR_WASH"]
				);
			}
    }

    const temp = {
      image,
      textOne,
      textTwo,
      textThree,
      color,
      hpfFan,
      mainTimer,
      timerVal,
      pump1,
      pump2,
      parlorWashMode,
      loadOutLoopStatus,
      turckTankName
    };
    setTankStatusObj(temp);
  };

  const getAlerts = async () => {
    try {
      const startTime = Date.parse(customDates[0]);
      const endTime = Date.parse(customDates[1]);
      const res = deviceTypeGlobal === "HPF" ? await getTankAlerts(tankID, startTime, endTime) : deviceTypeGlobal === "TURCK_TANKS" ? await getTurckAlerts(tankID, turckControlObjectId, startTime, endTime) :await getChillerAlerts(tankID, startTime, endTime);
      setAlerts(res.data);
    } catch (error) {
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const getDevice = async(tankID, tanks) => {
    if(tankID && tanks?.length) {
      const deviceObj = tanks.find(tank => tank.id === tankID);
      if(deviceObj && deviceObj.type) {
        const {data: controlData} = await getTurckControlById(deviceObj.type);
        setTurckTotalTanks(controlData?.tanks);
        setTurckControlData(controlData);
        setTurckControlObjectId(deviceObj.type);
      }
      if(deviceObj && Object.keys(deviceObj).length) {
        if(deviceObj.deviceType){
          setDeviceId(deviceObj.deviceSerialNumber)
          setDeviceTypeGlobal("CHILLER")
          setDeviceType("CHILLER")
          setCurrentTankData({})
          setTankType(null)
          setEquipmentModelNo(deviceObj.equipmentModelNo)
          setTurckControlObjectId(null);
        } else if(deviceObj.type){
          setDeviceId(tankID)
          setDeviceTypeGlobal("TURCK_TANKS")
          setDeviceType("TURCK_TANKS")
          setTurckGraphData("")
          setCurrentTankData({})
          setTankType(null)
          setEquipmentModelNo(null)
        } else {
          setDeviceId(deviceObj.HPFSerialNumber)
          setDeviceTypeGlobal("HPF")
          setDeviceType("HPF")
          setCurrentTankData({})
          setTankType(null)
          setEquipmentModelNo(null)
          setTurckControlObjectId(null);
        }
      }
    }
  }

  const ChillerDeviceIcon = useMemo(() => {
    switch (equipmentModelNo) {
      case 'None':
        return FallingFilmIcon;
      case 'HPC5':
        return HiperChill50Icon;
      case 'LS':
        return LsChillerIcon;
      case 'LSA':
        return LsChillerIcon;
      case 'CP2':
        return Cp2ChillerIcon;
      case 'HPC40':
        return MaxximizerIcon;
      default:
        return BakeryChillerIcon;
    }
  }, [equipmentModelNo]);

  useEffect(() => {
    const { location, history } = props;
    setTanksLoader(true);
    getDevices(dairyID)
      .then((data) => {
        let tempStaticItems = sortItems(data.data);
        setTanks(tempStaticItems);
        setTanksLoader(false);
        if (data.data.length !== 0 && !tankID) {
          let url = location.pathname.split("/");
          url.splice();
          if(data?.data[0]?.controlType && data?.data[0]?.tanks) {
            url.push(data.data[0]?.tanks[0]?.id)
          } else {
            url.push(data.data[0]?.id);
          }
          if (data.data[0]?.id) {
            url = url.join("/");
          }
          history.replace(url);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    fetchDairy(dairyID)
      .then((data) => {
        setDairyName(data.data.name);
        setDealerID(data.data.createdBy?.id)
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong1")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    getDevice(tankID, tanks)
  }, [tanks, tankID])

  useEffect(() => {
    if (deviceTypeGlobal) {
      getTankDetail();
      getAlerts();
      fetchFarmUsers(dairyID)
      .then((response) => {
        const result = response?.data?.farmUsers.some(item => item.isActive === true && item.role === "DairyAdmin");
        setDairyAdmin(result);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      });
    }
  }, [deviceTypeGlobal, tankID, turckControlObjectId, turckTotalTanks]);

  useEffect(() => {
    if (deviceTypeGlobal === "CHILLER") {
      rootApi
        .get(
          `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"device":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          setChillerAlertsList(response.data);
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster icon="error" message={t("live_alert_history_error")} />
          );
        });
    }
  }, [deviceTypeGlobal]);

  useEffect(() => {
    if(deviceTypeGlobal) {
      setLiveData({
        temperature: "--",
        tankLevel : "--",
        temperatureTURCK: "--",
        pheSensor1 : "--",
        pheSensor2 : "--",
        loadOutLoop: "--",
        volume: "--",
        pipelineTemperature: "--",
        hspValue: "--",
        lspValue: "--",
        valvePos: "--",
        subCoolTemp: "--",
        saturatedSuctionTemp: "--",
        condensingTemp: "--",
        fanSpeed: "--",
        voltageInputStatus: "--",
        probeSensorStatus: "--",
        pump1: null,
        pump2: null,
        chillerFlow: "--",
        processFlow: "--",
        accumulatorTemperatureDifference: "--",
      });
      setManualAnnotation(false);
      setShowAnnotation(false);
      setTankStatusObj(null);
           io.socket.on(`influxData:${deviceTypeGlobal === 'TURCK_TANKS'? turckControlObjectId: tankID}`, (data) => {
        const statusNumber = deviceTypeGlobal === "HPF"
          ? data?.HPF?.[Object.keys(data.HPF)[0]].HPF_INPUT_REG_MAIN_STATE
          : deviceTypeGlobal=== 'CHILLER'? data?.CHILLER?.[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE : [];
        let temp;
        let chillerTempFailures = {
          chillerOutletTempFailure: 0,
          chillerReturnTempFailure: 0,
          processInletTempFailure: 0,
          processOutletTempFailure: 0,
        };
        if (deviceType === "CHILLER" && data?.CHILLER) {
          const temperature =
            data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1;

          const temperatureSensor = Number(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_1) || 0;

          const pump1 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 12);
          const pump2 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 14);
          const voltageInputStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS, 0);
          const probeSensorStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS, 0);
          const chillerOutletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1
          const chillerReturnTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_2
          const processInletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_4
          const processOutletTemp = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_3
          const shouldDisplayProcessFlow = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_PROCESS_FLOW_METER_PRESENT_PARAM == "1"
          const shouldDisplayChillerFlow = data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_CHILLER_FLOW_METER_PRESENT_PARAM == "1"
          const processFlow = shouldDisplayProcessFlow ? data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ANALOG_1_UA : "--"
          const chillerFlow = shouldDisplayChillerFlow ? data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_ANALOG_2_UA : "--"
          chillerTempFailures = {
            chillerOutletTempFailure: (temperatureSensor >> 3) & 1,
            chillerReturnTempFailure: (temperatureSensor >> 4) & 1,
            processInletTempFailure: (temperatureSensor >> 6) & 1,
            processOutletTempFailure: (temperatureSensor >> 5) & 1,
          };
          
          temp = {
            temperature,
            pump1,
            pump2,
            voltageInputStatus,
            probeSensorStatus,
            chillerOutletTemp,
            chillerReturnTemp,
            processInletTemp,
            processOutletTemp,
            chillerFlow,
            processFlow,
          };
        }
        if (deviceType === "HPF" && data?.HPF) {
          const temperature =
            data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP1;
          const volume =
            data.HPF[Object.keys(data.HPF)[0]]
              .HPF_INPUT_REG_VOLUME_4TO20MA_READING ||
              Number(
                data.HPF[Object.keys(data.HPF)[0]]
                  .HPF_INPUT_REG_VOLUME_4TO20MA_READING
              ) === 0
              ? data.HPF[Object.keys(data.HPF)[0]]
                .HPF_INPUT_REG_VOLUME_4TO20MA_READING
              : "--";
          const pipelineTemperature =
            data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP2;
          temp = {
            temperature,
            volume,
            pipelineTemperature,
          };
        }
        if (deviceType === "EVC" && data?.EVC) {
          if(deviceTypeGlobal === "TURCK_TANKS") {
            statusNumber.push(data?.TURCK_TANKS[tankID]?.TANK_STATE)
          }
          const hspValue =
            data?.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_HIGH_SIDE_PRESSURE;

          const lspValue =
            data?.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_LOW_SIDE_PRESSURE;

          const valvePos =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_VALVE_POS;

          const subCoolTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_SUBCOOL_TEMP;

          const saturatedSuctionTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_LO_SAT_TEMP;

          const condensingTemp =
            data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_HI_SAT_TEMP;

          const fanSpeed =
            Math.floor((data.EVC[deviceId || Object.keys(data.EVC)[0]]
              ?.EVC_INPUT_REG_FAN_OUTPUT / 4096) * 100);

          const accumulatorTemperatureDifference =
            Math.abs(data.EVC[deviceId || Object.keys(data.EVC)[0]]?.EVC_INPUT_REG_TEMP_1 -
              data.EVC[deviceId || Object.keys(data.EVC)[0]]?.EVC_INPUT_REG_TEMP_2);

          temp = {
            subCoolTemp,
            accumulatorTemperatureDifference,
            hspValue,
            condensingTemp,
            lspValue,
            saturatedSuctionTemp,
            valvePos,
            fanSpeed
          };
        }
        if (deviceType === "TURCK_TANKS" && data?.TURCK_TANKS) {
          let pheSensor1;
          let pheSensor2;
          let loadOutLoop;
          let temperatureTurck;
          let tankLevel;
          turckTotalTanks?.length > 0 && (
            turckTotalTanks.map((turckTank,index) => {
              if (turckTank.id === tankID) {
                statusNumber.push(data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_STATE);
  
                pheSensor1 = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_PHE_TEMPERATURE_SENSOR_1;
                pheSensor2 = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_PHE_TEMPERATURE_SENSOR_2;
                loadOutLoop = data?.TURCK[Object.keys(data?.TURCK)[0]]?.TURCK_LOAD_OUT_LOOP_TEMPERATURE;
                temperatureTurck = data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_TEMPERATURE;
                tankLevel = data?.TURCK_TANKS[Object.keys(data?.TURCK_TANKS)[index]]?.TANK_LEVEL;
                setTankType(data?.TURCK_TANKS?.[Object.keys(data?.TURCK_TANKS)?.[index]]?.['SILO/MILK_COOLER_SELECTION'])
              }
            })
          )
            
          temp = {
            pheSensor1,
            pheSensor2,
            loadOutLoop,
            tankLevel,
            temperatureTurck,
          };
        }  
        setCurrentTankData(data);
        deviceTypeGlobal === "HPF" ? setTankStatusCode(statusNumber) : deviceTypeGlobal === "TURCK_TANKS"? setTurckTankStatusCode(statusNumber[0]): setChillerStatusCode(statusNumber);
        setLiveData({ ...liveData, ...temp });
        if(deviceType !== "TURCK_TANKS" && deviceType !== "EVC") {
          setTankType(data.tankType);
        }
        if(JSON.stringify(chillerTempFailures) !== JSON.stringify(chillerTemperatureFailure)){
          setChillerTemperatureFailures(chillerTempFailures)
        }
      });
      io.socket.on();
      if(deviceTypeGlobal === "HPF") {
        io.socket.on(`alerthistory:manual:${dairyID}`, (data) => {          
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceID];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.tank === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.tank === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      } else  if(deviceTypeGlobal === "TURCK_TANKS") {
        io.socket.on(`turck-alerthistory:manual:${dairyID}`, (data) => {
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceID];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.tank === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.tank === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      } else if(deviceTypeGlobal === "CHILLER") {
        io.socket.on(`chiller-alerthistory:manual:${dairyID}`, (data) => {
          /** Display live alerts in devices */
          let resolvedDevices = [];
          if (data.event === "update") {
            resolvedDevices = data.data.map((i) => {
              if (i.isResolved) {
                return i.deviceID;
              } else {
                return "";
              }
            });
            resolvedDevices = resolvedDevices.filter(Boolean);
            setUnResolvedDevices((items) => {
              let tmpItems = items.filter(function (el, index, arr) {
                return index == arr.indexOf(el);
              });
              return tmpItems.filter((i) => !resolvedDevices.includes(i));
            });
          } else {
            setUnResolvedDevices((items) => {
              return [...items, data.data.deviceSerialNumber];
            });
          }
          /** Graph reloaded when alerts display */
          let alerts = [];
          if (data.event === "update") {
            alerts = data.data.filter((el) => el.device === tankID);
          } else {
            alerts.push(data.data);
          }
          if (alerts.length && data.data.device === tankID) {
            setAlerts((prev) => [...prev, ...alerts]);
            getGraphData();
          }
          /** function call when socket connection generate */
          updateTankAlert();
        });
      }
      return () => {
        io.socket.removeAllListeners();
      };
    }
  }, [deviceTypeGlobal, deviceType, deviceId, turckControlObjectId]);

  // Get raw register live value when raw registers tab selected
  useEffect(() => {
    setIsShowingRawRegisterValuesLoading(true)
    if (currentActiveView === RAW_REGISTER_VIEW || currentActiveView === LIVE_ALERT_VIEW) {
      const COMPRESSOR_ENABLE_REGISTERS = [40129, 40147, 40165, 40183, 40203, 40221]
      io.socket.on(`rawRegister:${deviceTypeGlobal === 'TURCK_TANKS'? turckControlObjectId : tankID}`, (data) => {
        if(deviceTypeGlobal === "CHILLER") {
          const currentEnableCompressors = [];
          for (let i = 0; i < 6; i++) {
            const isCompressorEnable = Number(data?.CHILLER?.[COMPRESSOR_ENABLE_REGISTERS[i]]);
            if(isCompressorEnable) {
              currentEnableCompressors.push({ evcDeviceData: data.EVC[i], compressorIndex: i });
            }
          }
          setCurrentEnableCompressorsData(currentEnableCompressors);
          setCurrentlyAttachedEVC([]);
        }
        if( deviceTypeGlobal === "TURCK_TANKS" ) {
          if(!isEmpty(data?.EVC)){
            const filteredEVCArray = Object.entries(data?.EVC)?.filter(([key, value]) => deviceIdObj?.EVC?.includes(key))?.map(([key, value]) => value);
            setCurrentlyAttachedEVC(filteredEVCArray);
          }
          setCurrentEnableCompressorsData([]);
        }
        setRawRegisters(data);
        setIsShowingRawRegisterValuesLoading(false)
      });
    }
    // Destroy socket connection on component unmount
    return () => {
      io.socket.off(`rawRegister:${tankID}`);
    };
  }, [currentActiveView]);


  // Get parsed register live value when parsed registers tab selected
  useEffect(() => {
    if (currentActiveView === PARSED_REGISTER_VIEW) {

      // establish socket connection
      setIsLoadingRegisterValues(true);
      io.socket.on(`influxData:${deviceTypeGlobal === 'TURCK_TANKS'? turckControlObjectId : tankID}`, (data) => {
        let temp ={};
        if(deviceTypeGlobal === 'TURCK_TANKS') {
          temp.TURCK = {...data?.TURCK};
          temp.TURCK_TANKS = {[tankID]: data?.TURCK_TANKS[tankID]};
          temp.tankTypeName = data?.TURCK_TANKS?.[tankID]?.['SILO/MILK_COOLER_SELECTION'] === 1 ? "Vertical" : "";
          if(!isEmpty(data?.EVC)) {
            const filteredEVCObjects = Object.fromEntries(Object.entries(data?.EVC)?.filter(([key, value]) => deviceIdObj?.EVC?.includes(key)));
            temp.EVC = filteredEVCObjects;
          }
        } else {
          temp = {...data};
        }
        setRegisterValues(temp);
        setIsLoadingRegisterValues(false);
      });
    }
  }, [currentActiveView]);

  /** Show red Indicator in tank card and alert icon */
  const updateTankAlert = useCallback(() => {
    if(deviceTypeGlobal === "HPF") {
      io.socket.get(`/api/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.tank?.id) : [];
          setTankAlert(tankIds);
        }
      );
    } else if(deviceTypeGlobal === "TURCK_TANKS") {
      io.socket.get(`/api/turckalerthistories?where={"and":[{"turckAlert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS_TURCK"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.tank?.id || i?.control?.id) : [];
          setTankAlert(tankIds);
        }
      );
    } else if(deviceTypeGlobal === "CHILLER"){
      io.socket.get(`/api/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
      (data) => {
          let tankIds = data && data.length ? data.map((i) => i.device?.id) : [];
          setTankAlert(tankIds);
          setChillerAlertsList(data);
        }
      );
    }
  });

  useEffect(updateTankAlert, [deviceTypeGlobal]);
  
  useEffect(() => {
    if(
      (deviceTypeGlobal === "HPF" && currentTankData && currentTankData.HPF) ||
      (deviceTypeGlobal === "CHILLER" && currentTankData && currentTankData.CHILLER) ||
      (deviceTypeGlobal === "TURCK_TANKS" && currentTankData && currentTankData.TURCK && currentTankData.TURCK_TANKS)
      ) {
      setStatusTank();
    }
  }, [deviceTypeGlobal, tankStatusCode, chillerStatusCode,turckTankStatusCode, currentTankData]);

  useEffect(() => {
    if(deviceTypeGlobal && deviceType){
      // TODO: Uncomment and use the below code once the tankNumber feature is implemented on the backend.
      // if(deviceTypeGlobal === 'TURCK_TANKS' && !controlID) {
      //   getTurckControl();
      // }
      getGraphData();
      setTankStatusObj(null);
    }
  }, [deviceTypeGlobal, deviceType, deviceId]);

  useEffect(() => {
    if (reloadCount !== 0) {
      getGraphData();
      getAlerts();
    }
  }, [reloadCount]);

  /** Interval button in graph  */
  useEffect(() => {
    if (autoReloadInterval) {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
      setRelIntVar(
        setInterval(() => {
          tick();
          getGraphData();
          getAlerts();
        }, autoReloadInterval * 1000)
      );
    } else if (relIntVar) {
      clearInterval(relIntVar);
    }
    return () => {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
    };
  }, [autoReloadInterval]);

  useEffect(() => {
    setDateTimeIntVar(setInterval(tick, 10000));
    return () => {
      clearInterval(dateTimeIntVar);
    };
  }, []);

if(!isEmpty(currentTankData?.CHILLER) && !isEmpty(deviceIdObj?.CHILLER)){
  tempSensorStatusRegisterBits = getBinaryStringFromDecimal(currentTankData?.CHILLER[deviceIdObj?.CHILLER[0]]?.CHILLER_CONTROLLER_INPUT_REG_TEMP_SENSOR_STATUS,4);
}
  
  const liveDataHelper = {
    CHILLER: [
      {
        icon: <ThermometerIcon className="fill-blue" />,
        name: t("chiller_outlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.chillerOutletTemp),undefined,chillerTemperatureFailure.chillerOutletTempFailure, tempSensorStatusRegisterBits[0])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.chillerOutletTemp),undefined,chillerTemperatureFailure.chillerOutletTempFailure, tempSensorStatusRegisterBits[0])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-green" />,
        name: t("chiller_return_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.chillerReturnTemp),undefined,chillerTemperatureFailure.chillerReturnTempFailure, tempSensorStatusRegisterBits[1])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.chillerReturnTemp),undefined,chillerTemperatureFailure.chillerReturnTempFailure, tempSensorStatusRegisterBits[1])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-purple" />,
        name: t("process_outlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.processOutletTemp),undefined,chillerTemperatureFailure.processOutletTempFailure, tempSensorStatusRegisterBits[2])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.processOutletTemp),undefined,chillerTemperatureFailure.processOutletTempFailure, tempSensorStatusRegisterBits[2])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <ThermometerIcon className="fill-yellow" />,
        name: t("process_inlet_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.processInletTemp),undefined,chillerTemperatureFailure.processInletTempFailure, tempSensorStatusRegisterBits[3])
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.processInletTemp),undefined,chillerTemperatureFailure.processInletTempFailure, tempSensorStatusRegisterBits[3])
          .tempUnit,
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <SaturatedSuctionIcon className="fill-yellow" />,
        name: t("process_flow"),
        value: liveData.processFlow,
        unit: liveData.processFlow !== "--" && "uA",
        isComponent: true,
        isSkip: false,
      },
      {
        icon: <SaturatedSuctionIcon className="fill-blue" />,
        name: t("chiller_flow"),
        value: liveData.chillerFlow,
        unit: liveData.processFlow !== "--" && "uA",
        isComponent: true,
        isSkip: false,
      },
    ],
    HPF: [
      {
        icon: volumeIcon,
        name: t("tank_volume"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
          .volumeUnit,
        isSkip: false,
      },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
        isSkip: false,
      },
      {
        icon: pipelineTempIcon,
        name: t("pipeline_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).tempUnit,
        isSkip: false,
      },
    ],
    EVC: [
      {
        icon: subCoolTempIcon,
        name: t("subcool_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.subCoolTemp),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.subCoolTemp), true)
          .tempUnit,
        isSkip: false
      },
      {
        icon: <ThermometerIcon className="fill-blue" />,
        name: t("accumulator_temperature_deference"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.accumulatorTemperatureDifference),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.accumulatorTemperatureDifference), true)
          .tempUnit,
        isComponent: true,
        isSkip: deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS" ? true : false
      },
      {
        icon: hspIcon,
        name: t("HSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressureUnit,
        isSkip: false
      },
      {
        icon: CondensingIcon,
        name: t("sct"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .tempUnit,
        isSkip: deviceTypeGlobal === "HPF"  || deviceTypeGlobal === "TURCK_TANKS" ? true : false
      },
      {
        icon: lspIcon,
        name: t("LSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressureUnit,
        isSkip: false
      },
      {
        icon: <SaturatedSuctionIcon />,
        name: t("sst"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .tempUnit,
        isComponent: true,
        isSkip: deviceTypeGlobal === "HPF"  || deviceTypeGlobal === "TURCK_TANKS" ? true : false
      },
      {
        icon: valveIcon,
        name: t("valve_position"),
        value: liveData.valvePos,
        unit: "",
        isSkip: false
      },
      {
        icon: fanSpeedIcon,
        name: t("fan_speed"),
        value: liveData.fanSpeed,
        unit: liveData.fanSpeed !== "--" && "%",
        isSkip: deviceTypeGlobal === "HPF"  || deviceTypeGlobal === "TURCK_TANKS" ? true : false
      },
    ],
    TURCK: [
      {
        icon: PHE_Temperature_Sensor_1,
        name: t("TURCK_PHE_TEMPERATURE_SENSOR_1"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor1)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor1)).tempUnit,
      },
      {
        icon: PHE_Temperature_Sensor_2,
        name: t("TURCK_PHE_TEMPERATURE_SENSOR_2"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor2)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.pheSensor2)).tempUnit,
      },
      {
        icon: Load_Out_Loop_Temperature,
        name: t("TURCK_LOAD_OUT_LOOP_TEMPERATURE"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.loadOutLoop)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.loadOutLoop)).tempUnit,
      },
    ],
    TURCK_TANKS: [
      {
        icon: Tank_Level,
        name: t("TANK_LEVEL"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.tankLevel)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.tankLevel))
          .volumeUnit,
      },
      {
        icon: Tank_Temperature,
        name: t("TANK_TEMPERATURE"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperatureTurck)).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperatureTurck)).tempUnit,
      },
    ],
  };

  const toggleRegistrationCode = async (id, data) => {
    let type = "";
    let message = "";
    let currentDeviceType = "";
    let temp = {};
    if (data.HPFSerialNumber) {
      if (!data.registrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "HPF"
      data.calibrationChart = data.calibrationChart ? data.calibrationChart.id : null
      data.dairy = data?.dairy?.id
      temp = {
        ...data,
        updateRegistrationCode: type
      };
    } else if (data.deviceSerialNumber) {
      if (!data.deviceRegistrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "CHILLER"
      data.locationName = data?.locationName?.id
      temp = {
        ...data,
        updateRegistrationCode: type
      };
    } else if (data.controlSerialNumber) {
      if (!data.registrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
      currentDeviceType = "TURCK"
      data.dairy = data?.dairy?.id;
      data.tanks = [...data.tanks.map((tank) => tank.id)];
      temp = {
        ...data,
        updateRegistrationCode: type
      };
    } 

    if(currentDeviceType === "HPF" || currentDeviceType === "CHILLER" || currentDeviceType === "TURCK") {
      try {
        const res = currentDeviceType === "HPF" ? await updateTank(id, temp) : currentDeviceType === "TURCK" ? await updateTurckControl(id, temp) : await updateChiller(id, temp);
        if ((currentDeviceType === "HPF" && dairyID === res.data.dairy.id) || (currentDeviceType === "CHILLER" && dairyID === res.data.locationName.id) || (currentDeviceType === "TURCK" && dairyID === res.data.dairy.id)) {
          const resObj = await getDevices(dairyID);
          setTanks(resObj.data);
          toast.dark(<Toaster icon="notify" message={message} />, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    }
  };

  /** Show red indicator in device icon */
  useEffect(() => {
    if(deviceTypeGlobal === "HPF") {
      rootApi
      .get(
        `/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"tank":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        let devices = [];
        response.data.map((item) => {
          if (item.tank && item.deviceID !== "") {
            if (!devices.includes(item.deviceID)) {
              devices.push(item.deviceID);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    } else if(deviceTypeGlobal === "CHILLER"){
      rootApi
      .get(
        `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"device":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        let devices = [];
        response.data.map((item) => {
          if (item.tank && item.deviceSerialNumber !== "") {
            if (!devices.includes(item.deviceSerialNumber)) {
              devices.push(item.deviceSerialNumber);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    }

  }, [deviceTypeGlobal]);
  
  return (
    <>
      <Row className="row-full-width">
        <Col xl={tankID === undefined ? 2 : undefined} className="first-section">
          {tanksLoader && tankID !== undefined ? (
            <TanksLoader />
          ) : (
              <>
                <div>
                  {["SuperAdmin", "DairyOperator", "DairyAdmin"].indexOf(
                    user.role
                  ) != -1 ? (
                      <DragDropContext
                        onDragEnd={onDragEnd}
                        onBeforeDragStart={onBeforeDragStart}
                      >
                        <Droppable droppableId="tanks-droppable">
                          {(dropProvided) => {
                            return (
                              <div
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                              >
                                {tanks.map((tank, index) => {
                                  const { location, history } = props;
                                  const isAlert = tankAlert.includes(tank.id);
                                  if (tank.controlName !== undefined  && tank.tanks) {
                                    return (
                                      <Draggable
                                      draggableId={tank.id}
                                      index={index}
                                      key={tank.id}
                                      >
                                        {(dragProvided) => (
                                          <div
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}
                                            ref={dragProvided.innerRef}
                                            className="mb-3 border-0"
                                          >                                       
                                            <TurckDropdown 
                                              tanks = {tanks}
                                              props = {props}
                                              setDeviceType={setDeviceType}
                                              tankAlert={tankAlert}
                                              deviceIdObj={deviceIdObj}
                                              deviceId={deviceId}
                                              setDeviceId={setDeviceId}
                                              setTankDeleteAlert={setTankDeleteAlert}
                                              setTankDeleteIndex={setTankDeleteIndex}
                                              setIsTankEdit={setIsTankEdit}
                                              setCurrentEditTank={setCurrentEditTank}
                                              setShowAddTank={setShowAddTank}
                                              tankID={tankID}
                                              toggleRegistrationCode={toggleRegistrationCode}
                                              control = {tank}
                                              controlIndex={index}
                                              turckTotalTanks = {tank.tanks}
                                              setCurrentActiveView= {setCurrentActiveView}
                                              setDeviceTypeGlobal={setDeviceTypeGlobal}
                                              getDevice={getDevice}
                                              setDeviceIdObj={setDeviceIdObj}
                                              turckControlObjectId={turckControlObjectId}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  } 
                                  else {
                                    if(tank?.type === undefined || tank?.type === null || tank?.deviceType) {
                                      return (
                                        <Draggable
                                          draggableId={tank.id}
                                          index={index}
                                          key={tank.id}
                                        >
                                          {(dragProvided) => (
                                            <div
                                              {...dragProvided.draggableProps}
                                              {...dragProvided.dragHandleProps}
                                              ref={dragProvided.innerRef}
                                            >
                                              <TankCard
                                                key={"tank" + index}
                                                deviceType={tank && tank.deviceType ? "CHILLER" : "HPF"}
                                                click={() => {
                                                  if(tank.id === tankID) return;
                                                  let url = location.pathname.split(
                                                    "/"
                                                  );
                                                  url.pop();
                                                  url.push(tank.id);
                                                  url = url.join("/");
                                                  history.replace(url);
                                                  setCurrentActiveView(GRAPH_VIEW)
                                                  setDeviceId("")
                                                  setDeviceIdObj(null);
                                                  setDeviceTypeGlobal("");
                                                  setDeviceType("");
                                                  getDevice(tank.id);
                                                }}
                                                remove={() => {
                                                  setTankDeleteAlert(true);
                                                  setTankDeleteIndex(index);
                                                }}
                                                editTank={() => {
                                                  setIsTankEdit(true);
                                                  setCurrentEditTank({ ...tank });
                                                  setShowAddTank(true);
                                                }}
                                                tank={tank}
                                                isactive={tankID === tank.id}
                                                toggleRegistrationCode={() =>
                                                  toggleRegistrationCode(tank.id, tank)
                                                }
                                                isAlert={isAlert}
                                                setCurrentActiveView={setCurrentActiveView}
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                };
                                })}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div>
                        <div id="tanks-droppable">
                          <div>
                            {tanks.map((tank, index) => {
                              const { location, history } = props;
                              const isAlert = tankAlert.includes(tank.id);
                              if (tank.controlName !== undefined && tank.tanks) {
																return (
																	<div>
																		<TurckDropdown
																			tanks={tanks}
																			props={props}
																			setDeviceType={setDeviceType}
																			tankAlert={tankAlert}
																			deviceIdObj={deviceIdObj}
																			deviceId={deviceId}
																			setDeviceId={setDeviceId}
																			setTankDeleteAlert={setTankDeleteAlert}
																			setTankDeleteIndex={setTankDeleteIndex}
																			setIsTankEdit={setIsTankEdit}
																			setCurrentEditTank={setCurrentEditTank}
																			setShowAddTank={setShowAddTank}
																			tankID={tankID}
																			toggleRegistrationCode={toggleRegistrationCode}
																			control={tank}
																			controlIndex={index}
																			turckTotalTanks={tank.tanks}
																			setCurrentActiveView={setCurrentActiveView}
																			setDeviceTypeGlobal={setDeviceTypeGlobal}
																			getDevice={getDevice}
																			setDeviceIdObj={setDeviceIdObj}
																			turckControlObjectId={turckControlObjectId}
																		/>
																	</div>
																);
															} else {
																if (tank?.type === undefined || tank?.type === null || tank?.deviceType) {
																	return (
																		<div>
																			<TankCard
																				key={"tank" + index}
																				deviceType={tank && tank.deviceType ? "CHILLER" : "HPF"}
																				click={() => {
																					if(tank.id === tankID) return;
																					let url = location.pathname.split("/");
																					url.pop();
																					url.push(tank.id);
																					url = url.join("/");
																					history.replace(url);
																					setCurrentActiveView(GRAPH_VIEW);
																					setDeviceIdObj(null);
																					setDeviceId("");
																					setDeviceTypeGlobal("")
																					setDeviceType("");
																					getDevice(tank.id);
																				}}
																				remove={() => {
																					setTankDeleteAlert(true);
																					setTankDeleteIndex(index);
																				}}
																				editTank={() => {
																					setIsTankEdit(true);
																					setCurrentEditTank({ ...tank });
																					setShowAddTank(true);
																				}}
																				tank={tank}
																				isactive={tankID === tank.id}
																				toggleRegistrationCode={() => 
                                          toggleRegistrationCode(tank.id, tank)
                                        }
																				isAlert={isAlert}
																				setCurrentActiveView={setCurrentActiveView}
																			/>
																		</div>
																	);
																}
															}
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                {!drag && tankID !== undefined && (
                  <div className="text-center">
                    {user.role === "MilkHauler" || user.role === "Sanitarian" ? (
                      ""
                    ) : (
                        <div
                          className="add-tank"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsTankEdit(false);
                            setShowAddTank(true);
                          }}
                        >
                          <img src={addTankIcon} alt="" />
                          <span className="ml-3">{t("add_device")}</span>
                        </div>
                      )}
                  </div>
                )}
              </>
            )}
        </Col>
        <Col xl={8} className="second-section">
          
          {tankID ? (
            isTankRegistered && dairyAdmin ? (
              <>
                <div className="tank-device d-flex">
                  {deviceIdObj &&
                    ((deviceTypeGlobal === "HPF" && deviceIdObj?.HPF?.length) || (deviceTypeGlobal === "TURCK_TANKS" && deviceIdObj?.TURCK_TANKS?.length) || (deviceTypeGlobal === "CHILLER" && deviceIdObj?.CHILLER?.length)) &&
                    deviceIdObj?.EVC.length !== null &&
                    (
                      currentActiveView !== RAW_REGISTER_VIEW &&
                      currentActiveView !== PARSED_REGISTER_VIEW &&
                      (((deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") && true) || (deviceTypeGlobal === "CHILLER" && currentActiveView !== SETTINGS_VIEW))
                    ) && (
                      <GraphTypeWrapper
                        className={
                          deviceIdObj
                            ? "border-right device-scroll"
                            : 0
                        }
                      >
                        <TankDevices
                          currentTankData={currentTankData}
                          tankType={tankType}
                          setDeviceId={setDeviceId}
                          deviceType={deviceType}
                          deviceTypeGlobal={deviceTypeGlobal}
                          deviceId={deviceId}
                          tankDeviceType={tankDeviceType}
                          setCurrentActiveView={setCurrentActiveView}
                          setDeviceType={setDeviceType}
                          deviceIdObj={deviceIdObj}
                          chillerAlertsList={chillerAlertsList}
                          turckTankName={turckTankName}
                          turckControlSerialNumber={turckcontrolData?.controlSerialNumber}
                        />
                      </GraphTypeWrapper>
                    )}
                  {
                    (
                      currentActiveView === RAW_REGISTER_VIEW ||
                      currentActiveView === PARSED_REGISTER_VIEW ||
                      currentActiveView === SETTINGS_VIEW
                    ) && (
                      <div className="row-register-back-arrow d-flex flex-start" >
                        <BackArrow onClick={() => {
                          setCurrentActiveView(GRAPH_VIEW)
                        }} />
                      </div>
                    )}
                  <GraphControlDiv>
                    {
                      (currentActiveView !== RAW_REGISTER_VIEW && currentActiveView !== PARSED_REGISTER_VIEW) && (

                        <Button
                          className={`${currentActiveView === LIVE_ALERT_VIEW
                            ? deviceIdObj === null
                              ? "mr-auto px-2 ml-0 alert-btn-active"
                              : "mr-auto ml-3 px-2 alert-btn-active"
                            : deviceIdObj === null
                              ? "mr-auto px-2 ml-0 alert-btn"
                              : "mr-auto ml-3 px-2 alert-btn"
                            }`}
                          variant="none"
                          onClick={() => {
                            setCurrentActiveView(currentActiveView === LIVE_ALERT_VIEW ? GRAPH_VIEW : LIVE_ALERT_VIEW)
                            setDeviceId("");
                          }}
                        >
                          <span
                            className={`${tankAlert.includes(tankID)
                              ? "alert-red-indicator position-relative"
                              : "text-primary"
                              }`}
                          >
                            <Image src={AlertIcon} />
                          </span>
                        </Button>
                      )
                    }


                    <div
                      className={
                        showAnnotation === true
                          ? "annotation bg-light-blue"
                          : "annotation white"
                      }
                      show={showAnnotation}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showAnnotation) {
                          setShowAnnotation(false);
                          setManualAnnotation(false);
                        } else {
                          setShowAnnotation(true);
                        }
                      }}
                    >
                      {showAnnotation ? (
                        <div className="d-flex align-items-center">
                          <div className="hide-annotation">
                            {t("hide_annotation")}
                          </div>
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="tooltip-disabled"
                                className="annotation-tooltip"
                              >
                                {t("add_annotation")}
                              </Tooltip>
                            }
                          >
                            <span
                              className="ml-1"
                              show={manualAnnotation}
                              onClick={(e) => {
                                e.stopPropagation();
                                setManualAnnotation(!manualAnnotation);
                              }}
                            >
                              <Image src={plusIcon} />
                            </span>
                          </OverlayTrigger>
                        </div>
                      ) : (
                          t("show_annotation")
                        )}
                    </div>

                    <div className="pause">
                      <Dropdown pause handleClick={setAutoReloadInterval} />
                    </div>
                    <div
                      className="reload-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCustomDates([
                          new Date(ranges.Yesterday[0]).toISOString(),
                          new Date().toISOString(),
                        ]);
                        setReloadCount(reloadCount + 1);
                      }}
                    >
                      <Image src={reloadIcon} alt="" />
                    </div>
                    <div>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          timePicker: true,
                          startDate: new Date(),
                          endDate: new Date(),
                          ranges,
                          opens: "left",
                        }}
                        onApply={() => {
                          getGraphData();
                          getAlerts();
                        }}
                        onCallback={handleDateCallback}
                      >
                        <div className="text-center date-button">
                          <span>
                            {`${moment(customDates[0]).format(
                              "DD MMM"
                            )} - ${moment(customDates[1]).format("DD MMM")}`}
                          </span>
                          <Image
                            src={downCaret}
                            alt=""
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </DateRangePicker>
                    </div>
                    <div
                      className="text-center reload-button"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setShow(true);
                        setCurrentEditTank(() =>
                          tanks.find((i) => i.id === tankID)
                        );
                      }}
                    >
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="tooltip-disabled"
                            className="annotation-tooltip mt-2"
                          >
                            {t("export_csv")}
                          </Tooltip>
                        }
                      >
                        <Image src={IconDownLoad} fluid />
                      </OverlayTrigger>
                    </div>
                  </GraphControlDiv>
                </div>

                {currentActiveView === SETTINGS_VIEW && (
                  <Row>
                    <Col lg={12}>
                      <Settings />
                    </Col>
                  </Row>
                )}

                {currentActiveView === LIVE_ALERT_VIEW && (
                  <Row>
                    <Col lg={12}>
                      <LiveAlertsNotifications
                        currentTankData={currentTankData}
                        dropdownTime={props.dropdownTime}
                        equipmentModelNo={equipmentModelNo}
                        turckControlObjectId={turckControlObjectId}
                        tankType={tankType}
                      />
                    </Col>
                  </Row>
                )}

                {(currentActiveView === RAW_REGISTER_VIEW && isShowingRawRegisterValuesLoading === true)
                  ? (
                    <div className="my-4 rounded px-4 py-4 live-alerts h-74">
                      <RawRegisterLoader />
                    </div>
                  ) : (currentActiveView === RAW_REGISTER_VIEW && isShowingRawRegisterValuesLoading === false) ? (
                    <div className="my-4 rounded px-4 py-4 live-alerts h-74">
                      {
                        ( deviceTypeGlobal === "HPF")
                        ? (<div class="d-flex flex-wrap m-auto">
                            {
                              Object.keys(rawRegisters).map((register) => (
                                <div className="raw-rawRegister mb-3">
                                  <div className="d-flex flex-column"><p>{register}</p></div>
                                  <div className="col-rawRegister"><p>{rawRegisters[register]}</p></div>
                                </div>
                              ))
                            }
                          </div>)
                        : (<div class="m-auto">
                            {<Tabs className="main-tab register-values-tabs mb-4">
                              {rawRegisters
                                ? Object.keys(rawRegisters).toReversed().map((deviceType, index) => {
                                  let imgTankDevice;
                                  if (deviceType.includes('CHILLER')) {
                                    imgTankDevice = <ChillerDeviceIcon className="register-tank-vertical" alt="tank icon" />
                                  } else if (deviceType.includes('TURCK')) {
                                    imgTankDevice = <img src={ tankType === 1 ? tankVerticalIcon : horizontalTank} className="register-tank-vertical" alt="tank icon" />
                                  } else {
                                    imgTankDevice = <img src={EVC} className="evc-tank-icon" alt="evc icon" />;
                                  }
                                  return (
                                    <Tab eventKey={index} title={imgTankDevice} key={deviceType}>
                                      {
                                        (deviceType === "CHILLER" || deviceType === "TURCK") &&
                                        <div class="d-flex flex-wrap m-auto justify-between">
                                          {
                                            Object.entries(rawRegisters[deviceType])?.map(([key, value]) => (
                                              <div className="raw-rawRegister mb-3">
                                                <div className="d-flex flex-column"><p>{key}</p></div>
                                                <div className="col-rawRegister"><p>{value}</p></div>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      }
                                      {
                                        deviceType === "EVC" && !!currentEnableCompressorsData?.length &&
                                        <Collapse accordion expandIconPosition="right" bordered={false} className="settings-accordion">
                                          {currentEnableCompressorsData.map(({evcDeviceData, compressorIndex}, evcIndex) => (
                                            <Collapse.Panel key={compressorIndex} header={`Compressor: ${compressorIndex + 1}`} className="settings-accordion__item">
                                              <div class="d-flex flex-wrap m-auto">
                                                {
                                                  evcDeviceData && Object.keys(evcDeviceData).length
                                                    ?
                                                      Object.entries(evcDeviceData)?.map(([key, value]) => (
                                                        <div className="raw-rawRegister mb-3">
                                                          <div className="d-flex flex-column"><p>{key}</p></div>
                                                          <div className="col-rawRegister"><p>{value}</p></div>
                                                        </div>
                                                      ))
                                                    : <div className="text-center">{t('data_not_available')}</div>
                                                }
                                              </div>
                                            </Collapse.Panel>
                                          ))}
                                        </Collapse>
                                      }
                                      {
                                        deviceType === "EVC" && !!currentlyAttachedEVC?.length &&
                                        <Collapse accordion expandIconPosition="right" bordered={false} className="settings-accordion">
                                          {currentlyAttachedEVC.map((evcData,evcIndex) => (
                                            <Collapse.Panel key={evcIndex} header={`Compressor: ${evcIndex + 1}`} className="settings-accordion__item">
                                              <div class="d-flex flex-wrap m-auto">
                                                {
                                                  evcData && Object.keys(evcData).length
                                                    ?
                                                      Object.entries(evcData)?.map(([key, value]) => (
                                                        <div className="raw-rawRegister mb-3">
                                                          <div className="d-flex flex-column"><p>{key}</p></div>
                                                          <div className="col-rawRegister"><p>{value}</p></div>
                                                        </div>
                                                      ))
                                                    : <div className="text-center">{t('data_not_available')}</div>
                                                }
                                              </div>
                                            </Collapse.Panel>
                                          ))}
                                        </Collapse>
                                      }
                                      </Tab>
                                    );
                                  }).reverse()
                                  : (
                                    <center>
                                      <NoDataAvailable title={t("no_data")} />
                                    </center>
                                  )
                                }
                            </Tabs>}
                          </div>)
                      }
                    </div>
                  ) : ("")}
                {currentActiveView === PARSED_REGISTER_VIEW && (
                  <div className="my-4 rounded px-4 py-4 live-alerts">
                    <div class="m-auto">{
                      !isLoadingRegisterValues ? (
                        <Tabs

                          className="main-tab register-values-tabs"
                        >
                          {

                            registerValues
                              ? Object.keys(registerValues).map((deviceType, index) => {
                                let imgTankDevice;
                                if (deviceType.includes('HPF') || deviceType.includes('CHILLER') || deviceType.includes('TURCK_TANKS') || deviceType.includes('TURCK')) {
                                  imgTankDevice =  (deviceTypeGlobal === "HPF" || (deviceTypeGlobal === "TURCK_TANKS" && deviceType !== 'TURCK'))
                                                  ? (registerValues.tankTypeName === 'Vertical')
                                                    ? <img src={tankVerticalIcon} className="register-tank-vertical" alt="tank icon" />
                                                    : <img src={horizontalTank} className="register-tank-vertical" alt="tank icon" />
                                                  :( deviceType === 'TURCK' )? <img src={turckcontrolData.controlType === 'Stratus'? stratusControl : nimbusControl} className="register-tank-control-vertical" alt="tank icon"/>:<ChillerDeviceIcon className="register-tank-vertical" alt="tank icon" />
                                } else {
                                  imgTankDevice = <img src={EVC} className="evc-tank-icon" alt="evc icon" />;
                                }
                                if(deviceType.includes('HPF') || deviceType.includes('CHILLER') || deviceType.includes('TURCK_TANKS') || deviceType.includes('TURCK') || deviceType.includes('EVC')) {
                                return (
                                  <Tab eventKey={index} title={imgTankDevice} key={deviceType}>
                                    {
                                      Object.keys(registerValues[deviceType]).map((deviceId) => (
                                        <div key={deviceId} className="tabcontentBlock">
                                          <strong>
                                            {` ID : ${deviceId} `}
                                          </strong>
                                          <table style={{ border: "1px solid #adcdff", marginTop: "10px", width: "50%" }}>
                                            {
                                              Object.keys(registerValues[deviceType][deviceId])
                                                .map((deviceRegisters) => {
                                                  for (let i = 0; i < config.length; i += 1) {
                                                    if(config[i].key.includes("TURCK_TANK")) {
                                                      config[i].key = config[i].key.replace(/^TURCK_/, '');
                                                    }
                                                    if (`${deviceRegisters}_ADDR` === config[i].key || deviceRegisters === config[i].key || deviceRegisters === `${deviceType}_INPUT_REG_MAJOR_REVISION` || deviceRegisters === 'EVC_IDENTIFICATION') {
                                                      let sensorValue = '';
                                                      let sensorUnit = '';
                                                      let deviceWithId = registerValues[deviceType][deviceId];
                                                      if (config[i].key.includes('TEMP') && config[i].key.includes('CHILLER')) {
                                                        const {
                                                          temperature,
                                                          tempUnit,
                                                        } = getTempAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]), undefined, undefined, undefined, registerValues[deviceType][deviceId][deviceRegisters]);
                                                        sensorUnit = tempUnit;
                                                        sensorValue = temperature;
                                                      } else if (config[i].key.includes('TEMP')) {
                                                        const {
                                                          temperature,
                                                          tempUnit,
                                                        } = getTempAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]), config[i].key.includes('SUBCOOL'));
                                                        sensorUnit = tempUnit;
                                                        sensorValue = temperature;
                                                      } else if (config[i].key === 'HPF_INPUT_REG_VOLUME_4TO20MA_READING') {
                                                        const {
                                                          volume,
                                                          volumeUnit,
                                                        } = getVolumeAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]));
                                                        sensorUnit = volumeUnit;
                                                        sensorValue = volume;
                                                      } else if (config[i].key.includes('PRESSURE')) {
                                                        const {
                                                          pressure,
                                                          pressureUnit,
                                                        } = getPressureAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]));
                                                        sensorUnit = pressureUnit;
                                                        sensorValue = pressure;
                                                      } else if (deviceTypeGlobal === "CHILLER" && config[i].key.includes('EVC_INPUT_REG_FAN_OUTPUT')) {
                                                        sensorUnit = config[i].unit
                                                        sensorValue = Math.floor((Number(registerValues[deviceType][deviceId][deviceRegisters]) / 4096) * 100);
                                                      } else {
                                                        sensorUnit = config[i].unit;
                                                        sensorValue = deviceWithId[deviceRegisters];
                                                      }
                                                      let tableRowToReturn = (
                                                        <tbody className="row-register-table" key={deviceRegisters}>
                                                          <td>
                                                            {
                                                              deviceTypeGlobal === "HPF" 
                                                                ? t(`${config[i].key}`)
                                                                : deviceTypeGlobal === "TURCK_TANKS"
                                                                ? t(`${config[i].displayName}`)
                                                                :config[i].key === "EVC_INPUT_REG_TEMP_1"
                                                                  ? t('accumulator_inlet_temperature')
                                                                  : config[i].key === "EVC_INPUT_REG_TEMP_2"
                                                                    ? t('accumulator_outlet_temperature')
                                                                    : t(`${config[i].key}`)
                                                            }
                                                          </td>
                                                          <td>
                                                            {sensorValue}
                                                            {' '}
                                                            {sensorUnit}
                                                          </td>
                                                        </tbody>
                                                      );
                                                      if (deviceRegisters === 'EVC_IDENTIFICATION') {
                                                        sensorValue = findCompressorNumberByValue(Number(deviceWithId[deviceRegisters]))
                                                        if(!sensorValue) {
                                                          sensorValue = "-";
                                                        }
                                                        tableRowToReturn = (
                                                          <tbody className="row-register-table" key={deviceRegisters} >
                                                            <td>
                                                              {t(`${deviceType}_NUMBER`)}
                                                      </td>
                                                            <td>
                                                              {sensorValue}
                                                            </td>
                                                          </tbody>
                                                        );
                                                      } else if (deviceRegisters === `${deviceType}_INPUT_REG_MAJOR_REVISION`) {
                                                        tableRowToReturn = (
                                                          <tbody className="row-register-table" key={deviceRegisters} >
                                                            <td>{t(`${deviceType}_FIRMWARE_VERSION`)}</td>
                                                            <td>
                                                              {deviceWithId[`${deviceType}_INPUT_REG_MAJOR_REVISION`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_MINOR_REVISION`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_BUILD_NUMBER`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_HW_REVISION`]}
                                                            </td>
                                                          </tbody>
                                                        )
                                                      }
                                                      return tableRowToReturn;
                                                    }
                                                  }
                                                  return '';
                                                })
                                            }
                                          </table>
                                        </div>
                                      ))
                                    }
                                  </Tab>
                                );
                              }
                            }).reverse()
                              : (
                                <center>
                                  <NoDataAvailable title={t("no_data")} />
                                </center>
                              )
                          }
                        </Tabs>
                      ) : (<ParseRegisterLoader />)}
                    </div>
                  </div>
                )}
                {manualAnnotation && (
                  <Alert
                    className="mt-5"
                    variant="warning"
                    onClose={() => setManualAnnotation(!manualAnnotation)}
                    dismissible
                  >
                    {t("add_annotation_instruction")}
                  </Alert>
                )}
                
                {currentActiveView === GRAPH_VIEW && (
                  <>
                    <Chiller
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      currentActiveView={currentActiveView}
                      chillerGraphData={chillerGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />

                    <Hpf
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      currentActiveView={currentActiveView}
                      hpfGraphData={hpfGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />
                    <Turck
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      currentActiveView={currentActiveView}
                      turckGraphData={turckGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />

                    <Evc
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      currentActiveView={currentActiveView}
                      evcGraphData={evcGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />
                  </>
                )}
              </>
            ) : (
                <center>
                  {dairyAdmin !==  undefined && (!dairyAdmin ? <NoDataAvailable title={t("the_site_owner_has_not_registered")} />
                  : <NoDataAvailable title={t("device_not_registered")} />)}
                </center>
              )
          ) : (
              <center>
                <NoTankDataAvailable
                  setIsTankEdit={setIsTankEdit}
                  setShowAddTank={setShowAddTank}
                />
              </center>
            )}
        </Col>
        {
          tankID === undefined || !deviceType ? (
            ""
          ) : (
              <Col xl={2} className="third-section">
                <LiveDataSideBar
                  tankStatusObj={tankStatusObj}
                  agitatedTime={agitatedTime}
                  liveDataHelper={liveDataHelper}
                  deviceType={deviceType}
                  secondsToHourMin={secondsToHourMin}
                  currentTankData={currentTankData}
                  dropdownTime={props.dropdownTime}
                />
              </Col>
            )
        }
      </Row >
        <AddDeviceControlForm
          dealerID={dealerID}
          show={showAddTank}
          close={() => setShowAddTank(false)}
          dairy={dairyName}
          addNewTank={addNewTank}
          edit={isTankEdit}
          tank={currentEditTank}
          editTank={editTank}
          tankType={tankType}
        />
      <PromptModel
        open={tankDeleteAlert}
        closeAlert={() => {
          setTankDeleteAlert(false);
        }}
        modelContent={t("are_you_sure")}
        callback={() => {
          if (tankDeleteIndex !== null) {
            removeTank(tankDeleteIndex);
          }
        }}
      />
      {
        show && (
          <ExportCsvForm
            showModel={show}
            closeModel={() => setShow(false)}
            tank={currentEditTank}
            exportDeviceIds={exportDeviceIds}
            dropdownTime={props.dropdownTime}
          />
        )
      }
    </>
  );
});
TankListing.propTypes = {
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  dropdownTime: PropTypes.any,
};
export default withRouter(TankListing);
