import React, { useEffect, useState, useRef } from "react";
// import Dygraph from "dygraphs";
import Dygraph from "dygraphs/index.es5";
// eslint-disable-next-line
import Plugins from "dygraphs/src/extras/synchronizer";
// eslint-disable-next-line
import Hairlines from "dygraphs/src/extras/hairlines";
import Crosshair from "./CrossHair";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import "bootstrap-daterangepicker/daterangepicker.css";
import styled from "styled-components";
import { Row, Col, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import CustomCheckbox from "../CustomCheckbox";
import volumeIcon from "../../assets/images/Volume.svg";
import blueTempIcon from "../../assets/images/blue_temp.svg";
import greenTempIcon from "../../assets/images/green_temp.svg";
import yellowTempIcon from "../../assets/images/yellow_temp.svg";
import whiteStandbyIcon from "../../assets/images/standby-white.png";
import whiteFanIcon from "../../assets/images/fan-white.png";
import whiteSetupIcon from "../../assets/images/setup-white.png";
import coolingIcon from "../../assets/images/tooltip-snowflake.png";
import washingIcon from "../../assets/images/washing.png";
import diagnosticsIcon from "../../assets/images/Diagnostics.png";
import waveIcon from "../../assets/images/chiller_control_wave_icon.svg";
import standByIcon from '../../assets/images/Standby.svg';
import alertIcon from "../../assets/images/alert-icon.svg";
import flowMeterIcon from "../../assets/images/Group3633.svg";
import vatLevelIcon from "../../assets/images/Group3635.svg";
import motorFreqIcon from "../../assets/images/Group3637.svg";
import $ from "jquery";
import Sidebar from "../AnnotationSidebar/AnnotationSidebar";
import { useParams } from "react-router-dom";
import {
  postManualAnnotation,
  postChillerManualAnnotation,
  postTurckManualAnnotation,
  DeleteManualAnnotation,
  DeleteChillerManualAnnotation,
  DeleteTurckManualAnnotation,
  getUser,
} from "../../actions/actions";
import Toaster from "../Toaster";
import { toast } from "react-toastify";
import { ReactComponent as SystemIcon } from "../../assets/images/systemAlert.svg";
import { ReactComponent as ManualAlertIcon } from "../../assets/images/manualAlert.svg";
import Delete from "../../assets/images/delete.svg";
import PromptModel from "../PromptModel";
import {
  status_chiller as statusChiller,
} from "../../config";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem
} from "../../helpers";
import { useDeviceContext } from '../../context/deviceContext'

$(document).on("mouseover", ".dygraph-hairline", function () {
  $(".untitled").addClass("d-none");
  $(this).next("div").find(".untitled").removeClass("d-none");
});

const GraphWrapper = styled.div`
  margin-top: 20px;
  padding: 20px 10px 40px 10px;
  border-radius: 10px;
  border: solid 2px #d6e6ff;
`;

const CheckboxText = styled.span`
  margin-left: 5px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`;

const CheckboxContainer = styled(Col)`
  align-self: center;
  padding-left: 4%;
`;

const Graph = (props) => {
  const {
    data,
    alerts,
    showAnnotation,
    sensors,
    graphId,
    t,
    washData,
    manualAnnotation,
    getAlerts,
    dropdownTime,
    isResponsiveMode,
    deviceType,
  } = props;
  const ref = useRef(null)
  const { deviceTypeGlobal } = useDeviceContext(); 
  const getTankTooltipData = (temp, vol, state) => {
    const tooltipData = {
      tankTemp: null,
      tankVolume: null,
      tankState: null,
    };

    // Set Tank Temperature for graph tooltip
    if(temp != null && !Number.isNaN(temp)) {
      const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
      tooltipData.tankTemp = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
    }

    // Set Tank Volume for graph tooltip
    if (vol != null && !Number.isNaN(vol)) {
      const { volume, volumeUnit } = getVolumeAsPerSelectedUnitSystem(Number(vol));
      tooltipData.tankVolume = (!Number.isNaN(volume) && volumeUnit) ? `${volume} ${volumeUnit}` : null;
    }

    // Set Tank State for graph tooltip
    if (state != null && !Number.isNaN(state)) {
      tooltipData.tankState = Number(state);
    }

    return tooltipData;
  };
  const getTurckTankTooltipData = (temp, vol, state, flowMeter, motorFrequency, vatLevelPercentage) => {
    const tooltipData = {
      tankTemp: null,
      tankVolume: null,
      tankState: null,
      tankFlowMeter: null,
      tankMotorFrequencyCommanded: null,
      tankVatLevelPercentage: null
    };

    // Set Tank Temperature for graph tooltip
    if(temp != null && !Number.isNaN(temp)) {
      const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
      tooltipData.tankTemp = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
    }

    // Set Tank Volume for graph tooltip
    if (vol != null && !Number.isNaN(vol)) {
      const { volume, volumeUnit } = getVolumeAsPerSelectedUnitSystem(Number(vol));
      tooltipData.tankVolume = (!Number.isNaN(volume) && volumeUnit) ? `${volume} ${volumeUnit}` : null;
    }

    // Set Tank State for graph tooltip
    if (state != null && !Number.isNaN(state)) {
      tooltipData.tankState = Number(state);
    }
     // Set Tank Flow Meter for graph tooltip
    if (flowMeter != null && !Number.isNaN(flowMeter)) {
      tooltipData.tankFlowMeter = Number(flowMeter);
    }
     // Set Tank Motor Frequency for graph tooltip
    if (motorFrequency != null && !Number.isNaN(motorFrequency)) {
      tooltipData.tankMotorFrequencyCommanded = Number(motorFrequency);
    }
     // Set Tank Vat Level Percentage for graph tooltip
    if (vatLevelPercentage != null && !Number.isNaN(vatLevelPercentage)) {
      tooltipData.tankVatLevelPercentage = Number(vatLevelPercentage);
    }
    return tooltipData;
  };
  // TODO: Recreate this function as per new response for chilller/EVC tooltips
  const getChillerTooltipData = (chillerOutletTemp, chillerReturnTemp, processInletTemp, processOutletTemp, chillerState) => {
    const tooltipHeaderData = {};
    // Set Chiller Temperatures for graph tooltip
    Object.entries({chillerOutletTemp, chillerReturnTemp, processInletTemp, processOutletTemp}).forEach(([key, temp])=> {
      if(temp != null && !Number.isNaN(temp)) {
        const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
        tooltipHeaderData[key] = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
      }
    })

    // Set Chiller State for graph tooltip
    if (chillerState != null && !Number.isNaN(chillerState)) {
      tooltipHeaderData.chillerState = Number(chillerState);
    }

    return tooltipHeaderData;
  };

  const tooltip = (dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList = []) => {
    const currentTopOfTooltip = document.getElementsByClassName('dygraph-legend')[0]?.style?.top?.split('px')[0];
    if(currentTopOfTooltip && Number(currentTopOfTooltip) > 200) {
      document.getElementsByClassName('dygraph-legend')[0].style.top = '50px';
    }
    const date = new Date(dygraphLegendFormatter.x);
    let tankTemp = null;
    let tankVolume = null;
    let tankState = null;
    let motorFrequencyCommanded = null;
    let flowMeter = null;
    let vatLevelPercentage = null;

    let tooltipHeaderData = {};
    if(graphId === 'hpf') {
      const tooltipData = getTankTooltipData(graphValuesList[1], graphValuesList[5], graphValuesList[6]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;
    } else if(graphId === 'chiller') {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[1], graphValuesList[2],graphValuesList[3],graphValuesList[4], graphValuesList[9]);
    } else if (deviceTypeGlobal === "HPF" && graphId === 'evc0') {
      const tooltipData = getTankTooltipData(graphValuesList[5], graphValuesList[6], graphValuesList[7]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;

    } else if (deviceTypeGlobal === "HPF" && (graphId === 'evc1' || graphId === 'evc2')) {
            const tooltipData = getTankTooltipData(graphValuesList[2], graphValuesList[3], graphValuesList[4]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;

    } else if (deviceTypeGlobal === "CHILLER" && graphId === 'evc0') {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[11], graphValuesList[12],graphValuesList[13],graphValuesList[14], graphValuesList[15]);
    } else if (deviceTypeGlobal === "CHILLER" && (graphId === 'evc1' || graphId === 'evc2')) {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[5], graphValuesList[6],graphValuesList[7],graphValuesList[8], graphValuesList[9]);
    } else if (deviceTypeGlobal === "TURCK_TANKS" && ( graphId === 'evc0' || graphId === 'evc1' || graphId === 'evc2')) {
      let tooltipData;
      if(graphId === 'evc0') {
        tooltipData = getTurckTankTooltipData(graphValuesList[16], graphValuesList[17], graphValuesList[18], null, null, null);
      } else if(graphId === 'evc1') {
        tooltipData = getTurckTankTooltipData(graphValuesList[10], graphValuesList[11], graphValuesList[12], null, null, null);
      } else if(graphId === 'evc2') {
        tooltipData = getTurckTankTooltipData(graphValuesList[11], graphValuesList[12], graphValuesList[13], null, null, null);
      }
      tankState = tooltipData.tankState;
      tankVolume = tooltipData.tankVolume;
      tankTemp = tooltipData.tankTemp;
    } else if(graphId === 'turck') {
      const tooltipData = getTurckTankTooltipData(graphValuesList[5], graphValuesList[4], graphValuesList[10], graphValuesList[12], graphValuesList[13], graphValuesList[14]);
      tankTemp = tooltipData.tankTemp || null;
      tankVolume = tooltipData.tankVolume || null;
      tankState = tooltipData.tankState;
      motorFrequencyCommanded = tooltipData.tankMotorFrequencyCommanded;
      flowMeter = tooltipData.tankFlowMeter;
      vatLevelPercentage = tooltipData.tankVatLevelPercentage;
    }
    let agitatorOutputTank = "";
    let condensingUnit1Tank = "";
    let condensingUnit2Tank = "";
    let percentFullTank = "";
    let pheSensor1 = "";
    let pheSensor2 = "";
    let loadOutLoop = "";
    let turckTankLevel = "";
    let turckTankTemp = "";
    let washTimeRemaining = "";
    let agitatorOutputTankColor = "";
    let condensingUnit1TankColor = "";
    let condensingUnit2TankColor = "";
    let percentFullTankColor = "";
    let pheSensor1Color = "";
    let pheSensor2Color = "";
    let loadOutLoopColor = "";
    let turckTankLevelColor = "";
    let turckTankTempColor = "";
    let washTimeRemainingColor = "";

    dygraphLegendFormatter.series.forEach((data) => {
			if (isNaN(data.y)) {
				data.y = 0;
			}

			if (data.labelHTML === `${t('TURCK_PHE_TEMPERATURE_SENSOR_1')}`) {
				pheSensor1 = data && data.yHTML;
				pheSensor1Color = data.color;
			}
			if (data.labelHTML === `${t('TURCK_PHE_TEMPERATURE_SENSOR_2')}` && data.isVisible === true) {
				pheSensor2 = data && data.yHTML;
				pheSensor2Color = data.color;
			}
			if (data.labelHTML === `${t('TURCK_LOAD_OUT_LOOP_TEMPERATURE')}` && data.isVisible === true) {
				loadOutLoop = data && data.yHTML;
				loadOutLoopColor = data.color;
			}
			if (data.labelHTML === `${t('TANK_LEVEL')}` && data.isVisible === true) {
        // console.log({data})
				const { volume, volumeUnit } = getVolumeAsPerSelectedUnitSystem(Number(data.y));
				data.yHTML = !Number.isNaN(volume) && volumeUnit ? `${volume}&nbsp;${volumeUnit}` : null;
				turckTankLevel = data && data.yHTML;
				turckTankLevelColor = data.color;
			}
			if (data.labelHTML === `${t('TANK_TEMPERATURE')}` && data.isVisible === true) {
				turckTankTemp = data && data.yHTML;
				turckTankTempColor = data.color;
			}

			if (data.labelHTML === `${t('WASH_TIME_REMAINING')}` && data.isVisible === true) {
				data.yHTML = data.y + '&nbsp;s';
				washTimeRemaining = data && data.yHTML;
				washTimeRemainingColor = data.color;
			}

			if (data.labelHTML === `${t('AGITATOR_OUTPUT')}` && data.isVisible === true) {
				if (data.y === 0) {
					data.yHTML = 'Off';
				} else {
					data.yHTML = 'On';
				}
				agitatorOutputTank = data && data.yHTML;
				agitatorOutputTankColor = data.color;
			}
			if (data.labelHTML === `${t('CONDENSING_UNIT_1')}` && data.isVisible === true) {
				if (data.y === 0) {
					data.yHTML = 'Off';
				} else {
					data.yHTML = 'On';
				}
				condensingUnit1Tank = data && data.yHTML;
				condensingUnit1TankColor = data.color;
			}
			if (data.labelHTML === `${t('CONDENSING_UNIT_2')}` && data.isVisible === true) {
				if (data.y === 0) {
					data.yHTML = 'Off';
				} else {
					data.yHTML = 'On';
				}
				condensingUnit2Tank = data && data.yHTML;
				condensingUnit2TankColor = data.color;
			}
			if (data.labelHTML === `${t('TANK_STATE')}` && data.isVisible === true) {
				tankState = data && data.y;
			}
			if (data.labelHTML === `${t('PERCENT_FULL')}` && data.isVisible === true) {
				data.yHTML = data.y + '&nbsp;%';
				percentFullTank = data && data.yHTML;
				percentFullTankColor = data.color;
			}
		});

    const milkPipelineTemp =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";
    const tempSensor3 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";
    const tempSensor4 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";

    const { chillerOutletTemp = "???", chillerReturnTemp = "???", processInletTemp = "???", processOutletTemp = "???", chillerState } = tooltipHeaderData;

    const LSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const SST =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";

    const HSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";
        
    const SCT =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";

    const subCoolTemp =
      graphId === "evc1"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const evcTemp =
      graphId === "evc2"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const evcTemp2 =
      graphId === "evc2"
        ? (dygraphLegendFormatter.series[9] &&
          dygraphLegendFormatter.series[9].yHTML) ||
        ""
        : "";
        
    const displayHpfState = (
      ` ${tankState != null && tankState >= 0 && tankState <= 1000 ? `
          <div class=${(deviceTypeGlobal === "TURCK_TANKS")?`"col-sm-3 my-4 p-2"` : `"col-sm-4 my-2 p-0"`}>
            <img class="img mb-1" src=${whiteStandbyIcon} alt="Standby" title="" />
            <div>${t("STANDBY")}</div>
          </div>`
        : ''}

        ${tankState === 1001 ? `
          <div class=${( deviceTypeGlobal === "TURCK_TANKS" ) ? `"col-sm-3 my-4 p-2"` : `"col-md-4 col-sm-4 my-2 p-0"`}>
            <img class="img mb-1" src=${whiteFanIcon} alt="Fan" title="" />
            <div>${t("AGITATE")}</div>
          </div>`
        : ''}

        ${tankState === 1002 || tankState === 1004 ? `
          <div class=${( deviceTypeGlobal === "TURCK_TANKS" ) ? `"col-sm-3 my-4 p-2"` : `"col-md-4 col-sm-4 my-2 p-0 mode-font"`}>
            <img class="img mb-1" src=${whiteSetupIcon} alt="Setup" title="" />
            <div>${t("SETTINGS")}</div>
          </div>`
        : ''}

        ${tankState === 1003 ? `
          <div class=${( deviceTypeGlobal === "TURCK_TANKS" ) ? `"col-sm-3 my-4 p-2"` : `"col-md-4 col-sm-4 my-2 p-0 mode-font"`}>
            <img class="img mb-1" src=${diagnosticsIcon} alt="Setup" title="" />
            <div>${t("DIAGNOSTIC")}</div>
          </div>`
        : ''}

        ${tankState >= 2000 && tankState <= 2007 ? `
          <div class=${( deviceTypeGlobal === "TURCK_TANKS" ) ? `"col-sm-3 my-4 p-2"` : `"col-md-4 col-sm-4 my-2 p-0"`}>
            <img class="img mb-1" src=${coolingIcon} alt="Cooling" title="" />
            <div>${t("COOL")}</div>
          </div>`
        : ''}

        ${tankState >= 3000 && tankState <= 4000 ? `
          <div class=${( deviceTypeGlobal === "TURCK_TANKS" ) ? `"col-sm-3 my-4 p-2"` : `"col-md-4 col-sm-4 my-2 p-0"`}>
            <img class="img mb-1" src=${washingIcon} alt="Washing" title="" />
            <div>${t("WASH")}</div>
          </div>`
        : ''}`
    )
let chillerStateIcon = '';
if(!chillerStateIcon &&  chillerState) {
  if(statusChiller[chillerState] === 'CHILLER_IDLE'  || statusChiller[chillerState] === 'CHILLING') {
    chillerStateIcon = coolingIcon;
  }
  else if (statusChiller[chillerState] === 'PUMP_IDLE' || statusChiller[chillerState] === 'PUMPING' ) {
    chillerStateIcon = waveIcon;
  }
  else if (statusChiller[chillerState] === 'ALARM'){
    chillerStateIcon = alertIcon;
  }
  else if (statusChiller[chillerState] === 'TECH_OVERRIDE'){
    chillerStateIcon = diagnosticsIcon;
  }
  else {
    chillerStateIcon = standByIcon;
  }
}
    const displayChillerState = (`
      <div class="col-sm-3 my-2 p-0">
        <div class="d-flex justify-content-center align-items-center mb-1">
          <img class="img chillerStateIcon" src=${chillerStateIcon} alt="Chiller" />
          <div style="max-width:75%;word-wrap:break-word">${chillerState ? t(statusChiller[chillerState]) : '--'}</div>
        </div>
        <div>Chiller State</div>
      </div>
    `)

    const displayTurckToolTipHeader =
			(deviceTypeGlobal === "TURCK_TANKS" && deviceType === "EVC")
				? `
      <div class="col-sm-4 right-border my-4 p-2">
        <img class="mb-1" src=${greenTempIcon} alt="" title="" />
        <div>${tankTemp}</div>
      </div>
      <div class="col-sm-4 right-border my-4 p-2">
        <img class="mb-1" src=${volumeIcon} alt="" title="" />
        <div>${tankVolume}</div>
      </div>
      ${displayHpfState}
    `
				: `
      <div class="col-sm-3 right-border my-4 p-2 text-left">
          <img class="mb-1" src=${motorFreqIcon} alt="" title=""/>
          <div class="turckGraphToolTipHeader--title">${t('TURCK_MOTOR_FREQUENCY_COMMANDED')}</div>
          <strong>${motorFrequencyCommanded} Hz</strong>
        </div>
        <div class="col-sm-3 right-border my-4 p-2 text-left">
          <img class="mb-1" src=${flowMeterIcon} alt="" title=""/>
          <div class="turckGraphToolTipHeader--title">${t('TURCK_FLOW_METER')}</div>
          <strong>${flowMeter} gpm</strong>
        </div>
        <div class="col-sm-3 right-border my-4 p-2 text-left">
          <img class="mb-1" src=${vatLevelIcon} alt="" title=""/>
          <div class="turckGraphToolTipHeader--title">${t('TURCK_VAT_LEVEL_PERCENTAGE')}</div>
          <strong>${vatLevelPercentage} %</strong>
        </div>
        ${displayHpfState}
    `;

    const displayTurckToolTipBody = ` 
    <div class="container-fluid p-3 tooltip-label-wrapper">
    <div class="row pb-1 px-1">
      ${
				pheSensor1
					? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${pheSensor1Color}"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t('TURCK_PHE_TEMPERATURE_SENSOR_1')}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${pheSensor1}</div> 
        `
					: ''
			}

      ${
				pheSensor2
					? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${pheSensor2Color}"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t('TURCK_PHE_TEMPERATURE_SENSOR_2')}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${pheSensor2}</div> 
        `
					: ''
			}

      ${
				loadOutLoop
					? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${loadOutLoopColor}"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t('TURCK_LOAD_OUT_LOOP_TEMPERATURE')}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${loadOutLoop}</div> 
        `
					: ''
			}

      ${
				turckTankLevel
					? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${turckTankLevelColor}"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t('TANK_LEVEL')}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${turckTankLevel}</div> 
        `
					: ''
			}

      ${
				turckTankTemp
					? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${turckTankTempColor}"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t('TANK_TEMPERATURE')}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${turckTankTemp}</div> 
        `
					: ''
			}
      ${
        washTimeRemaining ? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${washTimeRemainingColor
          }"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t(
            "Wash Time Remaining"
          )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${washTimeRemaining}</div> 
        `
        : ""
      }

      ${
        agitatorOutputTank ? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${agitatorOutputTankColor
          }"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t(
            "Agitator Output"
          )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${agitatorOutputTank}</div> 
        `
        : ""
      }

      ${
        condensingUnit1Tank ? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${condensingUnit1TankColor
          }"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t(
            "Condensing Unit 1 Tank "
          )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${condensingUnit1Tank}</div>
        `
        : ""
      }

      ${
        condensingUnit2Tank ? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${condensingUnit2TankColor
          }"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t(
            "Condensing Unit 2 Tank "
          )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${condensingUnit2Tank}</div>
        `
        : ""
      }


      ${
        percentFullTank ? `
        <div class="col-md-1 col-sm-1 py-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${percentFullTankColor
          }"></div>
        </div>
          <div class="col-md-7 col-sm-5 text-left py-1">${t(
            "Percent Full Tank "
          )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${percentFullTank}</div>
        `
        : ""
      }

    </div>
    </div>
    `;
    const tooltipHeader = (
      `<div class="container-fluid">
        <div class="row mb-2">
          ${
            (deviceTypeGlobal === "HPF") ?
            `
              <div class="col-sm-4 right-border my-2 p-0">
                <img class="mb-1" src=${greenTempIcon} alt="" title="" />
                <div>${tankTemp}</div>
              </div>

              <div class="col-sm-4 right-border my-2 p-0">
                <img class="mb-1" src=${volumeIcon} alt="" title="" />
                <div>${tankVolume}</div>
              </div>

              ${displayHpfState}

            ` 
            : (deviceTypeGlobal === 'CHILLER')?
            `
              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${blueTempIcon} alt="" title="" />
                <div>${chillerOutletTemp}</div>
              </div>

              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${greenTempIcon} alt="" title="" />
                <div>${chillerReturnTemp}</div>
              </div>

              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${yellowTempIcon} alt="" title="" />
                <div>${processOutletTemp}</div>
              </div>

              ${displayChillerState}
            `
            : displayTurckToolTipHeader
          }

        </div>
      </div>`);

    return `<div class="graph-tooltip justify-content-center w-100 graphTooltip">
      <div class="panel-box text-white">
      ${tooltipHeader}
      ${graphId === "hpf"
        ? `<div class="container-fluid p-3 tooltip-label-wrapper">
        <div class="row pb-1 ${dygraphLegendFormatter.series[1]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color
          }"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left">${t(
            "milk_pipeline_temperature"
          )}</div>
          <div class="col-md-3 col-sm-5 text-right">${milkPipelineTemp}</div>
        </div>

        <div class="row pb-1 ${dygraphLegendFormatter.series[2]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left py-1">${t(
            "temperature_sensor_3"
          )}</div>
          <div class="col-md-3 col-sm-5 text-right py-1">${tempSensor3}</div>
        </div>

        <div class="row pb-1 ${dygraphLegendFormatter.series[3]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left pt-1">${t("temperature_sensor_4")}</div>
          <div class="col-md-3 col-sm-5 text-right pt-1">${tempSensor4}</div>
        </div>
      </div>`
        : ""
      }
      ${graphId === "chiller" ? `
          <div class="container-fluid p-3 tooltip-label-wrapper">
            <div class="row pb-1 ${(dygraphLegendFormatter.series[0]?.isVisible || (dygraphLegendFormatter.series[0].color && chillerOutletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("chiller_outlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${chillerOutletTemp}</div>
            </div>
            
            <div class="row py-1 ${(dygraphLegendFormatter.series[1]?.isVisible || (dygraphLegendFormatter.series[1].color && chillerReturnTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" pl-0 style="background-color:${dygraphLegendFormatter.series[1].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("chiller_return_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${chillerReturnTemp}</div>
            </div>
            
            <div class="row py-1 ${(dygraphLegendFormatter.series[2]?.isVisible || (dygraphLegendFormatter.series[2].color && processInletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("process_outlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${processInletTemp}</div>
            </div>

            <div class="row py-1 ${(dygraphLegendFormatter.series[3]?.isVisible || (dygraphLegendFormatter.series[3].color && processOutletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("process_inlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${processOutletTemp}</div>
            </div>

            <div class="row py-1 ${dygraphLegendFormatter.series[9]?.isVisible ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:#daebff"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("active_steps")}</div>
              <div class="col-md-3 col-sm-5 text-right">${graphValuesList[10]}</div>
            </div>
          </div>`
        : ""
      }
      ${graphId === "evc0" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("lsp")}</div>
            <div class="col-md-4 col-sm-5 text-right">${LSP}</div>
          </div>
        
          <div class="row py-1 ${dygraphLegendFormatter.series[1]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("sst")}</div>
            <div class="col-md-4 col-sm-5 text-right">${SST}</div>
          </div>
          
          <div class="row py-1 ${dygraphLegendFormatter.series[2]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("hsp")}</div>
            <div class="col-md-4 col-sm-5 text-right">${HSP}</div>
          </div>

          <div class="row py-1 ${dygraphLegendFormatter.series[3]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("sct")}</div>
            <div class="col-md-4 col-sm-5 text-right">${SCT}</div>
          </div>
        </div>`: ""
      }
      ${graphId === "evc1" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${t("subcool_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${subCoolTemp}</div>
          </div>
        </div>` : ""
      }
      ${graphId === "evc2" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${(deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") ? t("evc_temperature") : t("accumulator_inlet_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${evcTemp}</div>
          </div>
        ${
          deviceTypeGlobal === "CHILLER" ?
          `<div class="row pb-1 ${dygraphLegendFormatter.series[9]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[9].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${t("accumulator_outlet_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${evcTemp2}</div>
          </div>` : ""
        }
        </div>` : ""
      }
      ${
        (graphId === "turck")
        ? displayTurckToolTipBody
        : ""}
      <div class="w-100 text-center pb-2 mt-2 font-weight-bold">
        <span>
                  ${dropdownTime === undefined
        ? moment(date).format("MM/DD/YY hh:mm A")
        : moment
          .tz(new Date(date), dropdownTime)
          .format("MM/DD/YY hh:mm A")
      }
        </span>
      </div>
    </div>
  </div>`;
  };
  const sensorDisplayName = {
    HPF_INPUT_REG_TEMP1: {
      name: t("tank_temperature"),
    },
    HPF_INPUT_REG_TEMP2: {
      name: t("milk_pipeline_temperature"),
    },
    HPF_INPUT_REG_TEMP3: {
      name: t("temperature_sensor_3"),
    },
    HPF_INPUT_REG_TEMP4: {
      name: t("temperature_sensor_4"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_1: {
      name: t("chiller_outlet_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_2: {
      name: t("chiller_return_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_4: {
      name: t("process_inlet_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_3: {
      name: t("process_outlet_temperature"),
    },
    CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS: {
      name: t("pump"),
      hideCheckBox: true,
    },
    CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS: {
      name: t("water_relay_status"),
    },
    CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS: {
      name: t("voltage_relay_status"),
    },
    CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS: {
      name: t("probe_sensor_status"),
    },
    HPF_INPUT_REG_VOLUME_4TO20MA_READING: {
      name: t("tank_volume"),
    },
    HPF_INPUT_REG_MAIN_STATE: {
      name: "Tank State",
      hideCheckBox: true,
    },
    EVC_INPUT_REG_TEMP_1: {
      name: (deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") ? t("evc_temperature") : t("accumulator_inlet_temperature"),
    },
    EVC_INPUT_REG_TEMP_2: {
      name: t("accumulator_outlet_temperature"),
    },
    EVC_INPUT_REG_SUBCOOL_TEMP: {
      name: t("subcool_temperature"),
    },
    EVC_INPUT_REG_LOW_SIDE_PRESSURE: {
      name: `${t("lsp")}`,
    },
    EVC_INPUT_REG_HIGH_SIDE_PRESSURE: {
      name: `${t("hsp")}`,
    },
    EVC_INPUT_REG_HI_SAT_TEMP: {
      name: `${t("sct")}`,
    },
    EVC_INPUT_REG_LO_SAT_TEMP: {
      name: `${t("sst")}`,
    },
    EVC_INPUT_REG_VALVE_POS: {
      name: `${t("valve_position")}`,
    },
    EVC_INPUT_REG_FAN_OUTPUT:{
      name: t("fan_speed"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE:{
      name: t("control_status"),
    },
    CUSTOM_PARAM_ACTIVE_STEPS: {
      name: t("active_steps"),
    },
    TURCK_CONDUCTIVITY_SENSOR_1_PARLOR: {
      name: `${t("TURCK_CONDUCTIVITY_SENSOR_1_PARLOR")}`,
    },
    TURCK_CONDUCTIVITY_SENSOR_2_TANK:  {
      name: `${t("TURCK_CONDUCTIVITY_SENSOR_2_TANK")}`,
    },
    TURCK_DEVICE_TYPE:  {
      name: `${t("TURCK_DEVICE_TYPE")}`,
    },
    TURCK_FLOW_METER:  {
      name: `${t("TURCK_FLOW_METER")}`,
    },
    TURCK_LOAD_OUT_LOOP_WASHING:  {
      name: `${t("TURCK_LOAD_OUT_LOOP_WASHING")}`,
    },
    TURCK_LOAD_OUT_LOOP_WASH_SELECTED:  {
      name: `${t("TURCK_LOAD_OUT_LOOP_WASH_SELECTED")}`,
    },
    TURCK_MOTOR_FREQUENCY_COMMANDED:  {
      name: `${t("TURCK_MOTOR_FREQUENCY_COMMANDED")}`,
    },
    TURCK_NUMBER_OF_TANKS:  {
      name: `${t("TURCK_NUMBER_OF_TANKS")}`,
    },
    TURCK_PARLOR_MILK_MODE:  {
      name: `${t("TURCK_PARLOR_MILK_MODE")}`,
    },
    TURCK_PARLOR_WASH_CONTROL_PHASE:  {
      name: `${t("TURCK_PARLOR_WASH_CONTROL_PHASE")}`,
    },
    TURCK_PARLOR_WASH_MODE:  {
      name: `${t("TURCK_PARLOR_WASH_MODE")}`,
    },
    TURCK_PHE_TEMPERATURE_SENSOR_1:  {
      name: `${t("TURCK_PHE_TEMPERATURE_SENSOR_1")}`,
    },
    TURCK_PHE_TEMPERATURE_SENSOR_2:  {
      name: `${t("TURCK_PHE_TEMPERATURE_SENSOR_2")}`,
    },
    TURCK_LOAD_OUT_LOOP_TEMPERATURE:  {
      name: `${t("TURCK_LOAD_OUT_LOOP_TEMPERATURE")}`,
    },
    TURCK_POWER_FAIL_ALARM:  {
      name: `${t("TURCK_POWER_FAIL_ALARM")}`,
    },
    TURCK_REGISTRATION_CODE_FIRST_3_DIGITS:  {
      name: `${t("TURCK_REGISTRATION_CODE_FIRST_3_DIGITS")}`,
    },
    TURCK_REGISTRATION_CODE_LAST_3_DIGITS:  {
      name: `${t("TURCK_REGISTRATION_CODE_LAST_3_DIGITS")}`,
    },
    TURCK_TANK_SELECTED_FOR_LOADOUT:  {
      name: `${t("TURCK_TANK_SELECTED_FOR_LOADOUT")}`,
    },
    TURCK_TANK_SELECTED_FOR_WASH:  {
      name: `${t("TURCK_TANK_SELECTED_FOR_WASH")}`,
    },
    TURCK_VAT_LEVEL_PERCENTAGE:  {
      name: `${t("TURCK_VAT_LEVEL_PERCENTAGE")}`,
    },
    AGITATOR_OUTPUT:  {
      name: `${t("AGITATOR_OUTPUT")}`,
    },
    AGITATOR_TIMER:  {
      name: `${t("AGITATOR_TIMER")}`,
    },
    CONDENSING_UNIT_1:  {
      name: `${t("CONDENSING_UNIT_1")}`,
    },
    CONDENSING_UNIT_2:  {
      name: `${t("CONDENSING_UNIT_2")}`,
    },
    COOL_CYCLE_TIMER:  {
      name: `${t("COOL_CYCLE_TIMER")}`,
    },
    LEVEL_ALARM:  {
      name: `${t("LEVEL_ALARM")}`,
    },
    MILK_DESTINATION_PROXIMITY_SENSOR:  {
      name: `${t("MILK_DESTINATION_PROXIMITY_SENSOR")}`,
    },
    MILK_DESTINATION_TANK_PROX_ENABLED:  {
      name: `${t("MILK_DESTINATION_TANK_PROX_ENABLED")}`,
    },
    PERCENT_FULL:  {
      name: `${t("PERCENT_FULL")}`,
    },
    SILO_MILK_COOLER_SELECTION:  {
      name: `${t("SILO_MILK_COOLER_SELECTION")}`,
    },
    TANK_LEVEL:  {
      name: `${t("TANK_LEVEL")}`,
    },
    TANK_STATE:  {
      name: `${t("TANK_STATE")}`,
    },
    TANK_TEMPERATURE:  {
      name: `${t("TANK_TEMPERATURE")}`,
    },
    TEMPERATURE_ALARM:  {
      name: `${t("TEMPERATURE_ALARM")}`,
    },
    WASH_PROXIMITY_SENSOR:  {
      name: `${t("WASH_PROXIMITY_SENSOR")}`,
    },
    WASH_PROX_ENABLED:  {
      name: `${t("WASH_PROX_ENABLEDN")}`,
    },
    WASH_TIME_REMAINING:  {
      name: `${t("WASH_TIME_REMAINING")}`,
    },

  };


  if (graphId.includes('evc')) {
    sensorDisplayName['HPF_INPUT_REG_TEMP1'].hideCheckBox = true;
    sensorDisplayName['HPF_INPUT_REG_VOLUME_4TO20MA_READING'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_VALVE_POS'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_FAN_OUTPUT'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_1'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_2'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_3'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_4'].hideCheckBox = true;
    sensorDisplayName['TANK_STATE'].hideCheckBox = true;
    sensorDisplayName['TANK_TEMPERATURE'].hideCheckBox = true;
    sensorDisplayName['TANK_LEVEL'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_TEMP_2'].hideCheckBox = (deviceTypeGlobal === "HPF" ||  deviceTypeGlobal === "TURCK_TANKS");
  }
  
  if(deviceType === "CHILLER"){
    sensorDisplayName['CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE'].hideCheckBox = true;
  }

  if(deviceType === "TURCK_TANKS" || graphId.includes("turck")){
    sensorDisplayName['WASH_TIME_REMAINING'].hideCheckBox = true;
    sensorDisplayName['PERCENT_FULL'].hideCheckBox = true;
    sensorDisplayName['AGITATOR_OUTPUT'].hideCheckBox = true;
    sensorDisplayName['CONDENSING_UNIT_1'].hideCheckBox = true;
    sensorDisplayName['CONDENSING_UNIT_2'].hideCheckBox = true;
    sensorDisplayName['TANK_STATE'].hideCheckBox = true;
    sensorDisplayName['TURCK_FLOW_METER'].hideCheckBox = true;
    sensorDisplayName['TURCK_MOTOR_FREQUENCY_COMMANDED'].hideCheckBox = true;
    sensorDisplayName['TURCK_VAT_LEVEL_PERCENTAGE'].hideCheckBox = true;
  }
  const checkboxColor = {
    [t("tank_temperature")]: {
      color: "#3ca1ff",
    },
    [t("milk_pipeline_temperature")]: {
      color: "#7ae43f",
    },
    [t("temperature_sensor_3")]: {
      color: "#b068d6",
    },
    [t("temperature_sensor_4")]: {
      color: "#efc53a",
    },
    [t("chiller_outlet_temperature")]: {
      color: "#3ca1ff",
    },
    [t("chiller_return_temperature")]: {
      color: "#7ae43f",
    },
    [t("process_outlet_temperature")]: {
      color: "#b068d6",
    },
    [t("process_inlet_temperature")]: {
      color: "#efc53a",
    },
    [t("tank_volume")]: {
      color: "#ff5c5c",
      axis: "y2",
    },
    [t("evc_temperature")]: {
      color: "#e84e4a",
    },
    [t("accumulator_inlet_temperature")]: {
      color: "#e84e4a",
    },
    [t("accumulator_outlet_temperature")]: {
      color: "#0000ff",
    },
    [t("subcool_temperature")]: {
      color: "#f3c72f",
    },
    [t("lsp")]: {
      color: "#0000ff",
    },
    [t("hsp")]: {
      color: "#ff0000",
    },
    [t("sct")]: {
      color: "#b068d6",
      axis: "y2",
    },
    [t("sst")]: {
      color: "#efc53a",
      axis: "y2",
    },
    [t("active_steps")]: {
      color: "#6db0ff33",
      axis: "y2"
    },
    [t("TURCK_FLOW_METER")]: {
      color: "#efc53a",
    },
    [t("TURCK_MOTOR_FREQUENCY_COMMANDED")]: {
      color: "#7ae43f",
    },
    [t("TURCK_VAT_LEVEL_PERCENTAGE")]: {
      color: "#3ca1ff",
    },
    [t("TURCK_PHE_TEMPERATURE_SENSOR_1")]: {
      color: "#00D663",
    },
    [t("TURCK_PHE_TEMPERATURE_SENSOR_2")]: {
      color: "#027EC3",
    },
    [t("TURCK_LOAD_OUT_LOOP_TEMPERATURE")]: {
      color: "#CFE281",
    },
    [t("TANK_LEVEL")]: {
      color: "#E29781",
      axis: "y2"
    },
    [t("TANK_TEMPERATURE")]: {
      color: "#D32B24",
    },
    [t("WASH_TIME_REMAINING")]: {
      color: "#D324CC",
    },

    [t("AGITATOR_OUTPUT")]: {
      color: "#e84e4a",
    },
    [t("CONDENSING_UNIT_1")]: {
      color: "#f3c72f",
    },
    [t("CONDENSING_UNIT_2")]: {
      color: "#0000ff",
    },
    [t("TANK_STATE")]: {
      color: "#ff0000",
    },
    [t("PERCENT_FULL")]: {
      color: "#b068d6",
      axis: "y2",
    }
  };

  const TempType = [
    t("chiller_outlet_temperature"),
    t("chiller_return_temperature"),
    t("process_inlet_temperature"),
    t("process_outlet_temperature"),
    t("tank_temperature"),
    t("milk_pipeline_temperature"),
    t("evc_temperature"),
    t("subcool_temperature"),
    t("TANK_TEMPERATURE"),
    t("TURCK_LOAD_OUT_LOOP_TEMPERATURE"),
    t("TURCK_PHE_TEMPERATURE_SENSOR_1"),
    t("TURCK_PHE_TEMPERATURE_SENSOR_2")
  ];

  const [checkboxStatus, setCheckboxStatus] = useState(
    sensors.map((el) => true)
  );
  const [showDrawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("");
  const [dygraph, setDygraph] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState();
  const { dairyID, tankID } = useParams();
  const [open, setOpen] = useState(false);
  const user = getUser();
  const configId = deviceTypeGlobal === "HPF" ? "MANUAL_ALERTS" : deviceTypeGlobal === "TURCK_TANKS" ? "MANUAL_ALERTS_TURCK" : "MANUAL_ALERTS_CHILLER";
  const validationTempId = "VALIDATE_TEMPERATURE";

  /** Display line in particular point */
  const pointClick = async (event, p) => {
    // just to fetch HPF or CHILLER when selected EVC
    let createdAt = p;
    if (dropdownTime !== undefined) {
      createdAt = moment.tz(new Date(p), dropdownTime).valueOf();
    }
    if (manualAnnotation) {
      try {
        if(deviceTypeGlobal === "HPF") {
          const data = {
            tank: tankID,
            dairy: dairyID,
            configDisplay: "Untitled",
            alertText: "--",
            createdAt: createdAt,
            createdBy: user.id,
          };
          const res = await postManualAnnotation(data);
          if (res.status === 200) {
            getAlerts();
          }
        } else if(deviceTypeGlobal === "TURCK_TANKS") {
          const data = {
            tank: tankID,
            dairy: dairyID,
            configDisplay: "Untitled",
            alertText: "--",
            createdAt: createdAt,
            createdBy: user.id,
          };
          const res = await postTurckManualAnnotation(data);
          if (res.status === 200) {
            getAlerts();
          }
        } else if(deviceTypeGlobal === "CHILLER") {
          const data = {
            device: tankID,
            location: dairyID,
            configDisplay: "Untitled",
            alertText: "--",
            createdAt: createdAt,
            createdBy: user.id,
          };
          const res = await postChillerManualAnnotation(data);
          if (res.status === 200) {
            getAlerts();
          }        
        }
      } catch (err) {
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
  };

  const replaceSensorWithDisplayName = (sensorsList, ignoreList) => {
    // Ignoring sensors that are passed in the 'ignoreList'
    if (Array.isArray(ignoreList) && ignoreList.length) {
      sensorsList = sensorsList.filter(sensor => !ignoreList.includes(sensor));
    }
    return sensorsList.map((sensor) => sensorDisplayName[sensor].name);
  }

  const handleCheckboxes = (index) => {
    if (checkboxStatus[index]) {
      checkboxStatus[index] = false;
      dygraph.setVisibility(index, false);
    } else {
      checkboxStatus[index] = true;
      dygraph.setVisibility(index, true);
    }
    setCheckboxStatus([...checkboxStatus]);
  };

  const renderGraphData = (alert) => {
    const manualItems = alert?.filter((i) => i.configKey === configId || i.configKey === validationTempId);
    let hairlines = new Dygraph.Plugins.Hairlines({
      divFiller: function (div, data) {
        const index = alert.findIndex((el) => {
          return el.createdAt === data.hairline.xval;
        });
        const isManual = manualItems.findIndex(
          (i) => i.createdAt === data.hairline.xval
        );
        $(".hairline-legend", div).html(
          `<p class="d-none untitled" style="overflow: hidden;cursor: default; white-space: nowrap;text-overflow: ellipsis;" id=${index}>${alert[index]?.configDisplay}</p>`
        );
        $(div).data({ xval: data.hairline.xval });
        div.id = "hairline-" + index;
        if (isManual > -1) {
          div.className = div.className + " manual-item";
        } else {
          div.className = div.className + " system-item";
        }
        const child = div.childNodes[0];
        child.id = index; // see .hover() below.
        child.style.display = "flex";
        child.style.flex = "1";
        child.style.justifyContent = "center";
        child.style.padding = "1rem 10px 0px 10px";
      },
    });
    // }

    /** Display HairLine in dygraph */
    let plugins = [hairlines];
    if (manualAnnotation) {
      let crossLine = new Dygraph.Plugins.Crosshair({
        direction: "vertical",
      });
      plugins.push(crossLine);
    }

    if (data) {
      let maxControlSteps = 0;
      const dataList = data.split('\n');

      for (let i = 0; i < dataList.length; i++) {
        const valuesList = dataList[i].split(',');
        // Reading the control steps value
        const controlStepsValue = valuesList[valuesList.length - 1];

        if (controlStepsValue && !isNaN(Number(controlStepsValue))) {
          maxControlSteps = Math.max(maxControlSteps, parseInt(controlStepsValue));
        }
      }

      const graphOptions = {
        height: 450,
        axisLineWidth: 0.00000000001,
        plugins: plugins,
        visibility: sensors.map((sensor) => !sensorDisplayName[sensor]?.hideCheckBox),
        legend: "follow",
        legendFormatter(dygraphLegendFormatter) {
          // Get all the sensors' data at a given time 'x'.
                    let graphValuesList = dygraphLegendFormatter.dygraph.rawData_.find((d) => d[0] === dygraphLegendFormatter.x);
          // Show tooltip only if there is some data to show other than the timestamp.
          if (!isEmpty(graphValuesList) && (graphValuesList[1] || graphValuesList[2] || graphValuesList[3] || graphValuesList[4] || graphValuesList[5] || graphValuesList[6]|| graphValuesList[12] || graphValuesList[13]|| graphValuesList[14])) {
            return tooltip(dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList);
          }

          return null;
        },
        clickCallback: pointClick,
        includeZero: true,
        series: checkboxColor,
        axes: {
          x: {
            drawAxis: true,
            drawGrid: false,
            valueFormatter(val) {
              if (dropdownTime === undefined) {
                const value = moment(val).format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              } else {
                const value = moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              }
            },
            axisLabelFormatter(val) {
              if (dropdownTime === undefined) {
                return moment(val).format("MM/DD/YY hh:mm:ss A");
              } else {
                return moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YY hh:mm:ss A");
              }
            },
          },
          y: {
            drawAxis: true,
            gridLinePattern: [4, 4],
            independentTicks: true,
            valueRange: deviceType === 'CHILLER' ? [20, null] : [null, null],
            axisLabelWidth: 63, // width of label on axis y2
            valueFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              }
              return `${value}&nbsp;${unit}`;
            },
            axisLabelFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              }

              return `${parseInt(value, 10)}&nbsp;${unit}`;
            },
          },
          y2: {
            drawAxis: true,
            includeZero: true, // start y2 axis from zero
            axisLabelWidth: 40, // width of label on axis y2
            independentTicks: deviceType === 'CHILLER',
            valueRange: deviceType === 'CHILLER' ? [0, null] : [null, null],
            fillGraph: deviceType === 'CHILLER',
            stepPlot: deviceType === 'CHILLER',
            valueFormatter(val) {
              const sensorArr = replaceSensorWithDisplayName(sensors);

              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(
                  Number(val),
                  sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP")
                );
                unit = tempUnit;
                value = temperature;
              } else if ((deviceTypeGlobal === 'HPF' || deviceTypeGlobal === 'TURCK_TANKS') && (deviceType === 'HPF' || deviceType === 'TURCK_TANKS' || deviceType === "EVC")) {
                // needs to display tank volume only when globally hpf selected and graph is selected either HPF or EVC
                // it will be shown on HPF and on HPF EVC
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              } else {
                // no needs to display tank volume when globally CHILLER selected and graph is selected either CHILLER or EVC
                return '';
              }

              return `${parseInt(value, 10)} ${unit}`;
            },
            axisLabelFormatter(val) {
              if (deviceType === 'CHILLER') {
                return val;
              }

              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              } else if ((deviceTypeGlobal === 'HPF' || deviceTypeGlobal === 'TURCK_TANKS') && (deviceType === 'HPF' || deviceType === 'TURCK_TANKS' || deviceType === "EVC")) {
                // needs to display tank volume only when globally hpf selected and graph is selected either HPF or EVC
                // it will be shown on HPF and on HPF EVC
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              } else {
                // no needs to display tank volume when globally CHILLER selected and graph is selected either CHILLER or EVC
                return '';
              }

              return `${parseInt(value, 10)} ${unit}`;
            }
          },
        },
        underlayCallback(canvas, area, g) {
          const canvasData = canvas;
          if ((graphId === 'hpf' || graphId === "turck") && washData !== undefined) {
            washData.forEach((value) => {
              canvasData.fillStyle = "#FDEFC0";
              const bottomLeft = g.toDomCoords(value.start, -20)[0];
              const topRight = g.toDomCoords(value.end, +20)[0];
              canvasData.fillRect(
                bottomLeft,
                area.y,
                topRight - bottomLeft,
                area.h
              );
            });
          }
        },
        drawPoints: false,
        animatedZooms: false,
        rangeSelectorHeight: 40,
        labels: ["Date"].concat(replaceSensorWithDisplayName(sensors)),
      };

      if (isResponsiveMode) {
        // Disable vertical scrolling in graph in Responsive(Mobile) view
        graphOptions.interactionModel = {
          touchstart: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchstart(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
          touchmove: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchmove(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
        };
      }

      if (deviceType === 'CHILLER') {
        graphOptions.axes.y2.ticker = function setY2Ticks(min, max, pixels, opts, dygraphRef, vals) {
          const ticks = [];
          for (let i = parseInt(min); i <= parseInt(maxControlSteps); i += 1) {
            ticks.push({ v: i, label: i });
          }
          return ticks;
        };

        graphOptions.zoomCallback = function (minDate, maxDate, yRanges) {
          if (Number(yRanges[0][0]) === 0) {
            graph.updateOptions({ valueRange: [[20, null], [0, null]] });
          }
        };
      }

      const graph = new Dygraph(document.getElementById(`graph${graphId}`), data, graphOptions);

      setCheckboxStatus((prev) => prev.map((el) => true));
      setDygraph(graph);
    }
    if (alerts && showAnnotation) {
      const hairlineValue = alerts?.map((el) => {
        return { xval: el.createdAt, interpolated: false };
      });
      const manualItems = alerts?.filter((i) => i.configKey !== configId && i.configKey !== validationTempId);
      hairlines.set(hairlineValue);
      const totalHairline = ref.current.getElementsByClassName(`hairline-info`);
      for (let i = 0; i < totalHairline.length; i++) {
        totalHairline[i].addEventListener("click", (e) => {
          setSelectedAlert(alerts[e.target.id]);
          setDrawer(true);
        });
        totalHairline[i].previousElementSibling.addEventListener(
          "mouseout",
          (e) => {
            $(`.hairline-info`).find(".untitled").addClass("d-none");
            let element = $(e.target).closest("div").parent().next();
            element.find(".untitled").removeClass("d-none");
          }
        );

        let dygraphHairline = $(totalHairline[i]).prev();
        if (
          dygraphHairline.length > 0 &&
          totalHairline[i].id !== "hairline-template"
        ) {
          var id = totalHairline[i].id;
          dygraphHairline[0].id = id;
          totalHairline[i].id = id;
          let systemId = id.split("hairline-")[1];
          let manualId = id.split("hairline-")[1];
          const isManual = manualItems.findIndex(
            (i) => i.createdAt === alerts[manualId]?.createdAt
          );

          const systemItem = manualItems.findIndex(
            (i) =>
              i.createdAt === alerts[systemId]?.createdAt &&
              i.isResolved === false
          );
          if (isManual > -1) {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " system-item";
            if (systemItem > -1) {
              dygraphHairline[0].className =
                dygraphHairline[0].className + " anim-glow";
            } else {
              dygraphHairline[0].className = dygraphHairline[0].className;
            }
          } else {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " manual-item";
          }
        }
      }
      const $totalDygraphHairline = $(".dygraph-hairline");
      $totalDygraphHairline.each((i, line) => {
        if (line.id !== $(".graph").attr("id")) {
          $(line).off('click');
          $(line).on('click', () => {
            let id = line.id.split("-")[1];
            setSelectedAlert(alerts[id]);
            // setTitle(alerts[id].configDisplay);
            setDrawer(true);
          });
        }
      });
    }
  };

  /** Delete Manual annotation functionality */
  const deleteAnnotation = (selectedAlert) => {
    if(deviceTypeGlobal === "HPF") {
      DeleteManualAnnotation(selectedAlert)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setDrawer(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("manual_annotation_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlerts();
          }
        })
        .catch((err) => {
          console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response ?? t("something_went_wrong")}
          />
        );
      });
    } else if(deviceTypeGlobal === "TURCK_TANKS") {
      DeleteTurckManualAnnotation(selectedAlert)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setDrawer(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("manual_annotation_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlerts();
          }
        })
        .catch((err) => {
          console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response ?? t("something_went_wrong")}
          />
        );
      });
    } else {
      DeleteChillerManualAnnotation(selectedAlert)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setDrawer(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("manual_annotation_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlerts();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response ?? t("something_went_wrong")}
            />
          );
        });
    }
  };

  //Render the graph Data when graph component loads for the first time and when data or annotation value change
  useEffect(() => {
    renderGraphData(alerts);
  }, [data, showAnnotation, alerts]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [manualAnnotation, dropdownTime]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [showDrawer, alerts]);

  const ignoreList = (graphId.includes('evc')) ? [
      "CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS",
      "EVC_INPUT_REG_MAIN_STATE",
      "EVC_INPUT_REG_VALVE_POS",
      "EVC_INPUT_REG_FAN_OUTPUT",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
      "TANK_STATE",
      "TURCK_FLOW_METER" ,
      "TURCK_MOTOR_FREQUENCY_COMMANDED" ,
      "TURCK_VAT_LEVEL_PERCENTAGE" ,
      (deviceTypeGlobal === "HPF" || deviceTypeGlobal === "TURCK_TANKS") ? "EVC_INPUT_REG_TEMP_2" : ""
    ] : deviceType === "HPF" ? 
      ['HPF_INPUT_REG_MAIN_STATE'] : (graphId.includes('turck')) ? 
        [
          "WASH_TIME_REMAINING",
          "AGITATOR_OUTPUT",
          "CONDENSING_UNIT_1",
          "CONDENSING_UNIT_2",
          "TANK_STATE",
          "PERCENT_FULL",
          "TURCK_FLOW_METER" ,
          "TURCK_MOTOR_FREQUENCY_COMMANDED" ,
          "TURCK_VAT_LEVEL_PERCENTAGE" ,
        ]
      :[
      'CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS',
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
    ];

    const checkboxItems = sensors.map((key)=> {
      if (ignoreList.includes(key) || sensorDisplayName[key]?.hideCheckBox) {
        return null;
      }
      return sensorDisplayName[key]?.name;
    });

  return (
    <>
      <GraphWrapper ref={ref}>
        <Row className="mb-2">
          <CheckboxContainer lg={12}>
            {checkboxItems.map((item, index) => {
              return item && (
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                  <label className="mt-1" key={index}>
                    <CustomCheckbox
                      color={checkboxColor[item]?.color}
                      onChange={() => handleCheckboxes(index)}
                      checked={checkboxStatus[index]}
                    />
                    <CheckboxText>{t(item)}</CheckboxText>
                  </label>
              );
            })}
          </CheckboxContainer>
        </Row>
        <div
          id={`graph${graphId}`}
          className="graph"
          style={{
            cursor: "pointer",
          }}
        />
        <div
          id="hairline-template"
          className={`hairline-info text-primary`}
          style={{ display: "none" }}
        >
          <div className="hairline-legend"></div>
        </div>
      </GraphWrapper>
      {showDrawer && (
        <Sidebar
          onClose={() => setDrawer(false)}
          visible={showDrawer}
          title={
            (selectedAlert.configKey === configId || selectedAlert.configKey === validationTempId) ? (
              <div className="d-flex align-items-center mb-0">
                <div className="text-primary">
                  <ManualAlertIcon className="mr-2 align-text-bottom" />
                  {t("manual_notes")}
                </div>
                <div
                  className="ml-auto mr-3 delete d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Image src={Delete} />
                </div>
              </div>
            ) : (
                <div className="text-danger my-1">
                  <SystemIcon className="mr-2 align-text-bottom" />
                  {t("system_alert")}
                </div>
              )
          }
          key={selectedAlert?.id}
          alert={selectedAlert}
          getAlerts={getAlerts}
          dropdownTime={dropdownTime}
          configId={configId}
          validationTempId={validationTempId}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_annotation_msg")}
        className="text-black mt-3"
        callback={() => deleteAnnotation(selectedAlert.id)}
      />
    </>
  );
};

export default withTranslation()(Graph);
