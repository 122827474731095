/**
 * @function getTempAsPerSelectedUnitSystem converts the incoming temperature value based on the logged-in user's
 * preferred unit that is stored in the local storage (Fahrenheit)
 * @param {number} temperature temperature value.
 * @returns {object} contains converted temperature value and unit.
 */
export const getTempAsPerSelectedUnitSystem = (temperature, isSubcool,failure, isSensorNotConnected, isNullValue) => {
  if(failure || isSensorNotConnected === '0' || isNullValue === null){
    return { temperature: "???", tempUnit: "" };
  }
  // if the incoming temperature value is not a number return --
  if (typeof temperature !== "number" || Number.isNaN(temperature)) {
    return { temperature: "--", tempUnit: "" };
  }

  // get user's unit preference from the local storage
  const { temperatureUnit } = JSON.parse(localStorage.getItem("user"));

  switch (temperatureUnit) {
    case "C":
      return {
        temperature: isSubcool ? (temperature * (5 / 9)).toFixed(2) : (((temperature - 32) * 5) / 9).toFixed(2),
        tempUnit: "\u00b0C",
      };
    case "F":
      return { temperature: temperature.toFixed(2), tempUnit: "\u00b0F" };
    case "K":
      return {
        temperature: (((temperature - 32) * 5) / 9 + 273.15).toFixed(2),
        tempUnit: "K",
      };
    default:
      return { temperature: temperature.toFixed(2), tempUnit: "\u00b0F" };
  }
};

/**
 * @function getVolumeAsPerSelectedUnitSystem converts the incoming volume value based on the logged-in user's
 * preferred unit that is stored in the local storage
 * @param {number} volume volume value.
 * @returns {object} contains converted volume value and unit.
 */
export const getVolumeAsPerSelectedUnitSystem = (volume) => {
  // if the incoming volume value is not a number return --
  if (typeof volume !== "number" || Number.isNaN(volume)) {
    return { volume: "--", volumeUnit: "" };
  }

  // get user's unit preference from the local storage
  const { volumeUnit } = JSON.parse(localStorage.getItem("user"));

  switch (volumeUnit) {
    case "gal":
      return { volume, volumeUnit: "gal" };
    case "L":
      return { volume: Math.round(volume * 3.78), volumeUnit: "L" };
    case "lb":
      return { volume: Math.round(volume * 8.6), volumeUnit: "lb" };
    case "kg":
      return { volume: Math.round(volume * 8.6 * 0.45), volumeUnit: "kg" };
    default:
      return { volume, volumeUnit: "gal" };
  }
};

/**
 * @function getPressureAsPerSelectedUnitSystem converts the incoming temperature value based on the logged-in user's
 * preferred unit that is stored in the local storage
 * @param {number} temperature temperature value.
 * @returns {object} contains converted temperature value and unit.
 */
export const getPressureAsPerSelectedUnitSystem = (pressure) => {
  // if the incoming temperature value is not a number return --

  if (typeof pressure !== "number" || Number.isNaN(pressure)) {
    return { pressure: "--", pressureUnit: "" };
  }

  // get user's unit preference from the local storage
  const { pressureUnit } = JSON.parse(localStorage.getItem("user"));

  switch (pressureUnit) {
    case "psig":
      return { pressure: pressure.toFixed(2), pressureUnit: "psig" };
    case "bar":
      return {
        pressure: (pressure * 0.069).toFixed(2),
        pressureUnit: "bar",
      };
    case "MPa":
      return {
        pressure: (pressure * 0.0069).toFixed(2),
        pressureUnit: "MPa",
      };
    default:
      return { pressure, pressureUnit: "psig" };
  }
};

export const getStringFromHexadecimalToBinary = (val, bitAddress) => {
  // converting Hex value to binary 
  let tempVal = Array.from("0".repeat(18));
  tempVal = parseInt(val, 16).toString(2);
  tempVal = tempVal.split("").reverse().join("");
  return bitAddress >= 0 ? tempVal[bitAddress] : tempVal;
};

export const getStringFromDecimalToBinary = (val, bitAddress) => {
    // defining 0 to 17 bits as 0 value;
    let tempVal = Array.from("0".repeat(18));
    // converting hexadecimal to binary and reversing the array;
    let convertedBinary = parseInt(val).toString(2).split("").reverse();
    // re-assigning the converted values into main array
    tempVal.splice.apply(tempVal, [0, convertedBinary.length].concat(convertedBinary));
    return bitAddress >= 0 ? tempVal.at(bitAddress) : tempVal;
};

/**
 * @function getEnableStatus converts the register value into Status Text
 * @param {string|number} value 
 * @param {string} [enabledText='Enabled'] 
 * @param {string} [disabledText='Disabled'] 
 * @returns {string} status value [Enabled, Disabled, -]
 */
export const getEnableStatus = (value, enabledText = 'Enabled', disabledText = 'Disabled') => {
  const num = parseInt(value, 10);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num)) {
    return '-';
  }
  return (num > 0) ? enabledText : disabledText;
}

export const getBinaryStringFromDecimal = (val, totalBits) => {
	const tempVal = Array.from('0'.repeat(totalBits));
	const convertedBinary = parseInt(val).toString(2).split('').reverse();
	tempVal.splice.apply(tempVal, [0, convertedBinary.length].concat(convertedBinary));
	return tempVal;
};

export const findCompressorNumberByValue = (indicator) => {
	const evcIdentificationNumbers = {
		"Bottom Unit #1": [32, 36, 40, 44],
		"Bottom Unit #2": [33, 37, 41, 45],
		"Side Unit #3": [34, 38, 42, 46],
		"Side Unit #4": [35, 39, 43, 47],
	};
	return Object.keys(evcIdentificationNumbers).find((key) => evcIdentificationNumbers[key].includes(indicator));
};